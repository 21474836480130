import Vue from "vue";
import _ from "lodash";

export default {
  name: "NavIndexes",

  data: {
    style: 'default',
    topLinesIndexes: [],
    bottomLinesIndexes: []
  },

  computed: {
    display() {
      return this.$store.state.navIndexes.display;
    },

    index() {
      return this.$store.state.navIndexes.index;
    },

    indexes() {
      return document.querySelectorAll(`#content > section, #content > .products-container`).length;
    },

    scrollComponentName() {
      return this.$store.state.scrollComponentName;
    }
  },

  watch: {
    scrollComponentName(value) {
      let style = 'default';
      if (value === "contact-form") {
        style = 'light';
      }
      if (style !== this.style) {
        this.style = style;
      }
    },

    topLinesIndexes(values) {
      const intersectValues = values.filter(value => this.bottomLinesIndexes.includes(value));
      if (intersectValues.length && intersectValues[0] !== undefined) {
        this.$store.commit("navIndexes", { index: intersectValues[0] });
      }
    },

    bottomLinesIndexes(values) {
      const intersectValues = values.filter(value => this.topLinesIndexes.includes(value));
      if (intersectValues.length && intersectValues[0] !== undefined) {
        this.$store.commit("navIndexes", { index: intersectValues[0] });
      } 
    },
  },

  mounted() {
    Vue.nextTick().then(this.setup);
  },

  methods: {
    setup() {
      this.$options.intObserver = new IntersectionObserver(this.onIntersection);
      window.mondo.event.$on("NavIndexes.scrollTo", this.scrollTo);
      
      setTimeout(() => {
        Array
          .from(document.querySelectorAll('.intersection-line'))
          .forEach(el => this.$options.intObserver.observe(el));
      }, 1000);

      setTimeout(() => {
        // if index cannot be set on startup wait 1s and go to currentElementIntersect
        if (this.index === -1) {
          this.currentElementIntersect();
        }
      }, 1100);
    },

    currentElementIntersect() {
      const elements = Array.from(document.querySelectorAll("#content > *"));
      const pageY = window.pageYOffset;
      let minDiff = pageY;
      let index = -1;

      elements.forEach((el, i) => {
        const diff = Math.abs(pageY - el.offsetTop);
        if (diff < minDiff) {
          minDiff = diff;
          index = i;
        }
      });

      if (index >= 0) {
        this.$store.commit("navIndexes", { index });
      }
    },

    scrollTo(i) {
      const element = document.querySelector(`#content > *:nth-child(${i+1})`);
      if (element) {
        let top = element.getBoundingClientRect().top + window.pageYOffset;
        if (top > 0) {
          top += 2;
        }
        window.scrollTo({
          top,
          left: 0,
          behavior: "smooth"
        });
      }
    },

    onIntersection(entries) {
      entries.forEach(entry => {
        const index = parseInt(entry.target.getAttribute('data-index'));
        if (entry.target.classList.contains('intersection-line--top')) {
          if (entry.isIntersecting && !this.topLinesIndexes.includes(index)) {
            this.topLinesIndexes.push(index);            } 
          if (!entry.isIntersecting && this.topLinesIndexes.includes(index)) {
            this.topLinesIndexes.splice(this.topLinesIndexes.indexOf(index), 1);
          }
        }
        if (entry.target.classList.contains('intersection-line--bottom')) {
          if (entry.isIntersecting && !this.bottomLinesIndexes.includes(index)) {
            this.bottomLinesIndexes.push(index);            } 
          if (!entry.isIntersecting && this.bottomLinesIndexes.includes(index)) {
            this.bottomLinesIndexes.splice(this.bottomLinesIndexes.indexOf(index), 1);
          }
        }
      });
    }
  }
};
