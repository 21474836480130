export default {
  methods: {
    async apiLoad(endpoint, params = {}) {
      if (!window.mondoConfig.api.endpoints[endpoint]) {
        return Promise.reject(`${endpoint} endpoint does not exist`);
      }

      const url = `${window.mondoConfig.api.baseUrl}${window.mondoConfig.api.endpoints[endpoint]}`;
      const fetchParams = Object.assign({
        method: 'GET',
        mode: 'cors',
        cache: 'default'
      }, params);
      const response = await fetch(url, fetchParams);

      return await response.json();
    }
  }
};