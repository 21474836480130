import _ from "lodash";
import Vue from "vue";

export default {
  name: "TopHeader2",

  data: {
    getQuoteDisplay: true,
    scrollDirection: null,
    scrollY: null,
    style: "transparent",
    stylePrev: null,
  },

  computed: {
    hasColoredLogo(){
      return !this.isMobile && (this.$store.state.scrollComponentIndex==0 && this.$store.state.heroSlider.indexNext>0) 
    },
    isHidden() {
      return this.isMobile && this.scrollDirection === "down";
    },

    isMobile() {
      return this.$store.state.mediaQueries.includes("mobile");
    },

    navIndex() {
      return this.$store.state.navIndexes.index;
    },

    productsSliderVisible() {
      return this.$store.state.productsList.productIndex!=null;
    },

    scrollComponentIndex() {
      return this.$store.state.scrollComponentIndex;
    },

    scrollComponentName() {
      return this.$store.state.scrollComponentName;
    },

    sectionLabel() {
      return window.mondoConfig.strings.topHeaderLabels[this.scrollComponentIndex];
    },
    categories(){
      return this.$store.state.productsList.categories;
    },
    category(){
      return this.$store.state.productsList.category;
    },
  },

  watch: {
    isHidden(value) {
      this.$store.commit('topHeader', { isHidden: value });
    },

    productsSliderVisible() {
      this.onIntersection();
    },

    navIndex(value) {
      this.onIntersection();
    },

    scrollComponentName(value) {

      let style = "solid";
      this.getQuoteDisplay = true;

      if (value === "products-slider") {
        this.getQuoteDisplay = false;
        style = "transparent-alt";
      } else if (["hero-slider", "hero-slider-2", "hero-slider-3"].includes(value)) {
        this.getQuoteDisplay = false;
        style = "transparent";
      }

      this.style = style;
    },

    scrollY(value, valuePrev) {
      if (value < 0 || value === 0) {
        this.scrollDirection = "up";
        return;
      }

      if (value > valuePrev) {
        this.scrollDirection = "down";
      }
      else if (value < valuePrev) {
        this.scrollDirection = "up";
      }
    }
  },

  mounted() {
    setTimeout(() => {

      
      this.setup();
      this.onIntersection();

    }, 10);

    window.mondo.event.$on("TopHeader.styleHidden", () => {
      this.stylePrev = this.style;
      this.style = "hidden";
    });

    window.mondo.event.$on("TopHeader.stylePrev", () => {
      this.style = this.stylePrev ? this.stylePrev : "transparent";
      this.stylePrev = null;
    });
  },

  methods: {
    setup() {
      window.addEventListener("scroll", _.debounce(this.onScroll, 50, { leading: true }));
      this.$options.elements = Array.from(document.querySelectorAll('#content > *'));
    },
    setCategory(cat){
      this.scrollToProducts();
      window.mondo.event.$emit("TopHeader.setCategory", cat);
    },
    onIntersection() {
      let scrollComponentName;
      let entryOverlay = this.$options.elements[this.navIndex];
      let entryIndex;
      
      if (this.$options.elements[0] && !entryOverlay) {
        entryOverlay = this.$options.elements[0];
      }

      entryIndex = Array.from(entryOverlay.parentNode.children).indexOf(entryOverlay);
      
      if (entryOverlay.classList.contains("hero-slider")) {
        scrollComponentName = "hero-slider";
      } else if (entryOverlay.classList.contains("hero-slider-2")) {
        scrollComponentName = "hero-slider-2";
      } else if (entryOverlay.classList.contains("hero-slider-3")) {
        scrollComponentName = "hero-slider-3";
      } else if (entryOverlay.classList.contains("info-slider")) {
        scrollComponentName = "info-slider";
      } else if (entryOverlay.classList.contains("products-container")) {
        if (this.productsSliderVisible) {
          scrollComponentName = "products-detail";
        } else {
          scrollComponentName = "products-list";
        }
      } else if (entryOverlay.classList.contains("advantages-slider")) {
        scrollComponentName = "advantages-slider";
      } else if (entryOverlay.classList.contains("references-slider")) {
        scrollComponentName = "references-slider";
      } else if (entryOverlay.classList.contains("contact-form")) {
        scrollComponentName = "contact-form";
      }  else if (entryOverlay.classList.contains("editorial")) {
        scrollComponentName = "editorial";
      }  else if (entryOverlay.classList.contains("references-slider-2")) {
        scrollComponentName = "references-slider-2";
      }

      this.$store.commit("scrollComponentName", scrollComponentName);
      this.$store.commit("scrollComponentIndex", entryIndex);
    },

    onScroll() {
      this.scrollY = window.pageYOffset;
    },

    scrollToProducts() {
      const productsEl = document.querySelector(".products-container");
      if (!productsEl) {
        return;
      }
      const top = productsEl.getBoundingClientRect().y + window.pageYOffset;
      
      window.scrollTo({
        top,
        behavior: "smooth"
      });
    },

    scrollToContacts() {
      const contactFormEl = document.querySelector(".contact-form");
      if (!contactFormEl) {
        return;
      }
      const top = contactFormEl.getBoundingClientRect().y + window.pageYOffset;
      
      window.scrollTo({
        top,
        behavior: "smooth"
      });

      this.$store.commit("productsList", {productIndex:null});
    },

    toggleNavOverlay() {
      this.$store.commit("navOverlay", { display: true });
    }
  }
}
