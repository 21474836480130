export default {
  modules: {},

  // initialize framework
  init(params) {
    return Promise.resolve()
      .then(() => this.domInit())
      .then(() => this.modulesInit(params.modules))
  },

  // wait for the dom
  domInit() {
    return new Promise(resolve => {
      if (["interactive", "complete"].includes(document.readyState)) {
        resolve()
      } else {
        document.addEventListener("readystatechange", e => {
          if (["interactive", "complete"].includes(e.target.readyState)) {
            resolve()
          }
        })
      }
    })
  },

  // initialize sette modules
  modulesInit(modules) {
    if (!(typeof modules === "object" && modules.constructor === Object)) {
      return Promise.resolve()
    }

    const modulesInit = Object.entries(modules).map(moduleObj => {
      const key = moduleObj[0]
      const module = moduleObj[1].module
      const params = moduleObj[1].params

      this.modules[key] = module

      if (module.init && typeof module.init === "function") {
        return module.init(this, params)
      } else {
        return Promise.resolve()
      }
    })

    return Promise.all(modulesInit)
  }
}