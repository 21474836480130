import gsap from "gsap";

export default {
  methods: {
    tweenCssTransition(el, className) {
      return new Promise(resolve => {
        const listener = () => {
          el.removeEventListener("transitionend", listener);
          resolve(el);
        }
        el.addEventListener("transitionend", listener);
        el.classList.add(className);
      });
    },

    tweenTitleReveal(el) {
      const rows = el.querySelectorAll("div > div");
      return gsap.fromTo(rows, {
        yPercent: 100
      }, {
        yPercent: 0,
        stagger: 0.075,
        ease: "power4.inOut",
        duration: 1,
        clearProps: "transform"
      });
    },

    tweenImageWrapperMask(el) {
      const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      const from = { clipPath: 'inset(0% 100% 0% 0%)' };
      const to = {
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: 1,
        ease: "power3.inOut",
        clearProps: "clip-path"
      }

      if (!isFirefox) {
        from.webkitClipPath = 'inset(0% 100% 0% 0%)';
        to.webkitClipPath = 'inset(0% 0% 0% 0%)';
        to.clearProps = "clip-path,-webkit-clip-path";
      }

      return gsap.fromTo(el, from, to);
    },

    tweenImageMask(el) {
      return gsap.fromTo(el, {
        scale: 1.1,
        x: '-2rem',
        transformOrigin: '100% 50%'
      }, {
        scale: 1,
        x: 0,
        duration: 1.5,
        ease: "power4.inOut",
        clearProps: "transform,transformOrigin"
      });
    },

    tweenLettersIn(els, paramsFrom = {}, paramsTo = {}) {
      const from = Object.assign({
        yPercent: index => index % 2 === 1 ? -100 : 100
      }, paramsFrom);
      const to = Object.assign({
        yPercent: 0,
        ease: "expo.out",
        duration: 1.7,
        clearProps: "transform"
      }, paramsTo);
      return gsap.fromTo(els, from, to);
    },

    tweenLettersOut(els, paramsTo = {}) {
      const to = Object.assign({
        yPercent: index => index % 2 === 1 ? -100 : 100,
        ease: "expo.inOut",
        duration: 0.85
      }, paramsTo);
      return gsap.to(els, to);
    }
  }
}