import AdvantagesSlider from "./advantages-slider.js";
import CookieBar from "./cookie-bar.js";
import ContactForm from "./contact-form.js";
import Editorial from "./editorial.js";
import Footer from "./footer.js";
import HeroSlider from "./hero-slider.js";
import HeroSlider2 from "./hero-slider-2.js";
import HeroSlider3 from "./hero-slider-3.js";
import InfoSlider from "./info-slider.js";
import Lightbox from "./lightbox.js";
import NavIndexes from "./nav-indexes.js";
import NavOverlay from "./nav-overlay.js";
import MouseCursor from "./mouse-cursor";
import ProductsGrid from "./products-grid.js";
import ProductsSlider from "./products-slider.js";
import ReferencesSlider from "./references-slider.js";
import ReferencesSlider2 from "./references-slider2.js";
import TopHeader from "./top-header.js";
import TopHeader2 from "./top-header-2.js";
import ProductsList from "./products-list.js";
import ProductsDetail from "./products-detail.js";
import LightboxGallery from "./lightbox-gallery.js";

export default {
    AdvantagesSlider,
    CookieBar,
    ContactForm,
    Editorial,
    Footer,
    HeroSlider,
    HeroSlider2,
    HeroSlider3,
    InfoSlider,
    Lightbox,
    NavIndexes,
    NavOverlay,
    MouseCursor,
    ProductsGrid,
    ProductsSlider,
    ReferencesSlider,
    ReferencesSlider2,
    TopHeader,
    TopHeader2,
    ProductsList,
    ProductsDetail,
    LightboxGallery
}
