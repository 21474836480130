import Vue from "vue";
import gsap from "gsap";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import ApiLoadMixin from "../mixins/api.js";
import TweenMixin from "../mixins/tween";
import _ from "lodash";

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: "ProductsDetail",

  mixins: [ApiLoadMixin,TweenMixin],

  data: {
    isTweenIn: false,
    isTweenSpecs:false,
    visible:false,
    prevIndex:null,
    nextIndex:null,
    imageIndex:0,
    altImage:null,
    specsActive:false,
    specsOpen:false,
    startX:null,
    accordionIndex:null,
    headerEl:null,
    thisEl:null,
    startPos:null,
    catSet:false
  },

  computed: {
    isMobile() {
      return this.$store.state.mediaQueries.includes("mobile");
    },
    products(){
      return this.$store.state.productsList.products;
    },
    productIndex(){
      return this.$store.state.productsList.productIndex;
    },
    categories(){
      return this.$store.state.productsList.categories;
    },
    category(){
      return this.$store.state.productsList.category;
    },
    categoryObj(){
      return this.$store.getters.getCategoryObj();
    },
    productsIcons() {
      return this.$store.state.productsIcons;
    },
    navOverlayDisplay() {
      return this.$store.state.navOverlay.display;
    },
    bkgTextBottom() {
      if (this.category) {
        return this.$store.getters.getCategoryObj().backgroundText[1];
      }
      return "";
    },
    bkgTextTop() {
      if (this.category) {
        return this.$store.getters.getCategoryObj().backgroundText[0];
      }
      return "";
    },
  },

  watch: {
    accordionIndex(value,oldValue){
      if(this.$refs.accordionItem.length)
      { 
        if(value!=null) this.$refs.accordionItem[value].style.height=this.$refs.accordionItem[value].scrollHeight+'px';
        if(oldValue!=null) this.$refs.accordionItem[oldValue].style.height=0;
      }
    },
    imageIndex(value){

      this.altImage=null;
      if(!this.isMobile) this.tweenImages(false);

    },
    productIndex(value,oldValue){

      this.imageIndex=0;
      if(this.visible) this.tweenImages(true);
      this.altImage=null;

      if(value!=null)
      {
        if(oldValue==null)
        {

          this.catSet=false;
          this.show();

          Vue.nextTick()
          .then(this.tweenIn)
        }
        else
        {
          const tl = gsap.timeline({});

          tl.fromTo('.products-detail__sheet', {
            opacity: 0,
            y:+15
          }, {
            opacity: 1,
            y:0,
            duration: 0.8,
            ease: "power2.inOut",
            clearProps: "all"
          }, 0)
          .fromTo('.products-detail__nav__button>.prod', {
            opacity: 0,
          }, {
            opacity: 1,
            y:0,
            duration: 0.8,
            ease: "power2.inOut",
            clearProps: "all"
          }, 0.4)
          .fromTo('.products-detail__box', {
            opacity: 0,
          }, {
            opacity: 1,
            y:0,
            duration: 0.8,
            ease: "power2.inOut",
            clearProps: "all"
          }, 0.4)
          .fromTo('.products-detail__thumbnails', {
            opacity: 0,
          }, {
            opacity: 1,
            y:0,
            duration: 0.8,
            ease: "power2.inOut",
            clearProps: "all"
          }, 0.4);
        }

        this.nextIndex=value<this.products.length-1?value+1:0;
        this.prevIndex=value>0?value-1:this.products.length-1;

        this.altImage=null;
        this.imageIndex=0;

      }
      else if(value==null && oldValue!=null)
      {
        if(this.visible) this.hide();
      }

    },
    category(value){
      if(this.visible && !this.isTweenIn)
      {
        Vue.nextTick()
        .then(()=>{
          this.tweenLettersIn(this.$refs.letters, { duration: 1.5 });
        })
      }
    },  
    specsActive(value){

      if(this.isMobile){
        this.specsOpen=value;
        return;
      }

      if(value){
        
        this.isTweenSpecs=true;

        document.body.classList.add("unscrollable");
        const t=.1+(Math.abs(this.$el.getBoundingClientRect().top)/1000);

        gsap.to(window, {ease:'expo.out',duration: t, scrollTo: this.$el.getBoundingClientRect().top + window.pageYOffset,onComplete:()=>{
          this.$store.commit("navIndexes", { display: false });
          this.appendSpecsHere();
          this.specsOpen=true;
          this.$refs.specs.style.overflow='visible';
          this.tweenSpecsIn()
          .then(()=>{
            this.isTweenSpecs=false;
          })
        }});
        

      }
      else
      {
        

        this.specsOpen=false;

        if(this.visible){
          this.isTweenSpecs=true;

          this.tweenSpecsOut()
          .then(()=>{
            this.$refs.specs.setAttribute('style',null)
            this.appendSpecsHeader();
            document.body.classList.remove("unscrollable");
            this.accordionIndex=null;
            this.$store.commit("navIndexes", { display: true });

            this.isTweenSpecs=false;
          });
        }
        

      }
    }
  },
  mounted() {
    window.addEventListener('resize',_.debounce(this.onRes,500));
  },
  methods: {
    toggleSpecs(){
      if(this.isTweenSpecs) return;
      this.specsActive=!this.specsActive;
    },
    tweenImages(immediately){
      if(this.products && this.products[this.productIndex])
      {
        let targetX=-this.imageIndex*(this.$refs.carousel.clientWidth/this.products[this.productIndex].images.length);
        gsap.to(this.$refs.carousel,{duration:immediately?0:.5,ease:'expo.out',x:targetX})
      }
    },
    isInline(list){
      return list.filter(el=>('label' in el && el.label!='')).length==0;
    },
    scrollToContacts() {
      const contactFormEl = document.querySelector(".contact-form");
      if (!contactFormEl) {
        return;
      }
      const top = contactFormEl.getBoundingClientRect().y + window.pageYOffset;
      
      window.scrollTo({
        top,
        behavior: "smooth"
      });

    },
    getPinStyle(pin){
      const defaults=this.categoryObj.defaultPins[('video' in pin)?'video':'led'];
      return {
        top:(pin.top||defaults.top)+'%',
        left:(pin.left||defaults.left)+'%'
      }
    },
    accordionGalleryClick(index,items){
        window.mondo.event.$emit("LightboxGallery.toggle", {index,items});
      
    },
    accordionClick(i){
      this.accordionIndex=this.accordionIndex!=i?i:null;
    },
    getTransX(){
      let transX=this.$refs.carousel.style.transform.match(/translate(?:3d)?\(([.\-0-9]+)px/);
      return transX?parseFloat(transX[1]):0;
    },
    dragMove(e){
      if(e.cancelable) e.preventDefault();

      let x=this.isMobile?e.touches[0].clientX:e.x;
      let targetX=this.startPos+(x-this.startX);

      gsap.set(this.$refs.carousel,{x:targetX+'px'});

    },
    startDrag(e){

      if(this.isMobile && e.type=='mousedown'){
        return;
      }

      this.startX=this.isMobile?e.touches[0].clientX:e.x;
      this.startPos=this.getTransX();
      this.isDragging=true;

      document.addEventListener('touchmove',this.dragMove, { passive: false });
      document.addEventListener('touchend',this.stopDrag);
    },
    stopDrag(e){

      this.isDragging=false;

      document.removeEventListener('touchmove',this.dragMove);
      document.removeEventListener('touchend',this.stopDrag);

      const gap=(this.isMobile?e.changedTouches[0].clientX:e.x)-this.startX;

      if(Math.abs(gap)>50)
      {
        if(gap>0){
          this.imageIndex=Math.max(this.imageIndex-1,0);
        }
        else
        { 
          this.imageIndex=Math.min(this.imageIndex+1,this.products[this.productIndex].images.length-1);
        }

        this.tweenImages();
      }

    },
    pinClick(pin){
      if('video' in pin)
      {
        window.mondo.event.$emit("Lightbox.toggle", pin.video);
      }
      else
      {
        this.altImage=this.altImage?null:pin.imageAltUrl;
      }
    },
    next(){
      this.$store.commit("productsList", {productIndex:this.nextIndex,category:this.products[this.nextIndex].catId});
    },
    prev(){
      this.$store.commit("productsList", {productIndex:this.prevIndex,category:this.products[this.prevIndex].catId});
    },
    onRes(){
      if(this.visible && document.body.classList.contains('unscrollable'))
      {
        window.scrollTo({
          top: this.$el.getBoundingClientRect().top + window.pageYOffset,
        });
      }
    },
    show(){

      this.visible=true;

      Vue.nextTick()
      .then(()=>{

        this.$refs.box.addEventListener('touchstart',this.startDrag, { passive: false });
        
        if(this.isMobile)
        {
          document.body.classList.add("unscrollable");
        }
        else
        {
          this.headerEl=document.querySelector('.top-header-2');
          this.thisEl=document.querySelector('.products-detail__container');

          this.$refs.specs.style.top='-150%';
          this.appendSpecsHeader();
          
          setTimeout(()=>{
              this.$refs.specs.removeAttribute('style');
          },0)

        }

        window.scrollTo({
          top: this.$el.getBoundingClientRect().top + window.pageYOffset,
          behavior: "smooth"
        });


      })

    },
    appendSpecsHere(){
      if(this.thisEl.querySelector('.products-detail__techspecs')==null)
      {
        this.thisEl.appendChild(document.querySelector('.products-detail__techspecs'))
      }
    },
    appendSpecsHeader(){
      if(this.headerEl.querySelector('.products-detail__techspecs')==null)
      {
        this.headerEl.insertBefore(document.querySelector('.products-detail__techspecs'),this.headerEl.firstChild)
      }
    },
    hide(){

      this.$refs.carousel.removeEventListener('touchstart',this.startDrag, { passive: false });

      this.tweenOut();

      if(this.isMobile){
        document.body.classList.remove("unscrollable");
        return;
      }

      if(this.specsActive)
      {
        document.body.classList.remove("unscrollable");
        this.$store.commit("navIndexes", { display: true });
      }
      
      Vue.nextTick()
      .then(()=>{
        setTimeout(this.appendSpecsHere,1000);
      });
      
    },
    setProductCat(){
      this.$store.commit("productsList", {category:this.products[this.productIndex].catId});
      Vue.nextTick()
      .then(()=>{
        this.catSet=true;
      })
    },
    tweenIn() {
      const tl = gsap.timeline({
        onComplete: () => {
          this.isTweenIn = false;
        }
      });
      if (this.isTweenIn) {
        return;
      }
      this.isTweenIn = true;

      if(!this.isMobile)
      {
        
        tl.fromTo(this.$el, {
          opacity: 0
        }, {
          opacity: 1,
          duration: 0.8,
          ease: "power2.inOut",
          clearProps: "all"
        }, 0)
        .call( this.setProductCat)
        .fromTo('.products-detail__box', {
          opacity: 0
        }, {
          opacity: 1,
          duration: .8,
          ease: "power2.inOut",
          clearProps: "opacity"
        }, .8)
        .fromTo('.products-detail__thumbnails', {
          opacity: 0
        }, {
          opacity: 1,
          duration: .8,
          ease: "power2.inOut",
          clearProps: "opacity"
        }, 1)
        .fromTo('.products-detail__content', {
          opacity: 0,
          right:'-25rem',
        }, {
          opacity: 1,
          right:'-18.1041666666667rem',
          duration: .8,
          ease: "power2.inOut",
          clearProps: "opacity"
        }, .8)
        
        .add(this.tweenLettersIn(this.$refs.letters, { duration: 1.5 }),1)

      }
      else
      {
        //products-detail__content
        tl.fromTo(this.$el, {
          opacity: 0
        }, {
          opacity: 1,
          duration: 0.8,
          ease: "power2.inOut",
          clearProps: "all"
        }, 0)
        .call( this.setProductCat)
        .fromTo('.products-detail__topnav', {
          top: '-18.75rem'
        }, {
          top:0,
          duration: .8,
          ease: "power2.inOut",
          clearProps: "top"
        }, .4)
        .from('.products-detail__specs-label-mobile',{
          opacity:0,
          scaleX:0,
          scaleY:0,
          duration: .8,
          ease: "power2.out"
        },.8)
        .from('.products-detail__content, .products-detail__mobile-specs-background, .products-detail__mobile-info-background',
          {
            top:'+=25rem',
            opacity:0,
            duration: .5,
            ease: "power2.inOut"
          },.0)
        .add(this.tweenLettersIn(this.$refs.letters, { duration: 1.5 }),1)
      }

      return tl.then();
  },
  tweenOut(){
    const tl = gsap.timeline({onComplete:()=>{
      this.$store.commit("productsList", {productIndex:null});
      this.visible=false;
      this.specsActive=false;
      this.imageIndex=0;
      this.accordionIndex=null;
    }});

    tl.to(this.$el,{
      opacity: 0,
      duration: .8,
      ease: "power2.inOut",
      clearProps: "opacity"
    }, 0);

    return tl;
  },
  tweenSpecsIn(){
    const tl = gsap.timeline({});

    if(!this.isMobile){
    
      tl.fromTo(this.$refs.specs, {
        right: '-45.1145833333334rem'
      }, {
        right:'-18.1041666666667rem',
        duration: 0.8,
        ease: "power2.inOut",
        clearProps: "right"
      }, 0)
      .fromTo('.products-detail__techspecs', {
        height:'9.114583333333336rem'
      }, {
        height:'100%',
        duration: 0.8,
        ease: "power2.inOut",
        clearProps: "height"
      }, 0)
      .fromTo('.products-detail__techspecs-bg', {
        skewX: '18deg',
        height:'4.94791666666667rem'
      }, {
        skewX:'-18deg',
        height:'100%',
        duration: 0.8,
        ease: "power2.inOut",
        clearProps: "transform,height"
      }, 0)
      .fromTo('.products-detail__techspecs-top', {
        left: '7rem'
      }, {
        left:'3rem',
        duration: 0.8,
        ease: "power2.inOut",
        clearProps: "left"
      }, 0)
      .fromTo('.products-detail__techspecs-button', {
        left: '9rem'
      }, {
        left:'39.9895833333333rem',
        duration: 0.8,
        ease: "power2.inOut",
        clearProps: "left"
      }, 0)
      .add(this.tweenLettersIn(this.$refs.letters_, {}, {
        duration: 0.75
      }), 0.8)
      .fromTo('.products-detail__techspecs-bottom', {
        opacity:0
      }, {
        opacity:1,
        duration: 0.8,
        ease: "power2.inOut",
        clearProps: "opacity"
      }, .7)
      .fromTo('.products-detail__techspecs-description', {
        opacity:0
      }, {
        opacity:1,
        duration: 0.8,
        ease: "power2.inOut",
        clearProps: "opacity"
      }, .7)
    }

    return tl.then();
  },
  tweenSpecsOut(){
    const tl = gsap.timeline({});

    if(!this.isMobile){

      tl.fromTo(this.$refs.specs, {
        right:'-18.1041666666667rem',
      }, {
        right: '-45.1145833333334rem',
        duration: 0.8,
        ease: "power2.inOut",
        clearProps: "right"
      }, 0.6)
      .fromTo('.products-detail__techspecs', {
        height:'100%',
      }, {
        height:'9.114583333333336rem',
        duration: 0.8,
        ease: "power2.inOut",
        clearProps: "height"
      }, 0.6)
      .fromTo('.products-detail__techspecs-bg', {
        skewX: '-18deg',
        height:'100%'
      }, {
        skewX:'18deg',
        height:'4.94791666666667rem',
        duration: 0.8,
        ease: "power2.inOut",
        clearProps: "transform"
      }, 0.6)
      .fromTo('.products-detail__techspecs-top', {
        left: '3rem'
      }, {
        left:'7rem',
        duration: 0.8,
        ease: "power2.inOut",
        clearProps: "left"
      }, 0.6)
      .fromTo('.products-detail__techspecs-button', {
        left:'39.9895833333333rem'
      }, {
        left: '9rem',
        duration: 0.8,
        ease: "power2.inOut",
        clearProps: "left"
      }, 0.6)
      .add(this.tweenLettersOut(this.$refs.letters_, {}, {
        duration: 0.75
      }), 0)
      .fromTo('.products-detail__techspecs-bottom', {
        opacity:1
      }, {
        opacity:0,
        duration: 0.8,
        ease: "power2.inOut",
        clearProps: "opacity"
      }, 0) 
      .fromTo('.products-detail__techspecs-description', {
        opacity:1
      }, {
        opacity:0,
        duration: 0.8,
        ease: "power2.inOut",
        clearProps: "opacity"
      }, 0)
    }

    return tl.then();
  }
  }
};
