import gsap from "gsap";
import TweenMixin from "../mixins/tween.js";
import ApiLoadMixin from "../mixins/api.js";
import FloatingButtonMixin from "../mixins/floatingbutton.js";

export default {
  name: "HeroSlider2",

  mixins: [ApiLoadMixin, TweenMixin, FloatingButtonMixin],

  data: {
    index: -1, // slide index
    indexThumbnail: 0, // index only for thumbnail
    indexDisplay: 1, // index to display in bar
    interactions: true,
    intersecting: false,
    isVideoSlide: false,
    panelActive: false,
    slides: [],
    timeout: null
  },

  computed: {
    indexNext() {
      if (this.index + 1 === this.slides.length) {
        return 0;
      }
      return this.index + 1;
    },

    indexPrev() {
      if (this.index - 1 < 0) {
        return this.slides.length - 1;
      }
      return this.index - 1;
    },

    isMobile() {
      return this.$store.state.mediaQueries.includes('mobile');
    },

    lightboxActive() {
      return this.$store.state.lightbox.active;
    }
  },

  watch: {
    index() {
      this.setTimeout();
    },

    intersecting(value) {
      if (this.slides.length) {
        if (value) {
          this.toggle('next');
        } else {
          this.index = -1;
          this.indexDisplay = 1;
          this.indexThumbnail = 0;
          this.interactions = true;
          clearTimeout(this.$options.timeout);
        }
      }
    },

    lightboxActive(value) {
      if (value) {
        clearTimeout(this.$options.timeout);
        if (this.$options.tweenTimeout) {
          this.tweenTimeoutReset();
        }
      }
      else {
        this.setTimeout()
      }
    }
  },

  mounted() {
    this.setup();
  },

  methods: {
    setup() {
      this.setupIntersection();
      Promise.resolve()
        .then(() => this.loadData())
        .then(() => new Promise(resolve => {
          this.preloadThumbs();
          setTimeout(resolve, 10);
        }))
        .then(() => this.toggle('next'));
    },

    setupIntersection() {
      // start stop slider timeout when in or out of the viewport
      this.$options.intObserver = new IntersectionObserver(entries => {
        this.intersecting = entries[0].isIntersecting;
      }, {
        threshold: 0.1
      });

      this.$options.intObserver.observe(this.$el);
    },

    loadData() {
      return this.apiLoad(this.$el.getAttribute("data-endpoint")).then(data => {
        if (data && data.slides && data.slides.length) {
          this.slides = data.slides;
        }
        if (data && data.timeout && typeof data.timeout === "number") {
          this.timeout = data.timeout;
        }
        this.floatingButtonSet(data);
      });
    },

    buttonEffect(button) {
      if (button.url) {
        location.href = button.url;
      }
      if (button.lightboxContent) {
        this.toggleLightbox(button.lightboxContent)
      }
      if (typeof button.scrollTo === 'number') {
        window.mondo.event.$emit("NavIndexes.scrollTo", button.scrollTo);
      }
    },

    next() {
      this.toggle('next');
    },

    prev() {
      this.toggle('prev');
    },

    toggle(param) {
      if (!this.interactions) {
        return;
      }

      this.isVideoSlide = false;
      clearTimeout(this.$options.timeout);
      this.interactions = false;
      this.indexDisplay = (param === 'next') ? this.indexNext + 1 : this.indexPrev + 1;

      // set indexThumbnail
      Promise.resolve()
        .then(() => this.tweenThumbOut())
        .then(() => {
          let nextNextIndex;
          if (typeof param === 'number') {
            nextNextIndex = param;
          } else {
            nextNextIndex = (param === 'next') ? this.indexNext + 1 : this.indexPrev - 1;
          }
          if (nextNextIndex >= this.slides.length) {
            nextNextIndex = 0;
          }
          if (nextNextIndex < 0) {
            nextNextIndex = this.slides.length - 1;
          }
          this.indexThumbnail = nextNextIndex;
        })
        .then(() => this.tweenThumbIn());

      // reset bar
      this.tweenTimeoutReset();

      // play next video
      const nextSlide = param === 'next' ? this.$refs.slides[this.indexNext] : this.$refs.slides[this.indexPrev];
      if (nextSlide) {
        const video = nextSlide.querySelector('video');
        if (video) {
          this.isVideoSlide = true;
          video.currentTime = 0;
          video.play();
          video.addEventListener('ended', this.onVideoEnded);
        }
      }

      // transition
      this.tweenToggleSlide(param)
        .then(() => {
          if (!this.intersecting) {
            return;
          }
          this.index = (param === 'next') ? this.indexNext : this.indexPrev;
          this.interactions = true;
        })
    },

    onVideoEnded(e) {
      this.isVideoSlide = false;
      this.next();
      e.target.removeEventListener('ended', this.onVideoEnded);
    },

    setTimeout() {
      if (!this.timeout) {
        return;
      }
      clearTimeout(this.$options.timeout);
      if (!this.intersecting) {
        return;
      }
      this.$options.timeout = setTimeout(() => {
        if (this.isVideoSlide) {
          return;
        }
        this.next();
      }, this.timeout);
      this.tweenTimeout();
    },

    preloadThumbs() {
      this.slides.forEach(slide => {
        if (slide.thumbnailImageUrl) {
          const img = document.createElement('img');
          img.src = slide.thumbnailImageUrl;
        }
      });
    },

    toggleLightbox(content) {
      window.mondo.event.$emit("Lightbox.toggle", content);
    },

    tweenTimeout() {
      if (!this.timeout) {
        return;
      }

      if (this.isVideoSlide) {
        this.tweenTimeoutReset();
        return;
      }

      this.$options.tweenTimeout = gsap.fromTo(
        this.$refs.timeoutBar,
        {
          scaleX: 0
        },
        {
          scaleX: 1,
          duration: this.timeout / 1000,
          ease: 'linear'
        }
      );
    },

    tweenTimeoutReset() {
      if (this.$options.tweenTimeout) {
        this.$options.tweenTimeout.pause();
      }
      
      gsap.to(
        this.$refs.timeoutBar,
        {
          scaleX: 0,
          duration: 0.5,
          ease: 'power2.out'
        }
      );
    },

    tweenToggleSlide(direction) {
      const tl = gsap.timeline();
      const selector = direction === 'next' ? '.hero-slider-2__slide--next' : '.hero-slider-2__slide--prev';
      const slide = this.$el.querySelector(selector);

      if (!slide) {
        return;
      }

      const polygonObj = {
        xTopLeft: direction === 'next' ? 100 : 0,
        xBottomLeft: direction === 'next' ? 100 : 0,
        xTopRight: direction === 'next' ? 100 : 0,
        xBottomRight: direction === 'next' ? 100 : 0
      };

      const tween1Param = direction === 'next' ? { xTopLeft: 0 } : { xTopRight: 100 };
      const tween2Param = direction === 'next' ? { xBottomLeft: 0 } : { xBottomRight: 100 };

      tl.to(
        polygonObj,
        Object.assign({
          duration: 1.2,
          ease: 'power2.inOut',
          onUpdate: () => {
            const value = `polygon(${polygonObj.xTopLeft}% 0%, ${polygonObj.xTopRight}% 0%, ${polygonObj.xBottomRight}% 100%, ${polygonObj.xBottomLeft}% 100%)`;
            slide.style.webkitClipPath = value;
            slide.style.clipPath = value;
          }
        }, tween1Param),
        0
      );
      
      tl.to(
        polygonObj,
        Object.assign({
          duration: 1.4,
          ease: 'power2.inOut',
          onUpdate: () => {
            const value = `polygon(${polygonObj.xTopLeft}% 0%, ${polygonObj.xTopRight}% 0%, ${polygonObj.xBottomRight}% 100%, ${polygonObj.xBottomLeft}% 100%)`;
            slide.style.webkitClipPath = value;
            slide.style.clipPath = value;
          },
        }, tween2Param),
        0
      );

      const image = slide.querySelector('picture');
      if (image) {
        tl.fromTo(
          image,
          {
            opacity: 0.4,
            xPercent: direction === 'next' ? 10 : -10,
            transformOrigin: direction === 'next' ? '0 50%' : '100% 0',
            scale: 1.2
          },
          {
            opacity: 1,
            xPercent: 0,
            scale: 1,
            duration: 1.5,
            ease: 'power2.inOut',
            clearProps: 'all'
          },
          0
        );
      }
      
      const title = slide.querySelector('.hero-slider-2__content > h1');
      if (title) {
        tl.add(this.tweenTitleReveal(title), 0.35);
      }

      const smallTitle = slide.querySelector('.hero-slider-2__content > h6');
      if (smallTitle) {
        tl.fromTo(
          smallTitle,
          {
            opacity: 0,
            x: -15
          },
          {
            opacity: 1,
            x: 0,
            duration: 0.8,
            ease: 'power4.out',
            clearProps: 'all'
          },
          1
        );
      }

      const actions = slide.querySelector('.hero-slider-2__actions');
      if (actions) {
        tl.fromTo(
          actions,
          {
            opacity: 0,
            x: -15
          },
          {
            opacity: 1,
            x: 0,
            duration: 0.8,
            stagger: 0.1,
            ease: 'power4.out',
            clearProps: 'all'
          },
          1.25
        );
      }

      // panel in
      if (!this.panelActive) {
        this.tweenPanelIn();
      }

      return tl.then(() => {
        slide.style.webkitClipPath = null;
        slide.style.clipPath = null;
      });
    },

    tweenThumbOut() {
      if (this.$refs.thumb) {
        const tween = gsap.to(
          this.$refs.thumb,
          {
            opacity: 0,
            x: -15,
            duration: 0.3,
            delay: 0.25,
            ease: 'power2.in'
          }
        );
  
        return tween;
      }

      return null;
    },
    
    tweenThumbIn() {
      if (this.$refs.thumb) {
        const tween = gsap.fromTo(
          this.$refs.thumb,
          {
            opacity: 0,
            x: -15
          },
          {
            opacity: 1,
            x: 0,
            duration: 0.3,
            delay: 0.25,
            ease: 'power2.out',
            clearProps: 'all'
          }
        );
  
        return tween;
      }

      return null;
    },

    tweenPanelIn() {
      const tween = gsap.fromTo(
        this.$refs.panel,
        {
          opacity: 0,
          x: -15
        },
        {
          opacity: 1,
          x: 0,
          duration: 1,
          delay: 0.2,
          ease: 'power4.inOut',
          clearProps: 'all'
        }
      );

      return tween.then(() => {
        this.panelActive = true;
      });
    }
  }
}
