import Vue from "vue";
import gsap from "gsap";
import _ from "lodash";
import TweenMixin from "../mixins/tween.js";
import ApiLoadMixin from "../mixins/api.js";
import FloatingButtonMixin from "../mixins/floatingbutton.js";

export default {
  name: "HeroSlider",

  mixins: [TweenMixin, ApiLoadMixin, FloatingButtonMixin],

  data: {
    cursorOverPin: false,
    index: null,
    indexNext: null,
    indexDisplay: null,
    maxIndex: 0,
    pointerX: 0,
    pointerY: 0,
    slides: [],
    viewportWidth: 0,
    viewportHeight: 0,
    stopInteractions: false,
    timeout: 6000,
    videoTime: 0
  },

  computed: {
    isMobile() {
      return this.$store.state.mediaQueries.includes('mobile');
    },

    isParallax() {
      if (this.stopInteractions) {
        return false
      }
      if (this.slides[this.index] && this.slides[this.index].image && this.slides[this.index].image.wideParallaxUrl && this.slides[this.index].image.portraitUrlParallaxUrl) {
        return true;
      }
      return false;
    },

    lightboxActive() {
      return this.$store.state.lightbox.active;
    },

    mediaQueries() {
      return this.$store.state.mediaQueries;
    },

    pointerPercentX() {
      if (!this.isParallax) {
        return 50;
      }
      return this.pointerX / (this.viewportWidth * 0.01)
    },
    
    pointerPercentY() {
      if (!this.isParallax) {
        return 50;
      }
      return this.pointerY / (this.viewportHeight * 0.01)
    }
  },

  watch: {
    cursorOverPin(value) {
      clearTimeout(this.$options.timeout);
      if (!value) {
        this.$options.timeout = setTimeout(this.next, this.timeout);        
      }
    },

    indexNext(value) {
      if (typeof value === "number") {
        Vue.nextTick().then(this.toggleSlide);
      }
    },

    lightboxActive(value) {
      if (value) {
        clearTimeout(this.$options.timeout);
      }
      else {
        this.$options.timeout = setTimeout(this.next, this.timeout); // restart timeout
      }
    },

    pointerPercentX() {
      this.tweenParallax();
    },

    pointerPercentY() {
      this.tweenParallax();
    },

    slides(values) {
      if (values && values.length && this.index === null) {
        this.maxIndex = values.length - 1;
      }
    }
  },

  mounted() {
    this.$el.addEventListener("keydown", this.onKeyDown, false);
    this.setup();
  },

  beforeDestroy() {
    this.$el.removeEventListener("keydown", this.onKeyDown, false);
  },

  methods: {
    setup() {
      // set viewportWidth for parallax slides
      this.viewportWidth = window.innerWidth;
      this.viewportHeight = window.innerHeight;
      window.addEventListener('resize', _.debounce(() => {
        this.viewportWidth = window.innerWidth;
        this.viewportHeight = window.innerHeight;
      }, 500));

      return Promise.resolve()
        .then(this.setupIntersection)
        .then(this.loadData)
        .then(this.preloadImages)
        .then(() => Vue.nextTick())
        .then(() => {
          if (this.slides.length) {
            this.indexNext = 0; // init slider
          }
        })
    },

    setupIntersection() {
      // start stop slider timeout when in or out of the viewport
      this.$options.intObserver = new IntersectionObserver(entries => {
        const entry = entries[0];
        if (entry) {
          if (entry.isIntersecting && this.slides.length && this.index === null) {
            this.indexNext = 0; // toggle in slider when the slider is displayed again
            this.$options.timeout = setTimeout(this.next, this.timeout); // restart timeout
          }

          if (!entry.isIntersecting && this.index !== null) {
            this.index = null;
            this.indexNext = null; // reset
            clearTimeout(this.$options.timeout); // stop slider
          }
        }
      }, {
        threshold: 0.1
      });

      this.$options.intObserver.observe(this.$el);
      return Promise.resolve();
    },

    loadData() {
      return this.apiLoad(this.$el.getAttribute("data-endpoint")).then(data => {
        if (data && data.slides && data.slides.length) {
          this.slides = data.slides;
        }
        if (data && data.timeout && typeof data.timeout === "number") {
          this.timeout = data.timeout;
        }
        this.floatingButtonSet(data);
      });
    },

    preloadImages() {
      let imagesUrl = [];
      if (this.mediaQueries.includes("mobile")) {
        imagesUrl = this.slides.filter(slide => !!(slide.image && slide.image.portraitUrl)).map(slide => slide.image.portraitUrl);
      } else {
        imagesUrl = this.slides.filter(slide => !!(slide.image && slide.image.portraitUrl)).map(slide => slide.image.wideUrl);
      }

      return Promise.all(imagesUrl.map(url => {
        return new Promise(resolve => {
          const img = document.createElement("img");
          img.addEventListener("load", resolve);
          img.setAttribute("src", url);
        });
      }));
    },

    toggleSlide() {
      if (this.indexNext < 0 || this.indexNext > this.maxIndex || this.indexNext === this.index) {
        return;
      }

      if (!this.$refs.slides.length) {
        return;
      }

      clearTimeout(this.$options.timeout);

      const tl = gsap.timeline();
      const slide = this.$refs.slides[this.indexNext];
      const slideElements = this.slideElements(slide);
      const revealElements = [];

      this.stopInteractions = true;

      if (slideElements.title) {
        tl.add(this.tweenTitleReveal(slideElements.title), 0);
      }

      if (slideElements.imageWrapper) {
        tl.add(this.tweenImageWrapperMask(slideElements.imageWrapper), 0);
      }

      if (slideElements.image) {
        tl.add(this.tweenImageMask(slideElements.image), 0);
      }

      if (slideElements.parallaxFront) {
        tl.fromTo(
          slideElements.parallaxFront,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 1.5,
            ease: "power4.inOut",
            clearProps: "opacity"
          },
          0
        )
      }

      if (slideElements.videoWrapper) {
        tl.add(this.tweenImageWrapperMask(slideElements.videoWrapper), 0);
      }

      if (slideElements.video) {
        tl.add(this.tweenImageMask(slideElements.video), 0);
      }

      if (slideElements.back1 && !this.mediaQueries.includes("mobile")) {
        tl.fromTo(slideElements.back1, {
          xPercent: -100,
          skewX: -35,
          opacity: 0
        }, {
          xPercent: 0,
          skewX: 0,
          opacity: 1,
          duration: 1,
          ease: "power4.inOut",
          clearProps: "transform,opacity"
        }, 0.2);
      }

      if (slideElements.back2 && !this.mediaQueries.includes("mobile")) {
        tl.fromTo(slideElements.back2, {
          xPercent: -100,
          skewX: -35,
          opacity: 0
        }, {
          xPercent: 0,
          skewX: 0,
          opacity: 1,
          duration: 1,
          ease: "power4.inOut",
          clearProps: "transform,opacity"
        }, 0.35);
      }

      if (slideElements.back1Mobile && this.mediaQueries.includes("mobile")) {
        tl.fromTo(slideElements.back1Mobile, {
          opacity: 0,
          yPercent: 100,
          skewY: 20,
        }, {
          yPercent: 0,
          skewY: 0,
          opacity: 1,
          duration: 1,
          ease: "power4.inOut",
          clearProps: "transform,opacity"
        }, 0.35);
      }

      if (slideElements.back2Mobile && this.mediaQueries.includes("mobile")) {
        tl.fromTo(slideElements.back2Mobile, {
          opacity: 0,
          yPercent: 100,
          skewY: 20,
        }, {
          yPercent: 0,
          skewY: 0,
          opacity: 1,
          duration: 1,
          ease: "power4.inOut",
          clearProps: "transform,opacity"
        }, 0.2);
      }

      if (slideElements.smallTitle) {
        revealElements.push(slideElements.smallTitle);
      }

      if (slideElements.button) {
        revealElements.push(slideElements.button);
      }
      
      if (slideElements.description) {
        revealElements.push(slideElements.description);
      }

      if (slideElements.navItems && slideElements.navItems.length) {
        slideElements.navItems.forEach(el => { revealElements.push(el) });
      }

      if (revealElements.length) {
        tl.fromTo(revealElements, {
          opacity: 0,
          x: -15,
          scale: 1 // fix gsap behaviour
        }, {
          opacity: 1,
          x: 0,
          scale: 1,
          duration: 0.6,
          stagger: 0.1,
          ease: "power4.out",
          clearProps: "opacity"
        }, 0.75);
      }

      if (slideElements.pins && slideElements.pins.length && !this.isMobile) {
        tl.fromTo(
          slideElements.pins,
          {
            opacity: 0,
            scale: 1.2
          },
          {
            opacity: 1,
            scale: 1,
            duration: 0.8,
            stagger: 0.1,
            ease: "power4.out",
            clearProps: "transform,opacity"
          },
          1.5
        );
      }

      tl.then(() => {
        if (this.$refs.video && this.$refs.video[0]) {
          const videoCurrentTime = this.$refs.video[0].currentTime;
          // video is replaced
          setTimeout(() => {
            if (this.$refs.video && this.$refs.video[0]) {
              this.$refs.video[0].currentTime = videoCurrentTime;
            }
          }, 1);
        }

        this.index = this.indexNext;
        this.indexNext = null;
        this.stopInteractions = false;

        setTimeout(() => {
          // if video change slide at the end of the video
          if (this.$refs.video && this.$refs.video[0]) {
            this.$refs.video[0].addEventListener("ended", () => {
              if (this.lightboxActive) {
                this.$refs.video[0].play();
                return;
              }
              this.next(); 
            });
          } 
          // otherwise next slide at timeout
          else {
            this.$options.timeout = setTimeout(this.next, this.timeout);
          }
        }, 0);
      });
    },

    slideElements(slide) {
      return {
        title: slide.querySelector(".title-reveal"),
        imageWrapper: slide.querySelector(".hero-slider__image"),
        image: slide.querySelector(".hero-slider__image img"),
        videoWrapper: slide.querySelector(".hero-slider__video"),
        video: slide.querySelector(".hero-slider__video video"),
        back1: slide.querySelector(".hero-slider__back-1"),
        back1Mobile: slide.querySelector(".hero-slider__back-1-mobile"),
        back2: slide.querySelector(".hero-slider__back-2"),
        back2Mobile: slide.querySelector(".hero-slider__back-2-mobile"),
        button: slide.querySelector(".button"),
        smallTitle: slide.querySelector("h6"),
        navItems: Array.from(slide.querySelectorAll(".hero-slider__nav > *")),
        description: slide.querySelector('.hero-slider__description'),
        pins: slide.querySelectorAll(".hero-slider__pin"),
        parallaxFront: slide.querySelector(".hero-slider__parallaxfront")
      }
    },

    slideIsParallax(index) {
      if (this.slides[index] && this.slides[index].image && this.slides[index].image.wideParallaxUrl && this.slides[index].image.portraitUrlParallaxUrl) {
        return true;
      }
      return false;
    },

    prev() {
      if (this.stopInteractions) {
        return;
      }

      this.indexNext = this.index === 0 ? this.maxIndex : this.index - 1;
    },

    next() {
      if (this.stopInteractions) {
        return;
      }

      if (this.cursorOverPin) {
        return;
      }

      this.indexNext = this.index === this.maxIndex ? 0 : this.index + 1;
    },

    onMouseMove(e) {
      if (this.isMobile) {
        return
      }
      if (!this.isParallax) {
        return;
      }
      this.pointerX = e.clientX;
      this.pointerY = e.clientY;
    },

    onKeyDown(e) {
      if (e.key === "ArrowLeft") {
        this.prev();
      }
      if (e.key === "ArrowRight") {
        this.next();
      }
    },

    scrollTo(i) {
      window.mondo.event.$emit("NavIndexes.scrollTo", i);
    },
    
    toggleLightbox(content) {
      window.mondo.event.$emit("Lightbox.toggle", content);
    },

    pinClick(i) {
      if (
        this.slides[this.index] &&
        this.slides[this.index].pins &&
        this.slides[this.index].pins[i]
      ) {
        if (this.slides[this.index].pins[i].scrollTo) {
          this.scrollTo(this.slides[this.index].pins[i].scrollTo);
        }
      }
    },

    labelHtml(str) {
      if (str.length < 20) {
        return str;
      }
      
      const labelSplit = str.split(" ");
      str = "";

      const half = Math.round(labelSplit.length / 2);
      labelSplit.forEach((word, i) => {
        if (i === half) {
          str += "<br>";
        }
        str += `${word} `;
      });

      return str;
    },

    onPinsMouseEnter() {
      if (this.isMobile) {
        return;        
      }
      this.cursorOverPin = true;
    },

    onPinsMouseLeave() {
      if (this.isMobile) {
        return;        
      }

      this.cursorOverPin = false;
    },

    tweenParallax() {
      if (this.isMobile) {
        return;
      }
      const xPercentFrontMax = 3;
      const yPercentFrontMax = 3;
      const xPercentBackMax = 1.5;
      const yPercentBackMax = 1.5;
      const xPercentFront = (xPercentFrontMax * (this.pointerPercentX / 100)) - (xPercentFrontMax / 2);
      const yPercentFront = (yPercentFrontMax * (this.pointerPercentY / 100)) - (yPercentFrontMax / 2);
      const xPercentBack = (xPercentBackMax * (this.pointerPercentX / 100)) - (xPercentBackMax / 2);
      const yPercentBack = (yPercentBackMax * (this.pointerPercentY / 100)) - (yPercentBackMax / 2);

      if (this.$refs.parallaxFront) {
        gsap.to(
          this.$refs.parallaxFront,
          {
            xPercent: xPercentFront * -1,
            yPercent: yPercentFront * -1,
            ease: 'sine.out',
            duration: 0.5
          }
        );
      }

      if (this.$refs.image) {
        gsap.to(
          this.$refs.image,
          {
            xPercent: xPercentBack * -1,
            yPercent: yPercentBack * -1,
            ease: 'sine.out',
            duration: 0.5
          }
        );
      }
    }
  }
};
