import gsap from "gsap";
import TweenMixin from "../mixins/tween";

export default {
  name: "NavOverlay",

  mixins: [TweenMixin],

  data: {
    stopInteractions: false,
    wasUnscrollable: false
  },

  computed: {
    display() {
      return this.$store.state.navOverlay.display;
    },

    languages() {
      if (window.mondoConfig && window.mondoConfig.languages && window.mondoConfig.languages.length) {
        return window.mondoConfig.languages;
      }
      return [];
    },

    menu() {
      if (window.mondoConfig && window.mondoConfig.strings && window.mondoConfig.strings.navOverlayLabels) {
        return window.mondoConfig.strings.navOverlayLabels.map(label => ({ label }));
      }
      return [];
    },

    isMobile() {
      return this.$store.state.mediaQueries.includes("mobile");
    }
  },

  watch: {
    display(value) {
      if (value) {
        this.stopInteractions = true;
        this.tweenIn().then(() => {
          this.stopInteractions = false;
        });

        if(window.document.body.classList.contains("unscrollable"))
        {
          this.wasUnscrollable=true;
        }
        else
        {
          this.wasUnscrollable=false;
          window.document.body.classList.add("unscrollable");
        }
       
      } else {
        if(!this.wasUnscrollable) window.document.body.classList.remove("unscrollable");
      }
    }
  },

  methods: {
    close() {
      if (this.stopInteractions) {
        return;
      }
      this.stopInteractions = true;
      this.tweenOut().then(() => {
        this.stopInteractions = false;
        this.$store.commit("navOverlay", { display: false });
      });
    },

    goTo(i) {
      if (this.stopInteractions) {
        return;
      }
      const element = document.querySelector(`#content > *:not(script):nth-child(${i+1})`);
      let top
      if (element) {
        // go to element
        top = element.getBoundingClientRect().top + window.pageYOffset + 1
      } else {
        // go to footer middle
        console.log(document.querySelector('.footer__middle'))
        top = document.querySelector('.footer__middle').getBoundingClientRect().top + window.pageYOffset + 1
      }

      this.close();
      window.scrollTo({
        top,
        left: 0,
        behavior: "smooth"
      });
    },

    scrollToContacts() {
      if (this.stopInteractions) {
        return;
      }

      const contactFormEl = document.querySelector(".contact-form");
      if (!contactFormEl) {
        return;
      }

      const top = contactFormEl.getBoundingClientRect().y + window.pageYOffset + 1;
      this.close();
      
      window.scrollTo({
        top,
        behavior: "smooth"
      });
    },

    async tweenIn() {
      if (this.isMobile) {
        return this.tweenInMobile();
      }
      const tl = gsap.timeline();
      const shapeWidth = this.$refs.shape.offsetWidth;
      const viewportWidth = window.innerWidth;
      const rem = viewportWidth / 100;

      tl.fromTo(this.$refs.background, {
          opacity: 0
        }, {
          opacity: 1,
          duration: 1,
          ease: "power3.out",
          clearProps: "opacity"
        }, 0)
        .fromTo(this.$refs.shape, {
          // x: -viewportWidth,
          x: shapeWidth,
          skewX: 0,
          scale: 1
        }, {
          x: 12.1 * rem,
          skewX: -18,
          scale: 1,
          duration: 0.7,
          ease: "power4.inOut",
          clearProps: "transform"
        }, 0)
        .fromTo(this.$refs.menu.querySelectorAll("li"), {
          opacity: 0,
          x: rem * 2
        }, {
          opacity: 1,
          x: 0,
          duration: 0.7,
          stagger: 0.05,
          ease: "power4.inOut",
          clearProps: "opacity,transform"
        }, 0.2)
        .add(this.tweenLettersIn(this.$refs.letters, {}, {
          duration: 0.75
        }), 0.25)
        .fromTo([this.$refs.button1, this.$refs.button2], {
          opacity: 0,
          x: rem * 2,
          scale: 1
        }, {
          opacity: 1,
          x: 0,
          scale: 1,
          ease: "power4.inOut",
          duration: 0.5,
          stagger: 0.1,
          clearProps: "opacity,transform"
        }, 0.25)
        .fromTo(this.$refs.icons.querySelectorAll("a"), {
          opacity: 0,
          x: rem,
        }, {
          opacity: 1,
          x: 0,
          ease: "power4.inOut",
          duration: 0.5,
          stagger: 0.1,
          clearProps: "opacity,transform"
        }, 0.25)
        .fromTo(this.$refs.close, {
          opacity: 0
        }, {
          opacity: 1,
          ease: "power4.inOut",
          duration: 0.5,
          clearProps: "opacity"
        }, 0.25);
      
      if (this.$refs.languages) {
        tl .fromTo(this.$refs.languages, {
          opacity: 0
        }, {
          opacity: 1,
          ease: "power4.inOut",
          duration: 0.5,
          clearProps: "opacity"
        }, 0.25);
      }

      return tl.then();
    },

    async tweenOut() {
      if (this.isMobile) {
        return this.tweenOutMobile();
      }

      const tl = gsap.timeline();
      const shapeWidth = this.$refs.shape.offsetWidth;
      const viewportWidth = window.innerWidth;
      const rem = viewportWidth / 100;

      tl.to(this.$refs.menu.querySelectorAll("li"), {
        opacity: 0,
        x: rem * 2,
        duration: 0.5,
        stagger: 0.1,
        ease: "power4.inOut"
      }, 0)
      .add(this.tweenLettersOut(this.$refs.letters, {}, {
        duration: 0.75
      }), 0)
      .to([this.$refs.button2, this.$refs.button1], {
        opacity: 0,
        x: rem * 2,
        scale: 1,
        ease: "power4.inOut",
        duration: 0.5,
        stagger: 0.1
      }, 0)
      .to(this.$refs.icons.querySelectorAll("a"), {
        opacity: 0,
        x: rem * 2,
        ease: "power4.inOut",
        duration: 0.5,
        stagger: 0.1
      }, 0)
      .to(this.$refs.close, {
        opacity: 0,
        ease: "power4.inOut",
        duration: 0.5
      }, 0)
      .to(this.$refs.shape, {
        x: shapeWidth,
        skewX: 0,
        scale: 1,
        duration: 0.75,
        ease: "power4.inOut"
      }, 0.5)
      .to(this.$refs.background, {
        opacity: 0,
        duration: 0.75,
        ease: "power3.out"
      }, 0.5);

      if (this.$refs.languages) {
        tl.to(this.$refs.languages, {
          opacity: 0,
          duration: 0.5,
          ease: "power4.inOut"
        }, 0);
      }

      return tl.then();
    },

    async tweenInMobile() {
      const tl = gsap.timeline();
      const viewportWidth = window.innerWidth;
      const rem = viewportWidth / 100;

      tl.fromTo(this.$refs.shape, {
        scaleY: 0,
        transformOrigin: "50% 0"
      }, {
        scaleY: 1,
        duration: 1,
        ease: "power4.inOut",
        clearProps: "transform,transformOrigin"
      }, 0)
      .fromTo(this.$refs.menu.querySelectorAll("li"), {
        opacity: 0,
        x: rem * 2
      }, {
        opacity: 1,
        x: 0,
        duration: 0.5,
        stagger: 0.1,
        ease: "power4.inOut",
        clearProps: "opacity,transform"
      }, 0.15)
      .fromTo(this.$refs.close, {
        opacity: 0
      }, {
        opacity: 1,
        ease: "power4.inOut",
        duration: 1,
        clearProps: "opacity"
      }, 0)
      .fromTo(this.$refs.buttons, {
        opacity: 0,
        y: rem
      }, {
        opacity: 1,
        y: 0,
        ease: "power4.inOut",
        duration: 0.5,
        clearProps: "opacity,transform"
      }, 0.5)
      .fromTo(this.$refs.icons, {
        opacity: 0,
        y: rem
      }, {
        opacity: 1,
        y: 0,
        ease: "power4.inOut",
        duration: 0.5,
        clearProps: "opacity,transform"
      }, 0.75);

      if (this.$refs.languages) {
        tl.fromTo(this.$refs.languages, {
          opacity: 0
        }, {
          opacity: 1,
          ease: "power4.inOut",
          duration: 1,
          clearProps: "opacity"
        }, 0);
      }

      return tl.then();
    },

    async tweenOutMobile() {
      const tl = gsap.timeline();
      const viewportWidth = window.innerWidth;
      const rem = viewportWidth / 100;

      gsap.set(this.$refs.shape, {
        transformOrigin: "50% 100%"
      })

      tl.to(this.$refs.shape, {
        scaleY: 0,
        duration: 1,
        ease: "power4.inOut"
      }, 0.25)
      .to(this.$refs.menu.querySelectorAll("li"), {
        opacity: 0,
        x: rem * 2,
        duration: 0.25,
        stagger: 0.05,
        ease: "power4.inOut"
      }, 0)
      .to(this.$refs.close, {
        opacity: 0,
        ease: "power4.inOut",
        duration: 0.5
      }, 0)
      .to(this.$refs.buttons, {
        opacity: 0,
        y: rem,
        ease: "power4.inOut",
        duration: 0.5
      }, 0.2)
      .to(this.$refs.icons, {
        opacity: 0,
        y: rem,
        ease: "power4.inOut",
        duration: 0.5
      }, 0.4)

      if (this.$refs.languages) {
        tl.to(this.$refs.languages, {
          opacity: 0,
          ease: "power4.inOut",
          duration: 0.5
        }, 0)
      }

      return tl.then();
    }
  }
};
