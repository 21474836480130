import TweenMixin from "../mixins/tween";

export default {
  name: "ContactForm",

  mixins: [TweenMixin],

  data: {
    errors: [],
    indexValues: null,
    steps: [
      {
        index: 1,
        label: "Tipologia di intervento",
        value: "seleziona",
        fieldValue: '',
        field: {
          type: "radio",
          name: "tipoprogetto"
        },
        values: [
          {
            value: "Calcetto a 5",
            label: "Calcetto a 5"
          },
          {
            value: "Calcio a 7",
            label: "CALCIO A 7"
          },
          {
            value: "Calcio a 8 / a 9",
            label: "CALCIO A 8/9"
          },
          {
            value: "Calcio a 11",
            label: "CALCIO A 11"
          }
        ]
      },
      {
        index: 2,
        label: "tipologia di campo",
        value: "seleziona",
        fieldValue: '',
        field: {
          type: "radio",
          name: "tipologiadicampo"
        },
        values: [
          {
            value: "5",
            label: "Calcetto a 5"
          },
          {
            value: "7",
            label: "Calcio a 7"
          },
          {
            value: "8",
            label: "Calcio a 8"
          },
          {
            value: "multisport",
            label: "Multisport"
          }
        ]
      },
      {
        index: 3,
        label: "descrizione progetto",
        info: "Per essere contattato al più presto descrivici il tuo progetto:",
        value: "seleziona",
        fieldValue: '',
        field: {
          type: "textarea",
          name: "descrizione"
        }
      },
      {
        index: 4,
        label: "Data prevista di realizzazione",
        value: "seleziona",
        fieldValue: '',
        field: {
          type: "radio",
          name: "anno"
        },
        values: [
          {
            value: "2020",
            label: "2020"
          },
          {
            value: "2021",
            label: "2021"
          },
          {
            value: "2022",
            label: "2022"
          },
          {
            value: "2023",
            label: "2023"
          },
          {
            value: "2024",
            label: "2024"
          },
          {
            value: "2025",
            label: "2025"
          },
          {
            value: "2026",
            label: "2026"
          },
          {
            value: "2027",
            label: "2027"
          },
          {
            value: "2028",
            label: "2028"
          },
          {
            value: "2029",
            label: "2029"
          },
          {
            value: "2030",
            label: "2030"
          },
          {
            value: "2031",
            label: "2031"
          },
          {
            value: "2032",
            label: "2032"
          },
          {
            value: "2033",
            label: "2033"
          },
          {
            value: "2034",
            label: "2034"
          },
          {
            value: "2035",
            label: "2035"
          },
          {
            value: "2036",
            label: "2036"
          },
          {
            value: "2037",
            label: "2037"
          },
          {
            value: "2038",
            label: "2038"
          },
          {
            value: "2039",
            label: "2039"
          },
          {
            value: "2040",
            label: "2040"
          }
        ]
      }
    ],
    select: {
      one: "",
      two: "",
      three: "",
      four: ""
    },
    checkboxes: {
      emailNews: true,
      emailOffers: true,
      privacy: false
    }
  },

  computed: {
    descriptionFieldValue(){
      if (this.steps[0].fieldValue || this.steps[1].fieldValue || this.steps[2].fieldValue || this.steps[3].fieldValue) {
        let strValue = '';
        if (this.steps[0].fieldValue) {
          strValue += 'Tipo Progetto:'+this.steps[0].fieldValue+'\r\n';
        }
        if (this.steps[1].fieldValue) {
          strValue += 'Tipo Progetto:'+this.steps[1].fieldValue+'\r\n';
        }
        if (this.steps[2].fieldValue) {
          strValue += 'Descrizione Progetto:'+ this.steps[2].fieldValue.replace(/\n/g, " ") +'\r\n';
        }
        // if (this.steps[3].fieldValue) {
        //   strValue += 'Campo Colorato:'+this.steps[3].fieldValue+'\r\n';
        // }
        return strValue;
      }
      return "Nessun informazione";
    }
  },

  mounted() {
    setTimeout(this.setup, 10);
    this.$refs.submit.addEventListener("click", this.submit, false);
  },

  methods: {
    setup() {

      //////
      
      let s=document.createElement('script');
      s.src="https://www.google.com/recaptcha/api.js?hl="+(document.querySelector("html").getAttribute('lang')||'en');
      document.body.append(s);
      
      //////

      const tweenElements = Array.from(this.$el.querySelectorAll('.contact-form__tween'));

      // region hidden field value change
      if (this.$refs.fieldCountry && this.$refs.regionHiddenField) {
        this.$refs.fieldCountry.addEventListener("change", () => {
          this.$refs.regionHiddenField.value = this.$refs.fieldCountry.value;
        });
      }

      this.$options.intListener = new IntersectionObserver(entries => {
        entries.filter(entry => entry.isIntersecting).forEach(entry => {
          if (entry.target.classList.contains('contact-form__bkg-text')) {
            this.tweenLettersIn(this.$refs.letters);
          } else {
            entry.target.classList.add('contact-form__tween--in');
          }
        });
      }, { threshold: 0.1 });
      
      this.$options.intListenerComponent = new IntersectionObserver(entries => {
        if (entries && entries[0]) {
          if (!entries[0].isIntersecting) {
            tweenElements.forEach(el => el.classList.remove('contact-form__tween--in'));
          }
        }
      }, { threshold: 0.1 });

      tweenElements.forEach(el => this.$options.intListener.observe(el));
      this.$options.intListener.observe(this.$refs.bkgText);
      
      this.$options.intListenerComponent.observe(this.$el);
    },

    submit(e) {
      let errors = this.validate();

      e.preventDefault();

      if (errors.length) {
        const top = this.$refs.steps
          ? window.pageYOffset + this.$refs.steps.getBoundingClientRect().y
          : window.pageYOffset + this.$refs.form.getBoundingClientRect().y
        
        window.scrollTo({
          top,
          behavior: "smooth"
        });
      } else {
        console.log(e);
        return;

        Promise.resolve()
          .then(() => new Promise(resolve => {

            if (window.onContactFormSubmit && typeof window.onContactFormSubmit === 'function') {
              window.onContactFormSubmit(e, errors)
                .then(errors => resolve(errors))
                .catch(errors => resolve(errors))
            } else {
              resolve();
            }
          }))
          .then(errors => {
            if (errors && errors.length) {
              this.errors = errors;
            } else {
              this.$el.querySelector('form').submit();
            }
          })
      }
      return true
    },

    selectStepValue(i, value) {
      this.steps[i].value = value.label;
      this.steps[i].fieldValue = value.value;
    },

    toggleValues(i) {
      if (this.steps[i].values && this.steps[i].values.length) {
        this.indexValues = (this.indexValues === i) ? null : i;
      }
    },

    validate() {
      this.errors = [];
/*       const recaptchaResponse = grecaptcha && !!grecaptcha.getResponse();

      if (!recaptchaResponse) {
        this.errors.push('recaptcha');
      } */

      if (this.$refs.fieldProfession && !this.$refs.fieldProfession.value) {
        this.errors.push('profession');
      }

      if (!this.$refs.fieldFirstName.value) {
        this.errors.push('first_name');
      }

      if (!this.$refs.fieldLastName.value) {
        this.errors.push('last_name');
      }

      if (this.$refs.fieldState && !this.$refs.fieldState.value) {
        this.errors.push('state');
      }

      if (!this.$refs.fieldCountry.value) {
        this.errors.push('country');
      }

      if (this.$refs.fieldCompany && !this.$refs.fieldCompany.value) {
        this.errors.push('company');
      }

      if (!this.$refs.fieldLanguage.value) {
        this.errors.push('language');
      }

      if (!this.$refs.fieldEmail.value) {
        this.errors.push('email');
      }

      if (!this.$refs.fieldPhone.value) {
        this.errors.push('phone');
      }

      if (!this.$refs.fieldPrivacy.checked) {
        this.errors.push('privacy');
      }

      return this.errors;
    }
  }
}
