export default {
  name: "Footer",

  mounted() {
    this.setup();
  },

  methods: {
    setup() {
      this.$options.intListener = new IntersectionObserver(entries => {
        if (entries && entries[0]) {
          if (entries[0].isIntersecting) {
            this.$store.commit('navIndexes', { display: false });
          } else {
            this.$store.commit('navIndexes', { display: true });
          }
        }
      });

      this.$options.intListener.observe(this.$el, {
        thresold: 0.6
      });
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
};