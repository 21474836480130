import gsap from "gsap";

export default {
  name: "CookieBar",

  data() {
    return {
      display: true,
      isToggling: false,
      profiling: false,
      stats: true
    };
  },

  computed: {
    cookieValueArray() {
      const value = [];
      if (this.profiling) {
        value.push("profilazione");
      }
      if (this.stats) {
        value.push("statistico");
      }
      return value;
    },

    cookieValueString() {
      return `["${this.cookieValueArray.join('","')}"]`;
    }
  },

  mounted() {
    if (localStorage.getItem("CookieBar")) {
      const timestamp = parseInt(localStorage.getItem("CookieBar"));
      const month = 1000 * 60 * 60 * 24 * 31;
      const now = Date.now();
      if (timestamp < (now - month)) {
        this.display = true;
        localStorage.removeItem("CookieBar");
      } else {
        this.display = false;
      }
    }
  },

  methods: {
    togglePreferences() {
      if (this.isToggling) {
        return;
      }
      this.isToggling = true;
      const isActive = this.$refs.preferences.classList.contains("cookiebar__preferences--active");
      let height 
      if (isActive) {
        height = 0;
      } else {
        this.$refs.preferences.style.height = "auto";
        height = this.$refs.preferences.offsetHeight;
        this.$refs.preferences.style.height = 0;
      }

      this.$refs.preferences.classList.toggle("cookiebar__preferences--active");

      gsap.to(
        this.$refs.preferences,
        {
          height,
          duration: 0.5,
          ease: "power2.inOut",
          onComplete: () => {
            this.isToggling = false;
          }
        }
      );
    },

    submit() {
      localStorage.setItem("CookieBar", Date.now());

      document.cookie = "cookiesDirective=1";
      document.cookie = "CookieShow=true";
      document.cookie = `CookiePreferences=${this.cookieValueString}`;

      gsap.to(
        this.$el,
        {
          opacity: 0,
          y: "-4rem",
          duration: 0.5,
          ease: "power2.inOut",
          onComplete: () => {
            this.$el.parentNode.removeChild(this.$el);
            this.$destroy();
          }
        }
      );
    }
  }
};
