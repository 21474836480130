import gsap from 'gsap';

export default {
  data() {
    return {
      floatingButton: {
        intersecting: false,
        label: null,
        setup: false,
        url: null,
        sticky: false,
        stickySupport: false
      }
    }
  },

  computed: {
    isMobile() {
      return this.$store.state.mediaQueries.includes('mobile');
    }
  },

  watch: {
    'floatingButton.url'(value) {
      if (value && !this.floatingButton.setup) {
        setTimeout(() => this.floatingButtonSetup(), 10)
      }
    },
    'floatingButton.intersecting'(value) {
      if (value) {
        this.floatingButtonTweenIn();
        if (this.isMobile) {
          this.floatingButton.sticky = true
        }
      } else {
        this.$refs.floatingButton.style.opacity = 0;
        if (this.isMobile) {
          this.floatingButton.sticky = false
        }
      }
    }
  },

  methods: {
    floatingButtonSet(data) {
      // test sticky property
      if (CSS && CSS.supports && CSS.supports("position", "sticky")) {
        this.floatingButton.stickySupport = true;
      }

      if (data && data.floatingButton) {
        if (data.floatingButton.url) {
          this.floatingButton.url = data.floatingButton.url;
        }
        if (data.floatingButton.label) {
          this.floatingButton.label = data.floatingButton.label;
        }
      }
    },

    floatingButtonSetup() {
      if (!this.$refs.floatingButton) {
        return
      }
      
      this.$refs.floatingButton.style.opacity = 0;
      this.$options.floatingButtonIntListener = new IntersectionObserver(entries => {
        this.floatingButton.intersecting = entries[0].isIntersecting;
      }, {
        threshold: 1
      });

      this.$options.floatingButtonIntListener.observe(this.$refs.floatingButton);

      this.floatingButton.setup = true;
    },

    floatingButtonTweenIn() {
      const tween = gsap.fromTo(
        this.$refs.floatingButton,
        {
          opacity: 0,
          scale: 0.9
        },
        {
          opacity: 1,
          scale: 1,
          duration: 0.4,
          ease: 'power2.inOut',
          clearProps: 'all'
        }
      );

      return tween.then()
    }
  }
}