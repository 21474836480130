import Vue from "vue";
import gsap from "gsap";
import ApiLoadMixin from "../mixins/api.js";
import TweenMixin from "../mixins/tween.js";

import _ from "lodash";

export default {
  name: "ProductsList",

  mixins: [ApiLoadMixin,TweenMixin],

  data: {
    isTweenIn: false,
    deleteme:null,
    title:null,
    description:null,
    backgroundText: [],
    itemWidth:null,
    carouselRatio:null,
    leaveTimeout:null,
    isDragging:false,
    startX:null,
    prevX:null,
    velX:null,
    startPos:null,
    catPos:null,
    carouselOffset:null,
    nextObj:null,
    skipBg:false,
    isVisible:false,
    clicked:false,
    FAKEPRODS:["Valla De Alta Competición Automática","jg. Obstáculos Regulables","pódium Juez Salidas","indicadores Número De Calle","carro Portavallas 20 Uds.","pantalla Led Salto Longitud P6 Doble Cara","saltómetro Altura Aluminio","zona De Caída Salto De Altura (8x4x0’8m)","saltómetro Pértiga Motorizado","zona De Caída Salto Con Pértiga (8’8x6’9x0’95m)","soporte Pértigas Individual","indicadores Distancia Lanzada","enrollador De Cinta","carro Soporte Para Discos","carro Soporte Para Jabalinas","carro Soporte Para Martillos","carro Soporte Para Pesos","indicador Dirección Viento","carro Transporte General","mesa Jueces","papelera Con Soporte","banco Para Atletas","banco Para Jueces"],
    FAKEPRODS_:[]
  },

  computed: {
    isMobile() {
      return this.$store.state.mediaQueries.includes("mobile");
    },
    products(){
      return this.$store.state.productsList.products;
    },
    categories(){
      return this.$store.state.productsList.categories;
    },
    productsIcons() {
      return this.$store.state.productsIcons;
    },
    category(){
      return this.$store.state.productsList.category;
    },
    productIndex(){
      return this.$store.state.productsList.productIndex;
    }
  },

  watch: {
    products(){
      this.loadIcons();
    },
    nextObj(obj){

      this.skipBg=true;
      this.tweenCarousel(obj.category,(this.isVisible==false || this.productIndex!=null));
      this.$store.commit("productsList", obj);
      
    },
    category(value){

      if(!this.skipBg) this.updateBkgLetters();

      //from header
      if(this.productIndex!=null)
      {
        this.nextObj={category:value};
      }

    },
    productIndex(value,oldValue){
      if(value==null && oldValue!=null){
        this.tweenIn();
      }
      else if(value!=null && oldValue==null)
      {
        this.tweenOut();
      }
    }
  },

  mounted() {


    window.addEventListener('resize', _.debounce(this.onRes, 5));

    window.mondo.event.$on("HeroSlider.linkTo",(data)=>{

      const _obj={...data,productIndex:data.productIndex!=null?this.$store.getters.getCategoryObj(data.category).index+data.productIndex:null};
      this.nextObj=_obj;

    });

    window.mondo.event.$on("TopHeader.setCategory",this.setCategory);

    Promise.resolve()
      .then(this.loadData)
      .then(() => Vue.nextTick())
      .then(this.setup);

  },
  methods: {
    updateBkgLetters(){
      this.backgroundText=this.$store.getters.getCategoryObj().backgroundText

      Vue.nextTick()
      .then(()=>{
        this.tweenLettersIn(this.$refs.letters);
      })


    },
    getTransX(){
      let transX=this.$refs.carousel.style.transform.match(/translate(?:3d)?\(([.\-0-9]+)px/);
      return transX?parseFloat(transX[1]):0;
    },
    onRes(){

      this.carouselOffset=this.$refs.carousel.parentElement.clientWidth-this.$refs.carousel.clientWidth;

      this.catPos=[...this.$refs.carousel.querySelectorAll('.category-icon')].map((el,i)=>({id:el.getAttribute('data-catid'),left:el.parentElement.offsetLeft+(i>0?el.clientWidth/2:0)}));

      gsap.set(this.$refs.carousel,{x:`${this.carouselRatio*this.$refs.carousel.offsetWidth}px`})
    },
    updateCarouselRatio(){
      this.carouselRatio=this.getTransX()/this.$refs.carousel.offsetWidth;
    },
    fillFakeData(data){
      return;
      let fake=[];
      let ROOT='/assets/img-athletics/products/';

      let fakecats=[
        {id:'running',l:6},
        {id:'long jump',l:2},
        {id:'high jump',l:2},
        {id:'pole vault',l:3},
        {id:'throws',l:8},
        {id:'accessories',l:4}
      ];

      const getFakeproductName=()=>{
        if(this.FAKEPRODS_.length==0)  this.FAKEPRODS_=[...this.FAKEPRODS.sort(()=>Math.random()>.5)];
        return this.FAKEPRODS_.pop()
      }

      fakecats.forEach(c=>{
        let catId=c.id;
        let cat=_.cloneDeep(data[0]);
        cat.id=catId.split(' ').join('');
        cat.icon=catId.split(' ').join('');
        cat.name=catId;

        if(catId.indexOf(' ')>-1)
        {
          cat.backgroundText=catId.split(' ');
        }
        else
        {
          let l=Math.ceil(catId.length/2);
          cat.backgroundText=[catId.substr(0,l),catId.substr(l,catId.length-l)]
        } 

        let prods=c.l;
        let catproducts=[];

        for(let n=0;n<prods;n++){
          let prod=_.cloneDeep(cat.products[0]);
          prod.name=getFakeproductName();

          prod.thumbnail.imageUrl=`${ROOT}carousel/${cat.id}_${n}.png`;
          
          let prodImg_=_.cloneDeep(prod.images[0]);
          prod.images=[];

          for(let m=0;m<2;m++){

            let prodImg=_.cloneDeep(prodImg_);

            prodImg.imageUrl=`${ROOT}details/${cat.id}_${n}/${cat.id}_${n}_${m}.png`;
            prodImg.thumbnailUrl=`${ROOT}details/${cat.id}_${n}/thumbnail_${cat.id}_${n}_${m}.png`;
            prodImg.pins[1].imageAltUrl=`${ROOT}details/${cat.id}_${n}/alt_${cat.id}_${n}_${m}.png`;

            prod.images.push(prodImg);
          }
          
          prod.code="AC "+(Math.floor(Math.random()*500));
          catproducts.push(prod);
        }
        cat.products=catproducts;
        fake.push(cat);
      });

      return fake;
    },
    loadData() {
      return this.apiLoad(this.$el.getAttribute("data-endpoint")).then(data => {
        
/*         let DELETEMEcategories=this.fillFakeData(data.categories);

        let newData=_.cloneDeep(data);
        newData.categories=DELETEMEcategories;

        let uhm=window.open('','uhm');
        console.log('------__>',uhm)
        uhm.document.body.innerText=JSON.stringify(newData); */


        let i=0,_categories=[];
        const _products = data.categories.reduce(
          (flatProducts,{id,products,name,icon,backgroundText,defaultPins})=>{
            _categories.push({id:id,name:name,count:products.length,index:i,backgroundText:backgroundText,icon:icon,defaultPins:defaultPins});
            products=products.map((el,i)=>({...el,...{icon:i==0?icon:null,catId:id}}));
            i+=products.length;
            return flatProducts.concat(products);
          },[]
        );
          
        this.$store.commit("productsList", {
          products: _products,categories:_categories,category:_categories[0].id
        });

        this.backgroundText = this.categories[0].backgroundText;

        if (data && data.title) {
          this.title = data.title;
        }

        if (data && data.description) {
          this.description = data.description;
        }
        
      });
    },
    loadIcons() {

      let icons=this.categories.map(el=>el.id);
      let urls=icons.reduce((res,icon)=>res.concat([`/assets/svg/athletics/rhombus/${icon}.svg`,`/assets/svg/athletics/${icon}.svg`]),[]);

      Promise.all(urls.map(u=>fetch(u))).then(responses =>{
        return Promise.all(responses.map(res => res.text()))}
      ).then(svg => {
        this.$store.commit("productIcons",svg.reduce((obj,tag,i)=>{
          let id=icons[Math.floor(i/2)]+(i%2==0?'_rhombus':'');
          obj[id]=tag;
          return obj;
        },{}));
      })

    },
    step(){
      if(!this.isDragging)
      {
        this.velX*=.85;
        let targetX=this.getTransX()+this.velX;
        targetX=Math.max(Math.min(0,targetX),this.carouselOffset);
        
        gsap.set(this.$refs.carousel,{x:`${targetX}px`});

        if(Math.abs(this.velX)>1) 
        {
          requestAnimationFrame(this.step);
        }
        else
        {
          if(this.isMobile) this.getNearestCat(); 
        }
      }
    },
    dragMove(e){
      if(e.cancelable) e.preventDefault();

      if(e.target.classList.contains('area') || this.isMobile)
      {
        
        let x=this.isMobile?e.touches[0].clientX:e.x;
        let targetX=this.startPos+(x-this.startX);
        targetX=Math.max(Math.min(0,targetX),this.carouselOffset);
  
        this.velX=this.prevX?targetX-this.prevX:0;

        this.prevX=targetX;
  
        if(this.isMobile){
          gsap.set(this.$refs.carousel,{x:targetX+'px'});
        }
        else
        {
          gsap.set(this.$refs.carousel,{duration:.5,x:targetX+'px'})
  
          this.getNearestCat();
          this.updateCarouselRatio();
        }
      }
     
    },
    startDrag(e){

      if(this.isMobile && e.type=='mousedown') return;
      
      this.$store.commit('mouseCursor', {
        isDragging: true
      });
      
      this.clicked=false;

      this.skipBg=false;
      this.startX=this.isMobile?e.touches[0].clientX:e.x;
      this.startPos=this.getTransX();
      this.isDragging=true;


      document.addEventListener('mousemove',this.dragMove);
      document.addEventListener('mouseup',this.stopDrag);

      document.addEventListener('touchmove',this.dragMove, { passive: false });
      document.addEventListener('touchend',this.stopDrag);
    },
    stopDrag(){

      this.$store.commit('mouseCursor', {
        isDragging: false
      });

      this.isDragging=false;
      this.prevX=null;

      document.removeEventListener('mousemove',this.dragMove);
      document.removeEventListener('mouseup',this.stopDrag);

      document.removeEventListener('touchmove',this.dragMove);
      document.removeEventListener('touchend',this.stopDrag);

      this.step();

    },
    setup() {

      if(!this.isMobile)
      {
        const cW=(8.33333333333333*this.categories.length*2)-8.33333333333333;
        const pW=(23.9583333333333*(this.products.length));
        const xW=(100-19.5833333333333)-23.9583333333333;

        this.$refs.carousel.style.width=(pW+cW+xW)+'rem';
      }
      else
      {
        this.$refs.carousel.style.width=(this.products.length*74)+'%';
        [...this.$refs.carousel.querySelectorAll('li')].forEach(li=>li.style.width=`${100/this.products.length}%`);
        this.onRes();
      }
      
      //drag
      this.$refs.carousel.addEventListener('mousedown',this.startDrag);
      this.$refs.carousel.addEventListener('touchstart',this.startDrag, { passive: false });


      this.$options.intListener = new IntersectionObserver(entries => {
        const entry = entries[0];
        if (entry) {

          if(entry.isIntersecting)
          {
            this.isVisible=true;
            this.tweenIn();
            this.onRes();

/*             window.mondo.event.$emit("LightboxGallery.toggle", {index:1,items:[{"description":"lorem ipsum dolor this.$refs.carousel.addEventListener","thumbnail": {"alt": "X3 AER", "imageUrl": "/assets/img-athletics/products/gallery.png"}, "image": {"alt": "X3 AER", "imageUrl": "/assets/img-athletics/products/gallery.png"} }, {"thumbnail": {"alt": "X3 AER", "imageUrl": "/assets/img-athletics/products/gallery2.png"}, "image": {"alt": "X3 AER", "imageUrl": "/assets/img-athletics/products/gallery2.png"} } ]}); */
          }
          else
          {
            this.isVisible=false;
            this.setCategory(this.categories[0]);
          }

        }
      }, { threshold: 0.1 });

      this.$options.intListener.observe(this.$el);


    },

    tweenIn() {
        const tl = gsap.timeline({
          onComplete: () => {
            this.isTweenIn = false;
          }
        });
        if (this.isTweenIn) {
          return;
        }
        this.isTweenIn = true;

        tl.fromTo(this.$el, {
          opacity: 0
        }, {
          opacity: 1,
          duration: 0.8,
          ease: "power2.inOut",
          clearProps: "all"
        }, 0)
        .add(this.tweenLettersIn(this.$refs.letters),0)

        return tl.then();
    },
    tweenOut(){
      const tl = gsap.timeline({});

      tl.fromTo(this.$el, {
        opacity: 1
      }, {
        opacity: 0,
        duration: 0.8,
        ease: "power2.inOut",
        clearProps: "all"
      }, 0)
      .add(this.tweenLettersOut(this.$refs.letters),0)

      return tl.then();
    },
    displayCursor() {

      if(this.leaveTimeout) clearTimeout(this.leaveTimeout);

      this.$store.commit('mouseCursor', {
        display: true,
        displayLabel: true,
        label:window.mondoConfig.strings.dragLabel||''
      });
    },
    thumbMouseLeave(){
      this.leaveTimeout=setTimeout(this.hideCursor,100);
    },
    hideCursor() {
      this.$store.commit('mouseCursor', {
        display: false
      });
    },
    setCategory(cat){
      this.nextObj={category:cat.id,productIndex:null};
    },
    getNearestCat(){
      
      let nObj = this.catPos.reduce((nearest,catObj)=>{
        let dist=Math.abs(catObj.left+this.getTransX());
        if(nearest.min==null || nearest.min>dist){
          return {min:dist,id:catObj.id};
        }
        else
        {
          return nearest;
        }
      },{min:null});
      
      this.$store.commit("productsList", {category:nObj.id}); 

    },
    tweenCarousel(catId,immediately){
      if(this.catPos)
      {

        const targetX=Math.max(Math.min(0,-this.catPos.filter(el=>el.id==catId)[0].left),this.carouselOffset);
        gsap.to(this.$refs.carousel,{duration:immediately?0:1.5,ease:'expo.inOut',x:`${targetX}px`,onComplete:()=>{

          this.getNearestCat();
          this.updateBkgLetters();
          this.updateCarouselRatio();

        }})
      }
    },
    setProduct(prod,i){
      this.velX=0;
      this.clicked=true;
      this.$store.commit("productsList", {productIndex:i});
    }
  }
};
