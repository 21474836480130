import gsap from "gsap";

export default {
  name: "MouseCursor",

  data: {
    isTouch: false,
    position: {
      left: 0,
      top: 0
    }
  },

  computed: {
    display() {
      return this.$store.state.mouseCursor.display;
    },

    displayLabel() {
      return this.$store.state.mouseCursor.displayLabel;
    },

    isDragging() {
      return this.$store.state.mouseCursor.isDragging;
    },

    isLinkOver() {
      return this.$store.state.mouseCursor.isLinkOver;
    },

    isMobile() {
      return this.$store.state.mediaQueries.includes("mobile");
    },

    label() {
      return this.$store.state.mouseCursor.label;
    },

    styleClass() {
      return this.$store.state.mouseCursor.style;
    },

    style() {
      return {
        transform: `translate(${this.position.left}px, ${this.position.top}px)`
      }
    }
  },

  watch: {
    display(value) {
      if (value) {
        document.body.classList.add("custom-cursor");
        this.tweenIn();
      } else {
        setTimeout(() => {
          document.body.classList.remove("custom-cursor");
        }, 1000);
        this.tweenOut().then(() => {
          this.$el.style.opacity = null;
        })
      }
    },

    isDragging(value) {
      if (value) {
        this.tweenDragIn();
      } else {
        this.tweenDragOut();
      }
    },

    isLinkOver(value) {
      if (value) {
        this.tweenLinkOverIn();
      } else {
        this.tweenLinkOverOut();
      }
    }
  },

  mounted() {
    this.setup();
  },

  methods: {
    setup() {
      this.$options.circleLength = 326.5;
      window.addEventListener('mousemove', this.onMouseMove, false);
      window.addEventListener('touchstart', this.onFirstTouch, false);
    },

    onFirstTouch() {
      this.isTouch = true;
      window.removeEventListener('touchstart', this.onFirstTouch, false);
    },

    onMouseMove(e) {
      if (this.isMobile) {
        return;
      }
      this.position.top = e.clientY;
      this.position.left = e.clientX;
    },

    tweenIn() {
      const tl = gsap.timeline();
      const objStroke = { value: 0 }
 
      tl.fromTo(objStroke, {
        value: 0
      }, {
        value: this.$options.circleLength,
        duration: 1,
        ease: "circ.inOut",
        onUpdate: () => {
          this.$refs.outerCircle.style.strokeDasharray = `${objStroke.value}, ${this.$options.circleLength}`;
        }
      }, 0);

      tl.fromTo([this.$refs.arrowLeft, this.$refs.arrowRight], {
        x: 10,
        opacity: 0
      }, {
        x: 5,
        opacity: 0.5,
        duration: 1,
        ease: "circ.inOut"
      }, 0);

      tl.fromTo(this.$refs.innerCircle, {
        scale: 0.8,
        transformOrigin: '50% 50%',
        opacity: 0
      }, {
        opacity: 1,
        scale: 1,
        duration: 1,
        ease: "circ.inOut",
        clearProps: "scale,opacity"
      }, 0);

      tl.fromTo(this.$refs.label, {
        opacity: 0
      }, {
        opacity: 1,
        duration: 1,
        ease: "circ.inOut",
        clearProps: "opacity"
      }, 0);

      return tl.then();
    },

    tweenOut() {
      const tl = gsap.timeline();
      const objStroke = { value: 0 }

      tl.set(this.$el, {
        opacity: 1
      }, 0);
 
      tl.fromTo(objStroke, {
        value: this.$options.circleLength
      }, {
        value: 0,
        duration: 0.5,
        ease: "circ.inOut",
        onUpdate: () => {
          this.$refs.outerCircle.style.strokeDasharray = `${objStroke.value}, ${this.$options.circleLength}`;
        }
      }, 0);

      tl.to(this.$refs.arrowLeft, {
        x: 10,
        opacity: 0,
        duration: 0.5,
        ease: "circ.inOut",
        clearProps: "x,opacity"
      }, 0);

      tl.to(this.$refs.arrowRight, {
        x: 10,
        opacity: 0,
        duration: 0.5,
        ease: "circ.inOut",
        clearProps: "x,opacity"
      }, 0);

      tl.to(this.$refs.innerCircle, {
        transformOrigin: '50% 50%',
        opacity: 0,
        scale: 0.8,
        duration: 0.5,
        ease: "circ.inOut",
        clearProps: "scale,opacity"
      }, 0);

      tl.to(this.$refs.label, {
        opacity: 0,
        duration: 0.5,
        ease: "circ.inOut",
        clearProps: "opacity"
      }, 0);

      return tl.then();
    },

    tweenDragIn() {
      const tl = gsap.timeline();

      tl.set(this.$refs.outerCircle, {
        transformOrigin: "50% 50%"
      });

      tl.to(this.$refs.outerCircle, {
        scale: 0.85,
        duration: 0.3,
        ease: "circ.out"
      }, 0);

      tl.to([this.$refs.arrowLeft, this.$refs.arrowRight], {
        x: 0,
        opacity: 1,
        duration: 0.3,
        ease: "circ.out"
      }, 0);

      return tl.then();
    },

    tweenDragOut() {
      const tl = gsap.timeline();

      tl.to(this.$refs.outerCircle, {
        scale: 1,
        duration: 0.5,
        ease: "circ.inOut",
        clearProps: "scale"
      }, 0);

      tl.to([this.$refs.arrowLeft, this.$refs.arrowRight], {
        x: 5,
        opacity: 0.5,
        duration: 0.3,
        ease: "circ.inOut"
      }, 0);

      return tl.then();
    },

    tweenLinkOverIn() {
      const tl = gsap.timeline();

      tl.set(this.$refs.outerCircle, {
        transformOrigin: "50% 50%"
      });

      tl.to(this.$refs.outerCircle, {
        scale: 0.85,
        duration: 0.3,
        ease: "circ.out"
      }, 0);

      tl.to([this.$refs.innerCircle, this.$refs.label], {
        opacity: 0,
        duration: 0.3,
        ease: "circ.out"
      }, 0);

      tl.to([this.$refs.arrowLeft, this.$refs.arrowRight], {
        opacity: 0,
        duration: 0.3,
        ease: "circ.out"
      }, 0);

      return tl.then();
    },

    tweenLinkOverOut() {
      const tl = gsap.timeline();

      tl.to(this.$refs.outerCircle, {
        scale: 1,
        duration: 0.5,
        ease: "circ.out",
        clearProps: "scale"
      }, 0);

      tl.to([this.$refs.innerCircle, this.$refs.label], {
        opacity: 1,
        duration: 0.5,
        ease: "circ.inOut",
        clearProps: 'opacity'
      }, 0);

      tl.to([this.$refs.arrowLeft, this.$refs.arrowRight], {
        opacity: 1,
        duration: 0.5,
        ease: "circ.out",
        clearProps: "opacity"
      }, 0);

      return tl.then();
    }
  }
};
