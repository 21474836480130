import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    lightbox: {
      active: false
    },
    lightboxGallery: {
      active: false
    },
    mediaQueries: [],
    mouseCursor: {
      display: false,
      displayLabel: false,
      isDragging: false,
      isLinkOver: false,
      label: '',
      style: null
    },
    navIndexes: {
      display: true,
      index: -1
    },
    navOverlay: {
      display: false
    },
    products: [],
    productsList:{
      category:null,
      productIndex:null,
      products:[],
      categories:[]
    },
    productsSlider: {
      index: -1,
      intersected: [],
      visible: false
    },
    productsIcons: {},
    scrollComponentIndex: 0,
    scrollComponentName: null,
    topHeader: {
      isHidden: false
    },
    vh: null,
    heroSlider:{
      indexNext:-1
    }
  },
  getters:{
    getCategoryObj: (state) => (id) => {
      return state.productsList.categories.find(cat => cat.id === (id||state.productsList.category));
    }
  },
  mutations: {
    lightbox(state, params) {
      if (params && _.isBoolean(params.active)) {
        state.lightbox.active = params.active;
      }
    },
    lightboxGallery(state, params) {
      if (params && _.isBoolean(params.active)) {
        state.lightboxGallery.active = params.active;
      }
    },
    mediaQueries(state, params) {
      if (params.add && typeof params.add === "string" && !state.mediaQueries.includes(params.remove)) {
        state.mediaQueries.push(params.add);
      }
      if (params.remove && typeof params.remove === "string" && state.mediaQueries.includes(params.remove)) {
        state.mediaQueries.splice(state.mediaQueries.indexOf(params.remove), 1);
      }
    },

    mouseCursor(state, params) {
      if (params && _.isBoolean(params.display)) {
        state.mouseCursor.display = params.display;
      }
      if (params && _.isBoolean(params.displayLabel)) {
        state.mouseCursor.displayLabel = params.displayLabel;
      }
      if (params && _.isBoolean(params.isDragging)) {
        state.mouseCursor.isDragging = params.isDragging;
      }
      if (params && _.isBoolean(params.isLinkOver)) {
        state.mouseCursor.isLinkOver = params.isLinkOver;
      }
      if (params && _.isString(params.label)) {
        state.mouseCursor.label = params.label;
      }
      if (params && _.isString(params.style) || _.isNull(params.style)) {
        state.mouseCursor.style = params.style;
      }
    },

    navIndexes(state, params) {
      if (_.isPlainObject(params)) {
        if (_.isBoolean(params.display)) {
          state.navIndexes.display = params.display;
        }
        if(_.isNumber(params.index)) {
          state.navIndexes.index = params.index;
        }
      }
    },

    navOverlay(state, params) {
      if (_.isPlainObject(params)) {
        if (_.isBoolean(params.display)) {
          state.navOverlay.display = params.display;
        }
      }
    },

    products(state, params) {
      if (params && params.products && typeof(params.index) === "number") {
        Vue.set(state.products, params.index, params.products);
      }
    },
    productIcons(state, param) {
      state.productsIcons=param;
    },
    productIcon(state, param) {
      if (param && param.key && param.value) {
        state.productsIcons[param.key] = param.value;
      }
    },
    productsList(state,params){
      if (_.isPlainObject(params)) {

        if (_.isString(params.category)) {
          state.productsList.category = params.category;
        }
        if (_.isNumber(params.productIndex) || _.isNull(params.productIndex)) {
          state.productsList.productIndex = params.productIndex;
        }
        if (_.isArray(params.products)) {
          state.productsList.products = params.products;
        }
        if (_.isArray(params.categories)) {
          state.productsList.categories = params.categories;
        }
      }
    },
    productsSlider(state, params) {
      if (_.isPlainObject(params)) {
        if (_.isBoolean(params.visible)) {
          state.productsSlider.visible = params.visible;
        }
        if (_.isArray(params.intersected)) {
          state.productsSlider.intersected = params.intersected;
        }
        if (_.isNumber(params.index)) {
          state.productsSlider.index = params.index;
        }
      }
    },

    scrollComponentIndex(state, param) {
      if (typeof param === "number") {
        state.scrollComponentIndex = param;
      }
    },

    scrollComponentName(state, param) {
      if (typeof param === "string") {
        state.scrollComponentName = param;
      }
    },
    
    topHeader(state, params) {
      if (_.isPlainObject(params)) {
        if (_.isBoolean(params.isHidden)) {
          state.topHeader.isHidden = params.isHidden;
        }
      }
    },

    vh(state, param) {
      if (_.isNumber(param)) {
        state.vh = param;
      }
    },
    heroSlider(state, params) {
      if (_.isPlainObject(params)) {
        if(_.isNumber(params.indexNext)) {
          state.heroSlider.indexNext = params.indexNext;
        }
      }
    },
  }
});

export default store;
