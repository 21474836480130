import Vue from "vue";
import gsap from "gsap";
import ApiLoadMixin from "../mixins/api.js";
import SliderMixin from "../mixins/slider.js";
import TweenMixin from "../mixins/tween.js";
import FloatingButtonMixin from "../mixins/floatingbutton.js";

export default {
  name: "AdvantagesSlider",

  mixins: [ApiLoadMixin, SliderMixin, TweenMixin, FloatingButtonMixin],

  data: {
    backgroundText: [],
    index: -1,
    indexVideo: 0,
    indexVideoBar: 0,
    indexesFixed: false,
    slides: [],
    timeout: 18000,
    toggleVideoStop: false
  },

  computed: {
    barStyle() {
      return {
        width: `${this.slides.length * 100}%`,
        transform: (this.slider.x !== null) ? `translateX(${this.slider.x}px)` : `translateX(${this.$el.offsetWidth * -this.index}px)`
      };
    },

    currentSlide() {
      return this.slides[this.index] ? this.slides[this.index] : null;
    },

    currentSlideType() {
      if (this.currentSlide && this.currentSlide.type) {
        return this.currentSlide.type;
      }
      return "default";
    },

    currentSlideVideo() {
      if (this.currentSlide && this.currentSlide.videos && this.currentSlide.videos[this.indexVideo]) {
        return this.currentSlide.videos[this.indexVideo];
      }
      return null;
    },

    indexVideoBarMax() {
      if (this.currentSlideType === 'video' && this.currentSlide.videos) {
        return this.currentSlide.videos.length - 1;
      }
      return 0;
    },

    isMobile() {
      return this.$store.state.mediaQueries.includes('mobile');
    },

    slideStyle() {
      return {
        width: `${100/this.slides.length}%`
      };
    },

    topHeaderHidden() {
      return this.$store.state.topHeader.isHidden;
    },

    videoSlideBarStyle() {
      if (
        this.currentSlideType === 'video' &&
        this.currentSlide.videos &&
        this.currentSlide.videos.length
      ) {
        const itemWidth = this.isMobile ? 25.333333333333332 : 9.270833333333334
        return {
          transform: `translateX(${this.indexVideoBar * itemWidth * -1}rem)`
        };
      }
      return {};      
    }
  },

  watch: {
    index() {
      // reset video slide
      this.indexVideo = 0;
      this.indexVideoBar = 0;
      
      Vue.nextTick().then(this.tweenSlideIn);
      if (this.slider.autoplay) {
        this.setTimeout();
      }
    },
  },

  mounted() {
    this.setup();
  },

  methods: {
    setup() {
      return Promise.resolve()
      .then(this.loadData)
      .then(() => new Promise(resolve => {
        setTimeout(resolve, 100);
      }))
      .then(() => {
        this.$options.intListener = new IntersectionObserver(entries => {
          const entry = entries[0];
          if (entry && entry.isIntersecting) {
            this.setupMobileScrollListener();
            this.tweenLetters();
            this.index = 0;
            if (this.slider.autoplay) {
              this.setTimeout(); // restart
            }
          }
          if (entry && !entry.isIntersecting) {
            if (this.slides.length) {
              // set slider to 1 to reset to 0 when the slider returns in viewport
              if (this.slides[1]) {
                this.$refs.indexes.scrollTo({ left: 0, behavior: "smooth" });
                this.index = 1;
                this.sliderTweenTo(1);
              }
            }
            clearTimeout(this.$options.timeout); // stop autoplay
          }
        }, { threshold: 0.05 });

        this.$options.intListener.observe(this.$el);
      });
    },

    setupMobileScrollListener() {
      if (!this.isMobile) {
        return;
      }

      this.$options.mobileScrollBounds = {
        min: this.$el.offsetTop,
        max: this.$el.offsetTop + (this.$el.offsetHeight / 2) - this.$refs.indexes.offsetHeight
      };

      window.removeEventListener('scroll', this.onScrollMobile, false);
      window.addEventListener('scroll', this.onScrollMobile, false);
    },

    loadData() {
      return this.apiLoad(this.$el.getAttribute("data-endpoint")).then(data => {
        if (data && data.slides && data.slides.length) {
          this.backgroundText = data.backgroundText;
          this.slides = data.slides;
        }
        this.floatingButtonSet(data);
      });
    },

    onScrollMobile() {
      if (!this.isMobile) {
        this.indexesFixed = false;
        return;
      }
      
      if (window.pageYOffset >= this.$options.mobileScrollBounds.min && window.pageYOffset <= this.$options.mobileScrollBounds.max) {
        this.indexesFixed = true;
      } else {
        this.indexesFixed = false;
      }
    },

    setTimeout() {
      return; // disabled
      
      // clearTimeout(this.$options.timeout);
      // this.$options.timeout = setTimeout(() => {
      //   const nextIndex = (this.index + 1 === this.slides.length) ? 0 : this.index + 1;
      //   this.sliderTweenTo(nextIndex);
      // }, this.timeout);
    },

    toggleSlide(i) {
      if (i === this.index) {
        return;
      }
      window.scrollTo({
        top: this.$el.getBoundingClientRect().top + window.pageYOffset + 1,
        behavior: "smooth"
      });
      this.sliderTweenTo(i);
    },

    toggleVideo(i) {
      if (i === this.indexVideo) {
        return;
      }
      if (this.toggleVideoStop) {
        return;
      }

      this.toggleVideoStop = true;

      return Promise.resolve()
        .then(() => this.tweenVideoToggleOut())
        .then(() => {
          this.indexVideo = i;
        })
        .then(() => this.tweenVideoToggleIn())
        .then(() => {
          this.toggleVideoStop = false;
        });
    },

    videoBarNext() {
      if (this.currentSlide.videos.length <= 4) {
        return;
      }
      if (this.indexVideoBar + 4 >= this.indexVideoBarMax) {
        // double the size of the array, this is exponential
        this.currentSlide.videos = this.currentSlide.videos.concat(this.currentSlide.videos);
      }
      this.indexVideoBar += 1;
    },

    mouseCursorDisplay() {
      this.$store.commit("mouseCursor", { display: true });
    },

    mouseCursorHide() {
      this.$store.commit("mouseCursor", { display: false });
    },

    tweenLetters() {
      return this.tweenLettersIn(this.$refs.letters);
    },

    tweenSlideIn() {
      if (this.currentSlideType === 'video') {
        return this.tweenVideoSlideIn();
      }
      return this.tweenDefaultSlideIn();
    },

    tweenVideoSlideIn() {
      const tl = gsap.timeline();
      const slide = this.$refs.slides[this.index];
      const els = {
        videoThumbs: slide.querySelectorAll(".advantages-slider__videothumb"),
        videoNext: slide.querySelector(".advantages-slider__videosnext"),
        youtubeCta: slide.querySelectorAll('.advantages-slider__youtube > *')
      };

      if (els.videoThumbs && els.videoThumbs.length) {
        tl.fromTo(
          els.videoThumbs,
          {
            opacity: 0,
            xPercent: 10
          },
          {
            opacity: 1,
            xPercent: 0,
            duration: 0.5,
            stagger: 0.1,
            ease: "power2.inOut",
            clearProps: "all"
          },
          0.2
        );
      }
      
      if (els.videoNext) {
        tl.fromTo(
          els.videoNext,
          {
            opacity: 0,
            xPercent: 10
          },
          {
            opacity: 1,
            xPercent: 0,
            duration: 0.5,
            ease: "power2.inOut",
            clearProps: "all"
          },
          0.5
        );
      }
      
      if (!this.isMobile) {
        tl.fromTo(
          els.youtubeCta,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.5,
            stagger: 0.1,
            ease: "power2.inOut",
            clearProps: "all"
          },
          0.2
        );
      }

      // to complete
      return this.tweenVideoToggleIn();
    },

    tweenDefaultSlideIn() {
      const tl = gsap.timeline();
      const slideType = this.slides[this.index].type;
      const slide = this.$refs.slides[this.index];
      const els = {
        overlay: slide.querySelector(".advantages-slider__overlay"),
        overlayMask: slide.querySelector(".advantages-slider__overlay div"),
        image: slide.querySelector(".advantages-slider__image"),
        imageMask: slide.querySelector(".advantages-slider__image div"),
        contentEls: slide.querySelectorAll(".advantages-slider__content > *")
      };

      if (els.overlay && els.overlayMask) {
        tl.fromTo(els.overlay, {
          x: 20,
          opacity: 0
        }, {
          x: 0,
          opacity: 1,
          duration: 0.8,
          ease: "power2.inOut",
          clearProps: "all"
        }, 0);

        tl.fromTo(els.overlayMask, {
          skewX: 18,
          xPercent: 0,
          opacity: 1
        }, {
          skewX: 0,
          xPercent: -100,
          duration: 0.8,
          ease: "power2.inOut",
          clearProps: "all"
        }, 0);
      }

      if (els.image && els.imageMask) {
        tl.fromTo(els.image, {
          opacity: 0,
          x: 40,
        }, {
          opacity: 1,
          x: 0,
          duration: 0.8,
          ease: "circ.inOut",
          clearProps: "all"
        }, 0.2);

        tl.fromTo(els.imageMask, {
          skewX: 0,
          xPercent: -105
        }, {
          skewX: -18,
          xPercent: 0,
          duration: 0.8,
          ease: "circ.inOut",
          clearProps: "all"
        }, 0.2);
      }

      if (els.contentEls && els.contentEls.length) {
        tl.fromTo(els.contentEls, {
          opacity: 0,
          x: 40
        }, {
          opacity: 1,
          x: 0,
          ease: "circ.out",
          duration: 0.8,
          stagger: 0.1,
          clearProps: "all"
        }, 0.4);
      }

      return tl.then();
    },

    tweenVideoToggleIn() {
      const tl = gsap.timeline();
      const slide = this.$refs.slides[this.index];
      const els = {
        video: slide.querySelector(".advantages-slider__video"),
        contentEls: slide.querySelectorAll(".advantages-slider__content > div:first-child > *")
      };

      if (els.video) {
        tl.fromTo(els.video, {
          opacity: 0,
          scale: 0.95
        }, {
          opacity: 1,
          scale: 1,
          duration: 0.5,
          ease: "circ.out",
          clearProps: "all"
        }, 0);
      }

      if (els.contentEls && els.contentEls.length) {
        tl.fromTo(els.contentEls, {
          opacity: 0,
          x: 40
        }, {
          opacity: 1,
          x: 0,
          ease: "circ.out",
          duration: 0.35,
          stagger: 0.1,
          clearProps: "all"
        }, 0);
      }

      return tl.then();
    },

    tweenVideoToggleOut() {
      const tl = gsap.timeline();
      const slide = this.$refs.slides[this.index];
      const els = {
        video: slide.querySelector(".advantages-slider__video"),
        contentEls: slide.querySelectorAll(".advantages-slider__content > div:first-child > *")
      };

      if (els.video) {
        tl.to(els.video, {
          opacity: 0,
          scale: 1.05,
          duration: 0.35,
          ease: "circ.out"
        }, 0);
      }

      if (els.contentEls && els.contentEls.length) {
        tl.to(els.contentEls, {
          opacity: 0,
          x: 40,
          ease: "circ.out",
          duration: 0.35,
          stagger: 0.1
        }, 0);
      }

      return tl.then();
    }
  }
};
