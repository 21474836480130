import Vue from "vue";
import Swiper from 'swiper';
import gsap from 'gsap';
import ApiLoadMixin from "../mixins/api.js";
import TweenMixin from "../mixins/tween.js"

export default {
  name: "ReferencesSlider2",

  mixins: [ApiLoadMixin, TweenMixin],

  data: {
    title:null,
    description:null,
    backgroundText: null,
    cta: null,
    index: 0,
    indexContent: 0,
    interactions: true,
    intersecting: false,
    slides: []
  },

  computed: {
    currentSlide() {
      if (this.index >= 0 && this.slides[this.index]) {
        return this.slides[this.index];
      }
      return null;
    },

    currentSlideContent() {
      if (this.indexContent >= 0 && this.slides[this.indexContent]) {
        return this.slides[this.indexContent];
      }
      return null;
    },

    isMobile() {
      return this.$store.state.mediaQueries.includes("mobile");
    }
  },

  watch: {
    intersecting(value) {
      if (!this.slides.length) {
        return
      }
      if (value) {
        this.tweenIn();
      }
      else {
        this.$options.swiper.slideTo(0);
      }
    }
  },

  mounted() {
    this.setup();
  },

  methods: {
    setup() {
      return Promise.resolve()
        .then(this.loadData)
        .then(() => Vue.nextTick())
        .then(() => {
          this.setupIntersection();
          this.setupSlider();
        });
    },

    loadData() {
      return this.apiLoad(this.$el.getAttribute("data-endpoint")).then(data => {
        if (data && data.slides && data.slides.length) {
          this.slides = data.slides;
        }
        if (data && data.cta) {
          this.cta = data.cta;
        }

        if (data && data.title) {
          this.title = data.title;
        }

        if (data && data.description) {
          this.description = data.description;
        }
        
        if (data && data.backgroundText) {
          this.backgroundText = data.backgroundText;
        }
      });
    },

    setupSlider() {
      if (!this.$refs.slider) {
        return
      }
      this.$options.swiper = new Swiper(this.$refs.slider, {
        slidesPerView: 'auto',
        width: this.$refs.slides[0].offsetWidth * 1,
        on: {
          slideChange: () => {
            this.index = this.$options.swiper.realIndex
            this.interactions = false
            Promise.resolve()
              .then(() => this.tweenContentOut())
              .then(() => {
                this.indexContent = this.index
              })
              .then(() => this.tweenContentIn())
              .then(() => {
                this.interactions = true
              })
          }
        }
      });
    },

    setupIntersection() {
      this.$options.intObserver = new IntersectionObserver(entries => {
        this.intersecting = entries[0].isIntersecting
      }, {
        threshold: 0.2
      });

      this.$options.intObserver.observe(this.$el);
    },

    displayCursor() {
      this.$store.commit('mouseCursor', {
        display: true,
        displayLabel: true,
        style: null
      });
    },

    hideCursor() {
      this.$store.commit('mouseCursor', {
        display: false
      });
    },

    prev() {
      const nextIndex = (this.index - 1 < 0) ? this.slides.length - 1 : this.index - 1;
      this.$options.swiper.slideTo(nextIndex)
    },

    next() {
      const nextIndex = (this.index + 1 === this.slides.length) ? 0 : this.index + 1;
      this.$options.swiper.slideTo(nextIndex)
    },

    tweenIn() {
      const tl = gsap.timeline()

      this.tweenLettersIn(this.$refs.letters);
      
      if (this.$refs.slides) {
        this.$refs.slides
          .filter((el, i) => i < 2)
          .map(el => el.style.opacty = 0)
  
        tl.fromTo(
          this.$refs.slides.filter((el, i) => i < 2),
          {
            opacity: 0
          },
          {
            opacity: 1,
            stagger: 0.25,
            duration: 1,
            ease: 'power2.inOut',
            clearProps: 'all'
          },
          0
        )
      }

      if (this.$refs.title) {
        tl.fromTo(
          this.$refs.title,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 1,
            ease: 'power2.inOut',
            clearProps: 'all'
          },
          0.2
        )
      }
      
      if (this.$refs.description) {
        tl.fromTo(
          this.$refs.description,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 1,
            ease: 'power2.inOut',
            clearProps: 'all'
          },
          0.3
        )
      }

      if (this.$refs.cta) {
        tl.fromTo(
          this.$refs.cta,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 1,
            ease: 'power2.inOut',
            clearProps: 'all'
          },
          0.4
        )
      }

      if (this.$refs.infoMask) {
        tl.fromTo(
          this.$refs.infoMask,
          {
            skewX: 9,
            xPercent: 0,
            opacity: 1
          },
          {
            skewX: 0,
            xPercent: -100,
            duration: 0.8,
            ease: "power2.inOut",
            clearProps: "all"
          },
          0
        );
      }

      return tl.then()
    },

    tweenContentOut() {
      const tween = gsap.to(
        this.$refs.content,
        {
          opacity: 0,
          duration: 0.3,
          ease: 'power1.in'
        }
      )
      
      return tween.then()
    },

    tweenContentIn() {
      const tween = gsap.fromTo(
        this.$refs.content,
        {
          opacity: 0
        },
        {
          opacity: 1,
          duration: 0.3,
          ease: 'power2.out'
        }
      )
      
      return tween.then()
    }
  }
}