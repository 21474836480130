import Vue from "vue";
import gsap from "gsap";
import { SlowMo } from "gsap/EasePack";
import sette from "./core/sette.js";
import Components from "./modules/components.js";
import MediaQueries from "./modules/media-queries.js";
import VhPlugin from "./plugins/vh.js";
import '../scss/main.scss';

gsap.registerPlugin(SlowMo);

sette.init({
  modules: {
    components: {
      module: Components,
      params: {}
    },
    mediaQueries: {
      module: MediaQueries,
      params: {
        mediaQueries: [
          {
            name: "mobile",
            mediaQuery: "screen and (max-width: 767px)"
          },
          {
            name: "desktop",
            mediaQuery: "screen and (min-width: 768px)"
          }
        ]
      }
    }
  }
});

Vue.use(VhPlugin);

window.mondo = sette;
window.mondo.event = new Vue();

// add firefox class
if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
  window.document.body.parentNode.classList.add("is-firefox");
}