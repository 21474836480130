import Vue from "vue";
import gsap from "gsap";
import _ from "lodash";

export default {
  data: {
    slider: {
      autoplay: true,
      interactions: true,
      bounds: {
        min: 0,
        max: 0
      },
      xStart: 0,
      xPrev: 0,
      yPrev: 0,
      xDiff: 0,
      yDiff: 0,
      x: null
    }
  },

  mounted() {
    // init setup
    if (this.slides && this.slides.length) {
      this.sliderSetup();
    } else {
      const unwatch = this.$watch('slides', (value) => {
        if (value && value.length) {
          Vue.nextTick().then(this.sliderSetup);
          unwatch();
        }
      })
    }
  },

  methods: {
    sliderSetup() {
      if (!_.isElement(this.$refs.sliderBar)) {
        return;
      }

      if (!_.isArray(this.$refs.slides)) {
        return;
      }

      this.$refs.sliderBar.addEventListener("mousedown", this.sliderDragStart);
      this.$refs.sliderBar.addEventListener("touchstart", this.sliderDragStart,{passive:true});

      window.addEventListener("resize", _.debounce(() => {
        this.sliderTweenTo(this.index);
      }, 100));
    },

    sliderDragStart(e) {
      if (!this.slider.interactions) {
        return;
      }

      this.slider.interactions = false;
      this.slider.autoplay = false;
      clearInterval(this.$options.timeout);

      if (e.type === "touchstart") {
        this.$el.addEventListener("touchend", this.sliderDragEnd);
        this.$el.addEventListener("touchmove", this.sliderDrag);
        this.slider.xPrev = e.touches[0].clientX;
        this.slider.yPrev = e.touches[0].clientY;
      }
      if (e.type === "mousedown") {
        this.$el.addEventListener("mouseup", this.sliderDragEnd);
        this.$el.addEventListener("mousemove", this.sliderDrag);
        this.slider.xPrev = e.clientX;
        this.$store.commit("mouseCursor", { isDragging: true });
      }

      // set initialX
      const transformMatch = getComputedStyle(this.$refs.sliderBar).transform.match(/matrix\((.+)\)/)
      if (transformMatch && transformMatch[1]) {
        this.slider.xStart = parseInt(transformMatch[1].split(",")[4]); // the translate value must be in px!
      } else {
        this.slider.xStart = 0;
      }
      this.slider.x = this.slider.xStart;

      // set bounds
      this.slider.bounds.min = -this.$refs.sliderBar.offsetWidth + (_.last(this.$refs.slides).offsetWidth);
      this.slider.bounds.max = 0;

      this.onSliderDragStart();
    },

    sliderDrag(e) {
      // e.preventDefault();

      if (e.type === "touchmove") {
        this.slider.xDiff = this.slider.xPrev - e.touches[0].clientX;
        this.slider.yDiff = this.slider.yPrev - e.touches[0].clientY;
        this.slider.xPrev = e.touches[0].clientX;
        this.slider.yPrev = e.touches[0].clientY;

        // ignore drag if the drag is vertical
        if (Math.abs(this.slider.yDiff) > Math.abs(this.slider.xDiff)) {
          return;
        }
      }
      if (e.type === "mousemove") {
        this.slider.xDiff = this.slider.xPrev - e.clientX;
        this.slider.xPrev = e.clientX;
      }
      this.slider.x -= this.slider.xDiff;
    },

    sliderDragEnd(e) {
      let nextIndex = null;

      // reset listeners
      if (e.type === "touchend") {
        this.$el.removeEventListener("touchend", this.sliderDragEnd);
        this.$el.removeEventListener("touchmove", this.sliderDrag);
      }
      if (e.type === "mouseup") {
        this.$el.removeEventListener("mouseup", this.sliderDragEnd);
        this.$el.removeEventListener("mousemove", this.sliderDrag);
        this.$store.commit("mouseCursor", { isDragging: false });
      }

      // center slider
      if (this.slider.x < this.slider.bounds.min) {
        nextIndex = this.slides.length - 1;
      } else if (this.slider.x > this.slider.bounds.max) {
        nextIndex = 0;
      } else {
        const direction = this.slider.xStart > this.slider.x ? "left" : "right";
        const sliderWidth = this.$refs.slider ? this.$refs.slider.offsetWidth : this.$el.offsetWidth;
        // console.log('sliderWidth', sliderWidth)
        const bounds = [sliderWidth * 0.1, sliderWidth * 0.9];
        const visibleSlides = this.$refs.slides.map(el => _.inRange(this.slider.x + el.offsetLeft, bounds[0], bounds[1]) || _.inRange(this.slider.x + el.offsetLeft + el.offsetWidth, bounds[0], bounds[1]))
        console.log(visibleSlides)

        if (!visibleSlides.filter(value => value === true).length) {
          nextIndex = this.index;
        } else {
          if (direction === "left") {
            nextIndex = _.findLastIndex(visibleSlides, (value) => value === true);
          } else if (direction === "right") {
            nextIndex = _.findIndex(visibleSlides, (value) => value === true);
          }
        }
      }

      // animate bar to next slide
      if (_.isNumber(nextIndex)) {
        this.sliderTweenTo(nextIndex);
      }
      
      this.onSliderDragEnd(nextIndex);
    },

    sliderTweenTo(index) {
      let fromX;
      let toX;

      if (_.isNumber(this.slider.x)) {
        fromX = this.slider.x;
      } 
      else if (this.$refs.slides[this.index]) {
        fromX = -this.$refs.slides[this.index].offsetLeft;
      }
      else {
        fromX = 0;
      }

      if (this.$refs.slides[index]) {
        toX = -this.$refs.slides[index].offsetLeft;
      } else {
        toX = 0;
      }

      this.slider.interactions = false;
      this.beforeSliderTweenTo(index);

      const tween = gsap.fromTo(this.$refs.sliderBar, {
        x: fromX
      }, {
        x: toX,
        duration: 0.5,
        ease: 'power2.inOut',
        onComplete: () => {
          this.slider.xPrev = null;
          this.slider.x = null;
          this.index = index;
          this.slider.interactions = true;
          Vue.nextTick().then(() => this.afterSliderTweenTo(index));
        }
      });

      return tween.then();
    },

    onSliderDragStart() {},

    onSliderDragEnd() {},

    beforeSliderTweenTo() {},

    afterSliderTweenTo() {}
  }
};