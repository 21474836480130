import ApiLoadMixin from "../mixins/api.js";
import FloatingButtonMixin from "../mixins/floatingbutton.js";
import gsap from 'gsap';
import _ from 'lodash';

export default {
  name: "Editorial",

  mixins: [ApiLoadMixin, FloatingButtonMixin],

  data: {
    dragActive: false,
    dragStartX: 0,
    dragMoveX: 0,
    ignoreDrag: false,
    index: -1,
    indexSet: 0,
    indexFuture: null,
    intersecting: false,
    sets: [],
    sticky: false,
    viewportWidth: 0
  },

  computed: {
    dragDirection() {
      if (this.dragMoveX < 0) {
        return 'next';
      }
      if (this.dragMoveX > 0) {
        return 'prev';
      }
      return null;
    },

    dragPercent() {
      return Math.abs(this.dragMoveX) / (this.viewportWidth * 0.01);
    },

    indexNext() {
      if (typeof this.indexFuture === 'number') {
        return this.indexFuture;
      }
      if (this.index + 1 === this.images.length) {
        return 0;
      }
      return this.index + 1;
    },

    indexPrev() {
      if (typeof this.indexFuture === 'number') {
        return this.indexFuture;
      }
      if (this.index - 1 < 0) {
        return this.images.length - 1;
      }
      return this.index - 1;
    },

    isMobile() {
      return this.$store.state.mediaQueries.includes('mobile');
    },

    image() {
      if (this.images[this.index]) {
        return this.images[this.index];
      }
      return null;
    },

    imageNext() {
      if (this.images[this.indexNext]) {
        return this.images[this.indexNext];
      }
      return null;
    },

    imagePrev() {
      if (this.images[this.indexPrev]) {
        return this.images[this.indexPrev];
      }
      return null;
    },

    images() {
      const images = []
      this.sets.forEach((set, setIndex) => {
        set.slides.forEach(slide => {
          images.push({
            setIndex,
            image: slide.image
          });
        })
      })
      return images;
    },

    indexImageSet() {
      const setImagesTot = this.sets.map(set => set.slides.length);
      let sum = 0
      for (let i = 0; i < this.indexSet; i++) {
        sum += setImagesTot[i];
      }
      return this.index - sum
    },

    mouseLabel() {
      if (!this.sets[this.indexSet]) {
        return;
      }
      const totImages = this.sets[this.indexSet].slides.length
      let index = 0
      return `${this.indexImageSet + 1} / ${totImages}`;
    }
  },

  watch: {
    dragActive(value) {
      if (!value && this.$options.slideTl) {
        // complete tween or reset it
        if (this.$options.slideTl.totalProgress() >= 0.04) {
          this.ignoreDrag = true;
          setTimeout(() => {
            this.$options.slideTl.play();

            // if the timeline is paused 10 milliseconds later start again (sometimes this happes)
            setTimeout(() => {
              if(this.$options.slideTl && !this.$options.slideTl.isActive()) {
                this.$options.slideTl.play();
              }
            }, 10);
          }, 1);
        } else {
          setTimeout(() => {
            this.$options.slideTl.tweenTo(0);
          }, 1);
        }
      }
    },

    dragPercent(value) {
      if (this.$options.slideTl && value > 0) {
        this.$options.slideTl.tweenTo(value * (this.$options.slideTl.totalDuration() * 0.01));
      }
    },

    dragMoveX(value) {
      // set timeline
      if (this.dragActive && this.dragDirection && !this.$options.slideTl) {
        this.tweenSlide();
      }
    },

    index(value) {
      let y = 0;
      let indexFound = false;
      this.sets
        .map(set => set.slides.length)
        .forEach((length, i) => {
          if (indexFound) {
            return;
          }
          y += length
          if (value < y) {
            indexFound = true;
            this.indexSet = i;
          }
        });
      
      this.$store.commit('mouseCursor', {
        label: this.mouseLabel
      });

      if (value === 0 && this.$refs.indexes) {
        this.$refs.indexes.scrollTo({ left: 0, behavior: "smooth" });
      }
    },

    intersecting(value) {
      if (value) {
        if (this.images.length) {
          this.dragMoveX = -1; // force next
          // tween first slide
          setTimeout(() => {
            this.tweenSlide();
            this.$options.slideTl.play();
          }, 1);
        }
      } else {
        this.index = -1
        if (this.$refs.indexes) {
          this.$refs.indexes.scrollTo({ left: 0, behavior: "smooth" });
        }
        // reset timeline
        if (this.$options.slideTl && this.$options.slideTl.totalProgress() > 0) {
          this.$options.slideTl.kill()
        }
      }
    }
  },

  mounted () {
    this.setup();
  },

  methods: {
    setup() {
      this.setupIntersection();

      // test sticky property
      if (CSS && CSS.supports && CSS.supports("position", "sticky")) {
        this.sticky = true;
      }

      // set viewport width
      this.viewportWidth = window.innerWidth;
      window.addEventListener('resize', _.debounce(() => {
        this.viewportWidth = window.innerWidth;
      }, 500));

      Promise.resolve()
        .then(() => this.loadData())
        .then(() => {
          // tween first slide
          if (this.intersecting) {
            this.dragMoveX = -1; // force next
            setTimeout(() => {
              this.tweenSlide();
              this.$options.slideTl.play();
            }, 1);
          }
        })
    },

    setupIntersection() {
      // start stop slider timeout when in or out of the viewport
      this.$options.intObserver = new IntersectionObserver(entries => {
        const entry = entries[0];
        this.intersecting = entry.isIntersecting
      }, {
        threshold: 0.05
      });

      this.$options.intObserver.observe(this.$el);
    },

    loadData() {
      return this.apiLoad(this.$el.getAttribute("data-endpoint")).then(data => {
        if (data && data.sets && data.sets.length) {
          this.sets = data.sets;
        }
        if (data && data.timeout && typeof data.timeout === "number") {
          this.timeout = data.timeout;
        }
        this.floatingButtonSet(data);
      });
    },

    prev() {
      if (this.$options.slideTl && this.$options.slideTl.totalProgress() > 0) {
        return;
      }
      this.dragMoveX = 10; // force direction to prev
      setTimeout(() => {
        this.tweenSlide();
        this.$options.slideTl.play();
      }, 1);
    },

    next() {
      console.log('next')
      if (this.$options.slideTl && this.$options.slideTl.totalProgress() > 0) {
        return;
      }
      this.dragMoveX = -10; // force direction to prev
      setTimeout(() => {
        this.tweenSlide();
        this.$options.slideTl.play();
      }, 1);
    },

    onMouseDown(e) {
      if (this.ignoreDrag) {
        return
      }
      const x = e.clientX === undefined ? e.touches[0].clientX : e.clientX;
      this.dragActive = true;
      this.dragStartX = x;
      this.$store.commit('mouseCursor', {
        isDragging: true
      });
    },

    onMouseMove(e) {
      if (this.ignoreDrag) {
        return
      }
      if (!this.dragActive) {
        return;
      }
      const x = e.clientX === undefined ? e.touches[0].clientX : e.clientX;
      this.dragMoveX = x - this.dragStartX;
    },
    
    onMouseUp() {
      if (this.ignoreDrag) {
        return
      }
      this.dragActive = false;
      this.$store.commit('mouseCursor', {
        isDragging: false
      });
    },

    displayCursor() {
      this.$store.commit('mouseCursor', {
        display: true,
        displayLabel: true,
        label: this.mouseLabel,
        style: 'alt'
      });
    },

    hideCursor() {
      this.$store.commit('mouseCursor', {
        display: false,
        displayLabel: false,
        label: '',
        style: null
      });
    },

    scrollToThis() {
      const index = Array.from(this.$el.parentNode.querySelectorAll(":scope > *")).indexOf(this.$el);
      window.mondo.event.$emit("NavIndexes.scrollTo", index);
    },

    setIndex(indexSet) {
      // ignore if there's a timeline active
      if (this.$options.slideTl && this.$options.slideTl.totalProgress() > 0) {
        return;
      }
      this.indexSet = indexSet;
      this.scrollToThis();

      let imageIndex = null;
      let index = 0;
      while (imageIndex === null) {
        if (this.images[index].setIndex === indexSet) {
          imageIndex = index;
        }
        else {
          index++;
        }
      }

      // do not toggle if same index
      if (index === this.index) {
        return;
      }
      
      this.dragMoveX = -10; // force direction to next
      this.indexFuture = index;
      setTimeout(() => {
        this.tweenSlide();
        this.$options.slideTl.play();
      }, 1);
    },

    tweenSlide() {
      const tl = gsap.timeline({
        paused: true,
        onStart: () => {
          picture.style.opacity = 1;
        }
      });
      this.$options.slideTl = tl;

      const direction = this.dragDirection;
      const selector = direction === 'next' ? '.editorial__picture--next' : '.editorial__picture--prev';
      const picture = this.$el.querySelector(selector);

      if (!picture) {
        return;
      }

      const polygonObj = {
        xTopLeft: direction === 'next' ? 100 : 0,
        xBottomLeft: direction === 'next' ? 100 : 0,
        xTopRight: direction === 'next' ? 100 : 0,
        xBottomRight: direction === 'next' ? 100 : 0
      };

      const tween1Param = direction === 'next' ? { xTopLeft: 0 } : { xTopRight: 100 };
      const tween2Param = direction === 'next' ? { xBottomLeft: 0 } : { xBottomRight: 100 };

      tl.to(
        polygonObj,
        Object.assign({
          duration: 1,
          ease: 'power2.out',
          onUpdate: () => {
            const value = `polygon(${polygonObj.xTopLeft}% 0%, ${polygonObj.xTopRight}% 0%, ${polygonObj.xBottomRight}% 100%, ${polygonObj.xBottomLeft}% 100%)`;
            picture.style.webkitClipPath = value;
            picture.style.clipPath = value;
          }
        }, tween1Param),
        0
      );
      
      tl.to(
        polygonObj,
        Object.assign({
          duration: 1.2,
          ease: 'power2.out',
          onUpdate: () => {
            const value = `polygon(${polygonObj.xTopLeft}% 0%, ${polygonObj.xTopRight}% 0%, ${polygonObj.xBottomRight}% 100%, ${polygonObj.xBottomLeft}% 100%)`;
            picture.style.webkitClipPath = value;
            picture.style.clipPath = value;
          },
        }, tween2Param),
        0
      );
      
      const pictureCurrent = this.$el.querySelector('.editorial__picture--active')
      if (pictureCurrent) {
        tl.to(
          pictureCurrent,
          {
            opacity: 0.2,
            duration: 1.2,
            ease: 'power2.out',
            clearProps: 'all'
          },
          0
        );
      }

      const image = picture.querySelector('img');
      if (image) {
        tl.fromTo(
          image,
          {
            xPercent: direction === 'next' ? 10 : -10,
            transformOrigin: direction === 'next' ? '0 50%' : '100% 0',
            scale: 1.2
          },
          {
            xPercent: 0,
            scale: 1,
            duration: 1.2,
            ease: 'power2.inOut',
            clearProps: 'all'
          },
          0
        );
      }

      tl.then(() => {
        // reset
        picture.style.opacity = null
        picture.style.webkitClipPath = null;
        picture.style.clipPath = null;
        this.$options.slideTl = null;
        this.dragMoveX = 0;
        this.ignoreDrag = false;

        // set new index
        this.index = direction === 'next' ? this.indexNext : this.indexPrev;

        this.indexFuture = null;
      });

      return tl;
    }
  }
}
