import Vue from "vue";
import gsap from "gsap";
import ApiLoadMixin from "../mixins/api.js";
import SliderMixin from "../mixins/slider.js";
import TweenMixin from "../mixins/tween.js";
import FloatingButtonMixin from "../mixins/floatingbutton.js";

export default {
  name: "ReferencesSlider",

  mixins: [ApiLoadMixin, SliderMixin, TweenMixin, FloatingButtonMixin],

  data: {
    buttonUrl: null,
    index: -1,
    indexInfo: null,
    mobileLabelDisplay: true,
    revealed: false,
    slides: [],
    slideSelect: false,
    slideSelectOffsetStartX: 0,
    slideSelectOffsetX: 0,
    timeout: 8000,
    title: "",
    smallTitle: ""
  },

  computed: {
    barStyle() {
      let translateX;
      let width = this.isMobile ? this.slides.length * 100 : this.slides.length * 40;
 
      if (this.slider.x !== null) {
        translateX = this.slider.x;
      } else {
        if (this.$refs.slides && this.$refs.slides[this.index]) {
          translateX = -this.$refs.slides[this.index].offsetLeft;
        } else {
          translateX = 0;
        }
      }
 
      return {
        width: `${width}%`,
        transform: `translateX(${translateX}px)`
      };
    },

    currentSlide() {
      if (this.index >= 0 && this.slides[this.index]) {
        return this.slides[this.index];
      }
      return null;
    },

    isMobile() {
      return this.$store.state.mediaQueries.includes("mobile");
    },

    slideInfo() {
      if (this.indexInfo === null) {
        return null;
      }
      return this.slides[this.indexInfo];
    },

    slideStyle() {
      return {
        width: `${100/this.slides.length}%`
      };
    }
  },

  watch: {
    indexInfo(value) {
      this.mobileLabelDisplay = value === null;
      if (this.isMobile) {
        if (value === null) {
          document.body.classList.remove("unscrollable");
        } else {
          document.body.classList.add("unscrollable");
          window.scrollTo({
            top: this.$el.getBoundingClientRect().top + window.pageYOffset,
            behavior: "smooth"
          });
        }
      } else {
        // on desktop, display custom mouse cursor on close
        if (value === null) {
          this.mouseCursorDisplay();
        }
      }
    }
  },

  mounted() {
    this.setup();
  },

  methods: {
    setup() {
      return Promise.resolve()
        .then(this.loadData)
        .then(() => Vue.nextTick())
        .then(() => {
          this.$options.intListener = new IntersectionObserver(entries => {
            const entry = entries[0];
            if (entry && entry.isIntersecting && !this.revealed) {
              this.revealed = true;
              this.index = 0;
            }
            if (entry && entry.isIntersecting) {
              this.tweenIn();
              this.setTimeout(); // restart
            }
            if (entry && !entry.isIntersecting) {
              if (this.slides.length) {
                this.sliderTweenTo(0); // reset slider
              }
              if (this.indexInfo !== null) {
                this.slideToggleOut(); // auto switch to mobile
              }
              clearTimeout(this.$options.timeout); // stop autoplay
            }
          }, { threshold: 0.1 });

          this.$options.intListener.observe(this.$el);
        });
    },

    loadData() {
      return this.apiLoad(this.$el.getAttribute("data-endpoint")).then(data => {
        if (data && data.slides && data.slides.length) {
          this.slides = data.slides;
        }
        if (data && data.buttonUrl) {
          this.buttonUrl = data.buttonUrl;
        }
        if (data && data.title) {
          this.title = data.title;
        }
        if (data && data.smallTitle) {
          this.smallTitle = data.smallTitle;
        }
        this.floatingButtonSet(data);
      });
    },

    setTimeout() {
      clearTimeout(this.$options.timeout);
      this.$options.timeout = setTimeout(() => {
        const nextIndex = (this.index + 1 === this.slides.length) ? 0 : this.index + 1;
        this.sliderTweenTo(nextIndex);
      }, this.timeout);
    },

    mobileToggleDetails() {
      if (!this.isMobile) {
        return;
      }
      if (this.indexInfo === null) {
        this.slideToggleInMobile();
      } else {
        this.slideToggleOutMobile();
      }
    },

    mouseCursorDisplay() {
      if (this.indexInfo !== null) {
        return;
      }
      this.$store.commit("mouseCursor", { display: true, displayLabel: true,
        label:window.mondoConfig.strings.dragLabel||'' });
    },

    mouseCursorHide() {
      this.$store.commit("mouseCursor", { display: false, displayLabel: false });
    },

    slideSelectStart(i, e) {
      if (this.isMobile) {
        return;
      }
      if (this.indexInfo !== null) {
        return;
      }
      if (this.slideSelect) {
        return;
      }
      this.slideSelect = true;
      this.slideSelectOffsetStartX = e.screenX;
      this.slideSelectOffsetX = 0;
    },

    slideSelectMove(i, e) {
      if (this.slideSelect) {
        this.slideSelectOffsetX = e.screenX - this.slideSelectOffsetStartX;
      }
    },

    slideSelectEnd(i) {
      this.slideSelect = false;
      if (this.indexInfo === null && Math.abs(this.slideSelectOffsetX) < 100) {
        this.indexInfo = i;
        clearTimeout(this.$options.timeout); // stop slider timeout
        this.slideSelect = false; // reset slideSelect
        this.slideToggleIn();
      }
    },

    slideToggleIn() {
      if (this.isMobile) {
        this.slideToggleInMobile();
        return;
      }
      const slideEl = this.$refs.slides[this.indexInfo];
      const slideImgEl = slideEl.querySelector("img");
      const tl = gsap.timeline();

      setTimeout(() => {
        this.sliderTweenTo(this.indexInfo).then(() => {
          this.slider.interactions = false; // block slider
        });
      }, 500);

      tl.to(this.$refs.slider, {
        x: this.$refs.slider.offsetWidth - (slideEl.offsetWidth * 1.5),
        duration: 1,
        ease: 'circ.inOut'
      }, 0);

      if (slideImgEl) {
        tl.fromTo(slideImgEl, {
          width: "100%"
        }, {
          width: "150%",
          duration: 1,
          ease: 'circ.inOut'
        }, 0);
      }

      if (this.$refs.infoDetails) {
        tl.to(this.$refs.infoDetails, {
          x: '26rem',
          duration: 1,
          ease: 'circ.inOut'
        }, 0);
      }

      return tl.then();
    },

    slideToggleInMobile() {
      if (!this.isMobile) {
        this.slideToggleIn();
        return;
      }

      const tl = gsap.timeline({
        onStart: () => {
          this.slider.interactions = false;
        }
      });
      this.indexInfo = this.index;

      tl.fromTo(this.$refs.info, {
        yPercent: 0
      }, {
        yPercent: 100,
        duration: 1,
        ease: "circ.inOut"
      }, 0);

      tl.to(this.$refs.detailsLabelMobile, {
        y: "-20rem",
        duration: 1,
        ease: "circ.inOut"
      }, 0);
      
      tl.to(this.$refs.detailsLabelMobile.querySelector("svg"), {
        rotate: "45deg",
        duration: 1,
        ease: "circ.inOut"
      }, 0);
      
      tl.fromTo(this.$refs.infoDetails, {
        opacity: 0,
        yPercent: 20
      }, {
        opacity: 1,
        yPercent: 0,
        duration: 1,
        ease: "circ.inOut",
        clearProps: "opacity,yPercent"
      }, 0);
      
      return tl.then();
    },

    slideToggleOut() {
      if (this.isMobile) {
        this.slideToggleOutMobile();
        return;
      }

      const slideEl = this.$refs.slides[this.indexInfo];
      const slideImgEl = slideEl.querySelector("img");
      const tl = gsap.timeline({
        onComplete: () => {
          this.indexInfo = null;
          this.slider.interactions = true;
        }
      });

      tl.to(this.$refs.close, {
        opacity: 0,
        ease: 'circ.inOut',
        duration: 1,
        clearProps: 'opacity'
      }, 0);

      tl.to(this.$refs.slider, {
        x: 0,
        duration: 1,
        ease: 'circ.inOut',
        clearProps: 'x'
      }, 0);

      if (slideImgEl) {
        tl.to(slideImgEl, {
          width: "100%",
          duration: 1,
          ease: 'circ.inOut',
          clearProps: 'width'
        }, 0);
      }

      if (this.$refs.infoDetails) {
        tl.to(this.$refs.infoDetails, {
          x: 0,
          duration: 1,
          ease: 'circ.inOut',
          clearProps: 'x'
        }, 0);
      }

      return tl.then();
    },

    slideToggleOutMobile() {
      const tl = gsap.timeline({
        onComplete: () => {
          this.indexInfo = null;
          this.slider.interactions = true;
        }
      });

      tl.to(this.$refs.info, {
        yPercent: 0,
        duration: 0.75,
        ease: "circ.inOut",
        clearProps: "yPercent"
      }, 0);

      tl.to(this.$refs.detailsLabelMobile, {
        y: 0,
        duration: 0.75,
        ease: "circ.inOut",
        clearProps: "y"
      }, 0);

      tl.to(this.$refs.detailsLabelMobile.querySelector("svg"), {
        rotate: "0deg",
        duration: 0.75,
        ease: "circ.inOut",
        clearProps: "rotate"
      }, 0);

      tl.to(this.$refs.infoDetails, {
        opacity: 0,
        yPercent: 20,
        duration: 0.75,
        ease: "circ.inOut",
        clearProps: "opacity,yPercent"
      }, 0);

      return tl.then();
    },

    tweenIn() {
      if (this.isMobile) {
        return Promise.resolve();
      }
      
      const tl = gsap.timeline();

      tl.fromTo([this.$refs.bkgFront, this.$refs.bkgBack], {
        xPercent: -100,
        skewX: 0,
        opacity: 0
      }, {
        xPercent: 0,
        skewX: -18,
        opacity: 1,
        duration: 1,
        ease: "power4.inOut",
        clearProps: "transform,opacity",
        stagger: 0.1
      }, 0);

      if (this.$refs.content) {
        tl.fromTo(this.$refs.content.querySelectorAll(':scope > *'), {
          opacity: 0,
          y: 10,
          scale: 1
        }, {
          opacity: 1,
          y: 0,
          scale: 1,
          ease: "power4.inOut",
          clearProps: "transform,opacity",
          duration: 1,
          stagger: 0.1
        }, 0.5);
      }

      if (this.$refs.slideImages && this.$refs.slideImages.length) {
        tl.fromTo(this.$refs.slideImages, {
          scale: 1.1,
          x: 20,
          opacity: 0
        }, {
          scale: 1,
          x: 0,
          opacity: 1,
          duration: 1,
          ease: "power4.inOut",
          clearProps: "transform,opacity",
          stagger: 0.1
        }, 0.5);
      }

      return tl.then();
    },

    onSliderDragStart() {
      this.mobileLabelDisplay = false;
    },

    afterSliderTweenTo() {
      this.mobileLabelDisplay = true;
    }
  }
};
