import Vue from "vue";
import gsap from "gsap";
import ApiLoadMixin from "../mixins/api.js";
import TweenMixin from "../mixins/tween.js"
import FloatingButtonMixin from "../mixins/floatingbutton.js";

export default {
  name: "ProductsGrid",

  mixins: [ApiLoadMixin, TweenMixin, FloatingButtonMixin],

  data: {
    backgroundText: [],
    isTweenIn: false,
    title: "",
    description: "",
    productsIndex: null
  },

  computed: {
    isMobile() {
      return this.$store.state.mediaQueries.includes("mobile");
    },

    products() {
      if (typeof this.productsIndex === "number" && this.$store.state.products[this.productsIndex]) {
        return this.$store.state.products[this.productsIndex];
      }
      return [];
    },

    productsSliderVisible() {
      return this.$store.state.productsSlider.visible;
    }
  },

  watch: {
    products() {
      this.loadIcons();
    },

    productsSliderVisible(value) {
      if (value) {
        this.tweenOut();
      } else {
        this.tweenIn();
      }
    }
  },

  mounted() {
    this.productsIndex = parseInt(this.$el.parentNode.getAttribute("data-products-index"));

    Promise.resolve()
      .then(this.loadData)
      .then(() => Vue.nextTick())
      .then(this.setup)
  },

  methods: {
    loadData() {
      return this.apiLoad(this.$el.getAttribute("data-endpoint")).then(data => {
        if (data && data.title) {
          this.title = data.title;
        }
        if (data && data.description) {
          this.description = data.description;
        }
        if (data && data.products && data.products.length) {
          this.$store.commit("products", {
            index: this.productsIndex,
            products: data.products
          });
        }
        if (data && data.backgroundText && data.backgroundText.length) {
          this.backgroundText = data.backgroundText;
        }
        this.floatingButtonSet(data);
      });
    },

    loadIcons() {
      if (!(this.products && this.products.length)) {
        return;
      }
      const icons = [];
      this.products.forEach(product => {
        if (product && product.pins && product.pins.length) {
          product.pins.forEach(pin => {
            if (!icons.includes(pin.icon)) {
              icons.push(pin.icon);
            }
          });
        }
      });

      icons.forEach(icon => {
        fetch(`/assets/svg/products-icons/${icon}.svg`)
          .then(response => response.text())
          .then(svg => {
            this.$store.commit("productIcon", {
              key: icon,
              value: svg
            });
          });
      });
    },

    setup() {
      this.$options.intListener = new IntersectionObserver(entries => {
        const entry = entries[0];
        if (entry && entry.isIntersecting) {
          this.tweenIn();
        }
      }, { threshold: 0.1 });

      this.$options.intListener.observe(this.$el);
    },

    toggleSlider(i) {
      const top = window.pageYOffset + this.$el.parentNode.getBoundingClientRect().top;
      if (this.isMobile) {
        setTimeout(() => {
          window.scrollTo({
            top
          });
        }, 1);
      } else {
        window.scrollTo({
          top,
          behavior: "smooth"
        });
      }
      window.mondo.event.$emit("ProductsSlider.toggle", this.productsIndex, i);
    },

    tweenIn() {
      const tl = gsap.timeline({
        onComplete: () => {
          this.isTweenIn = false;
        }
      });
      if (this.isTweenIn) {
        return;
      }
      this.isTweenIn = true;

      this.tweenLettersIn(this.$refs.letters);

      gsap.set(this.$refs.products, { transition: "unset" });

      tl.fromTo(this.$refs.title, {
        opacity: 0,
        y: 10
      }, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "Power4.inOut",
        clearProps: "all"
      }, 0);
      
      tl.fromTo(this.$refs.description, {
        opacity: 0,
        y: 10
      }, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "Power4.inOut",
        clearProps: "all"
      }, 0.1);

      this.$refs.products.forEach(el => {
        el.style.transition = "unset";
      });

      tl.fromTo(this.$refs.products, {
        opacity: 0,
        y: 10
      }, {
        opacity: 1,
        y: 0,
        duration: 0.3,
        stagger: 0.05,
        ease: "Power4.out",
        clearProps: "all",
        onComplete: () => {
          this.$refs.products.forEach(el => {
            el.style.transition = null;
          });
        }
      }, 0.2);

      return tl.then();
    },

    tweenOut() {
      const tl = gsap.timeline();

      this.tweenLettersOut(this.$refs.letters);

      gsap.set(this.$refs.products, { transition: "unset" });

      tl.to(this.$refs.title, {
        opacity: 0,
        y: 10,
        duration: 0.5,
        ease: "Power4.inOut"
      }, 0);

      tl.to(this.$refs.products, {
        opacity: 0,
        y: 10,
        duration: 0.25,
        stagger: 0.1,
        ease: "Power4.out"
      }, 0.2);

      return tl.then();
    }
  }
};
