import gsap from "gsap";
import TweenMixin from "../mixins/tween.js";
import Vue from "vue";

export default {
  name: "LightboxGallery",

  mixins: [TweenMixin],

  data() {
    return {
      items:[],
      index:0,
      ratio:1,
      isFirst:true
    };
  },

  computed: {
    active() {
      return this.$store.state.lightboxGallery.active;
    }
  },

  watch: {
    index(value,oldValue){

      this.$refs.carousel.style.left=`${-value*(100)}%`;
      gsap.fromTo(this.$refs.carousel,{opacity:0},{opacity:1,duration:1,ease:'expo.out',clearProps:'opacity'})

    }
  },

  mounted () {
    window.mondo.event.$on("LightboxGallery.toggle", this.toggle);
  },

  methods: {

    toggle(content) {

      if(content)
      {
        this.items=content.items;
        this.index=content.index;
      }

      if (this.active) {
        this.tween("out").then(() => {
          this.$store.commit("lightboxGallery", { active: false });
          this.items=[];
          this.index=null;
        });
      } else if (!this.active) {
        this.$store.commit("lightboxGallery", { active: true });
        this.tween("in").then(() => {
          
        })
      }
    },

    prev(){
      if(this.index>0) this.index--;
    },
    next(){
      if(this.index<this.items.length-1)  this.index++;
    },

    tween(type) {
      return new Promise(resolve => {
        const tl = gsap.timeline()

        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        const from = {
          clipPath: type === 'in' ? 'inset(0% 0% 15% 0%)' : 'inset(0% 0% 0% 0%)',
          opacity: type === 'in' ? 0 : 1
        };
        const to = {
          clipPath: type === 'in' ? 'inset(0% 0% 0% 0%)' : 'inset(0% 0% 15% 0%)',
          opacity: type === 'in' ? 1 : 0,
          duration: 1,
          ease: "power2.inOut",
          clearProps: "clip-path,opacity",
          onComplete: resolve
        }
  
        if (!isFirefox) {
          from.webkitClipPath = type === 'in' ? 'inset(0% 0% 15% 0%)' : 'inset(0% 0% 0% 0%)';
          to.webkitClipPath = type === 'in' ? 'inset(0% 0% 0% 0%)' : 'inset(0% 0% 15% 0%)';
          to.clearProps = "clip-path,-webkit-clip-path";
        }
  
        tl.fromTo(
          this.$refs.background,
          from,
          to,
          type === 'in' ? 0.1 : 0.45
        );
        
        tl.fromTo(
          this.$refs.close,
          {
            opacity: type === 'in' ? 0 : 1,
          },
          {
            opacity: type === 'in' ? 1 : 0,
            duration: 1,
            ease: "power4.inOut"
          },
          type === 'in' ? 0.75 : 0.1
        );

        tl.fromTo(
          '.lightbox-gallery__content',
          {
            opacity: type === 'in' ? 0 : 1,
            yPercent: type === 'in' ? -5 : 0
          },
          {
            opacity: type === 'in' ? 1 : 0,
            yPercent: type === 'in' ? 0 : -5,
            duration: 1,
            ease: "power4.inOut",
            clearProps: "transform , opacity"
          },
          type === 'in' ? 0.75 : 0.1
        )

        tl.fromTo(
          this.$refs.nav,
          {
            opacity: type === 'in' ? 0 : 1,
            yPercent: type === 'in' ? -5 : 0
          },
          {
            opacity: type === 'in' ? 1 : 0,
            yPercent: type === 'in' ? 0 : -5,
            duration: 1,
            ease: "power4.inOut",
            clearProps: "transform , opacity"
          },
          type === 'in' ? 0.75 : 0.1
        )

        tl.fromTo(
          this.$refs.nav2,
          {
            opacity: type === 'in' ? 0 : 1,
            yPercent: type === 'in' ? -5 : 0
          },
          {
            opacity: type === 'in' ? 1 : 0,
            yPercent: type === 'in' ? 0 : -5,
            duration: 1,
            ease: "power4.inOut",
            clearProps: "transform , opacity"
          },
          type === 'in' ? 0.75 : 0.1
        )
      });
    }
  }
}
