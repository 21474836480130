import store from "../store/index.js"

const mediaQueries = {
  init(sette, params) {
    if (params.mediaQueries && params.mediaQueries.length) {
      params.mediaQueries.forEach(mq => {
        mq.mql = window.matchMedia(mq.mediaQuery)

        const mqlListener = function (mql) {
          window.document.documentElement.classList[mql.matches ? "add" : "remove"](`mq-${mq.name}`)
          store.commit("mediaQueries", { [mql.matches ? "add" : "remove"]: mq.name })
        }

        mq.mql.addListener(mqlListener)
        mqlListener(mq.mql)

        if (typeof mq.listener === "function") {
          mq.mql.addListener(mq.listener)
        }
      })
    }

    return Promise.resolve()
  }
}

export default mediaQueries