import Vue from "vue";
import gsap from "gsap";
import _ from "lodash";
import TweenMixin from "../mixins/tween.js";
import ApiLoadMixin from "../mixins/api.js";
import FloatingButtonMixin from "../mixins/floatingbutton.js";

export default {
  name: "HeroSlider3",

  mixins: [TweenMixin, ApiLoadMixin, FloatingButtonMixin],

  data: {
    cursorOverPin: false,
    index: null,
    indexNext: null,
    indexDisplay: null,
    maxIndex: 0,
    pointerX: 0,
    pointerY: 0,
    slides: [],
    viewportWidth: 0,
    viewportHeight: 0,
    stopInteractions: false,
    videoTime: 0,
    pinCarouselContainer:null,
    pinCarouselIndex:0,
    pinCarouselItems:null,
    cta_pins:null
  },

  computed: {
    isMobile() {
      return this.$store.state.mediaQueries.includes('mobile');
    },

    lightboxActive() {
      return this.$store.state.lightbox.active;
    },

    mediaQueries() {
      return this.$store.state.mediaQueries;
    },

  },

  watch: {
    cursorOverPin(value) {
      clearTimeout(this.$options.timeout);
      if (!value) {
        this.$options.timeout = setTimeout(this.next, this.timeout);        
      }
    },

    indexNext(value) {
      
      if (typeof value === "number") {
        Vue.nextTick().then(this.toggleSlide);
        this.$store.commit("heroSlider", { indexNext: value });
      }
    },

    lightboxActive(value) {
      if (value) {
        clearTimeout(this.$options.timeout);
      }
      else {
        this.$options.timeout = setTimeout(this.next, this.timeout); // restart timeout
      }
    },

    slides(values) {
      if (values && values.length && this.index === null) {
        this.maxIndex = values.length - 1;
      }
    },
    pinCarouselContainer(el){

      if(el){
        gsap.set(el,{left:0})
        this.pinCarouselItems=el?el.querySelectorAll('li').length:null;
        this.pinCarouselIndex=0;
      }
     
    },
    pinCarouselIndex(value){
      if(this.pinCarouselContainer) gsap.to(this.pinCarouselContainer,{ease:'expo.inOut',duration:1,left:`${-100*value}%`})
    }
  },

  mounted() {
    this.$el.addEventListener("keydown", this.onKeyDown, false);
    this.setup();
  },

  beforeDestroy() {
    this.$el.removeEventListener("keydown", this.onKeyDown, false);
  },

  methods: {
    setup() {
      // set viewportWidth for parallax slides
      this.viewportWidth = window.innerWidth;
      this.viewportHeight = window.innerHeight;

      this.setMobileHeight();

      
      window.addEventListener('resize', _.debounce(() => {
        this.viewportWidth = window.innerWidth;
        this.viewportHeight = window.innerHeight;
        
        this.setMobileHeight();
      }, 500));

      return Promise.resolve()
        .then(this.setupIntersection)
        .then(this.loadData)
        .then(this.preloadImages)
        .then(() => Vue.nextTick())
        .then(() => {
         
          if (this.slides.length) {
            this.indexNext = 0; // init slider
          }
        })
    },
    setMobileHeight(){
      if(this.isMobile){
        this.$el.style.height=window.screen.height+'px'
      }
    },
    setupIntersection() {
      // start stop slider timeout when in or out of the viewport
      this.$options.intObserver = new IntersectionObserver(entries => {
        const entry = entries[0];
        if (entry) {
          if (entry.isIntersecting && this.slides.length && this.index === null) {
            this.indexNext = 0; // toggle in slider when the slider is displayed again
            this.$options.timeout = setTimeout(this.next, this.timeout); // restart timeout
          }

          if (!entry.isIntersecting && this.index !== null) {
            this.index = null;
            this.indexNext = null; // reset
            clearTimeout(this.$options.timeout); // stop slider
          }
        }
      }, {
        threshold: 0.1
      });

      this.$options.intObserver.observe(this.$el);
      return Promise.resolve();
    },

    loadData() {
      return this.apiLoad(this.$el.getAttribute("data-endpoint")).then(data => {
        if (data && data.slides && data.slides.length) {
          this.slides = data.slides;
        }
        if (data && data.timeout && typeof data.timeout === "number") {
          this.timeout = data.timeout;
        }

        if (data && data.cta_pins) {
          this.cta_pins = data.cta_pins;
        }
        
        this.floatingButtonSet(data);
      });
    },

    preloadImages() {
      let imagesUrl = [];
      if (this.mediaQueries.includes("mobile")) {
        imagesUrl = this.slides.filter(slide => !!(slide.image && slide.image.portraitUrl)).map(slide => slide.image.portraitUrl);
      } else {
        imagesUrl = this.slides.filter(slide => !!(slide.image && slide.image.portraitUrl)).map(slide => slide.image.wideUrl);
      }

      return Promise.all(imagesUrl.map(url => {
        return new Promise(resolve => {
          const img = document.createElement("img");
          img.addEventListener("load", resolve);
          img.setAttribute("src", url);
        });
      }));
    },

    toggleSlide() {
      
      
      if (this.indexNext < 0 || this.indexNext > this.maxIndex || this.indexNext === this.index) {
        return;
      }

      if (!this.$refs.slides.length) {
        return;
      }

      clearTimeout(this.$options.timeout);

      const tl = gsap.timeline();
      const slide = this.$refs.slides[this.indexNext];
      const slideElements = this.slideElements(slide);
      const revealElements = [];


      this.stopInteractions = true;

      if (slideElements.title) {
        tl.add(this.tweenTitleReveal(slideElements.title), 0);
      }

      if (slideElements.imageWrapper) {
        tl.add(this.tweenImageWrapperMask(slideElements.imageWrapper), 0);
      }

      if (slideElements.image) {
        tl.add(this.tweenImageMask(slideElements.image), 0);
      }

      if (slideElements.videoWrapper) {
        tl.add(this.tweenImageWrapperMask(slideElements.videoWrapper), 0);
      }

      if (slideElements.video) {
        tl.add(this.tweenImageMask(slideElements.video), 0);
      }



      //if (slideElements.back1 && !this.mediaQueries.includes("mobile")) {
      if (slideElements.back1 && !this.mediaQueries.includes("mobile")) {

        if(this.indexNext==0){
          gsap.set(slideElements.back1,{opacity:0});
        }
        else
        {
          gsap.set(slideElements.back1,{opacity:1});
          tl.fromTo(slideElements.back1, {
            xPercent: -100,
            skewX: -35,
            opacity: 0
          }, {
            xPercent: 0,
            skewX: 0,
            opacity: 1,
            duration: 1,
            ease: "power4.inOut",
            clearProps: "transform,opacity"
          }, 0.2);
        }

      }

      if (slideElements.back2 && !this.mediaQueries.includes("mobile")) {

        if(this.indexNext==0){
          gsap.set(slideElements.back2,{opacity:0});
        }
        else
        {
          gsap.set(slideElements.back2,{opacity:1});
          tl.fromTo(slideElements.back2, {
            xPercent: -100,
            skewX: -35,
            opacity: 0
          }, {
            xPercent: 0,
            skewX: 0,
            opacity: 1,
            duration: 1,
            ease: "power4.inOut",
            clearProps: "transform,opacity"
          }, 0.35);
        }


      }
      if (slideElements.backsMobile && this.mediaQueries.includes("mobile")) {
        tl.fromTo(slideElements.backsMobile.querySelector('div:last-child'), {
          opacity: 0,
          yPercent: 100,
          skewY: 20,
        }, {
          yPercent: 0,
          skewY: 0,
          opacity: 1,
          duration: 1,
          ease: "power4.inOut",
          clearProps: "transform,opacity"
        }, 0.35);

        tl.fromTo(slideElements.backsMobile.querySelector('div:first-child'), {
          opacity: 0,
          rotation:20
        }, {
          opacity: 1,
          rotation:8,
          duration: 1,
          delay:.1,
          ease: "power4.inOut",
          clearProps: "transform,opacity"
        }, 0.35);
      }

      if(slideElements.scrollLabel)
      {
        revealElements.push(slideElements.scrollLabel);
      }

      if(slideElements.logoDoha){
        if(this.indexNext==0)
        {
          revealElements.push(slideElements.logoDoha);
        }
        else
        {
          gsap.set(slideElements.logoDoha,{alpha:0});
        }
      }

      if (slideElements.smallTitle) {
        revealElements.push(slideElements.smallTitle);
      }

      if (slideElements.smallTitle) {
        revealElements.push(slideElements.smallTitle);
      }

      if (slideElements.button) {
        revealElements.push(slideElements.button);
      }
      
      if (slideElements.description) {
        revealElements.push(slideElements.description);
      }

      if (slideElements.navItems && slideElements.navItems.length) {

        if(this.mediaQueries.includes("mobile")){
         
        }
        else
        {
          slideElements.navItems.forEach(el => { revealElements.push(el) });
        }
        
      }

      if (revealElements.length) {
        tl.fromTo(revealElements, {
          opacity: 0,
          x: -15,
          scale: 1 // fix gsap behaviour
        }, {
          opacity: 1,
          x: 0,
          scale: 1,
          duration: 0.6,
          stagger: 0.1,
          ease: "power4.out",
          clearProps: "opacity"
        }, 0.75);
      }

      if (slideElements.pins && slideElements.pins.length && !this.isMobile) {
        tl.fromTo(
          slideElements.pins,
          {
            opacity: 0,
            scale: 1.2
          },
          {
            opacity: 1,
            scale: 1,
            duration: 0.8,
            stagger: 0.1,
            ease: "power4.out",
            clearProps: "transform,opacity"
          },
          1.5
        );
      }

      tl.then(() => {
        if (this.$refs.video && this.$refs.video[0]) {
          const videoCurrentTime = this.$refs.video[0].currentTime;
          // video is replaced
          setTimeout(() => {
            if (this.$refs.video && this.$refs.video[0]) {
              this.$refs.video[0].currentTime = videoCurrentTime;
            }
          }, 1);
        }

        this.index = this.indexNext;
        this.indexNext = null;
        this.stopInteractions = false;

        setTimeout(() => {
          // if video change slide at the end of the video
          if (this.$refs.video && this.$refs.video[0]) {
            this.$refs.video[0].addEventListener("ended", () => {
              if (this.lightboxActive) {
                this.$refs.video[0].play();
                return;
              }
              this.next(); 
            });
          } 
          // otherwise next slide at timeout
          else {
            this.$options.timeout = setTimeout(this.next, this.timeout);
          }
        }, 0);
      });
    },


    slideElements(slide) {
      return {
        title: slide.querySelector(".title-reveal"),
        imageWrapper: slide.querySelector(".hero-slider-3__image"),
        image: slide.querySelector(".hero-slider-3__image img"),
        videoWrapper: slide.querySelector(".hero-slider-3__video"),
        video: slide.querySelector(".hero-slider-3__video video"),
        back1: slide.querySelector(".hero-slider-3__back-1"),
        back2: slide.querySelector(".hero-slider-3__back-2"),
        backsMobile: slide.querySelector(".hero-slider-3__backs-mobile"),
        button: slide.querySelector(".button"),
        smallTitle: slide.querySelector("h6"),
        navItems: Array.from(slide.querySelectorAll(".hero-slider-3__nav > *")),
        description: slide.querySelector('.hero-slider-3__description'),
        pins: slide.querySelectorAll(".hero-slider-3__pin"),
        logoDoha: slide.querySelector(".hero-slider-3__slide-logo-doha"),
        scrollLabel: slide.querySelector(".hero-slider-3__scroll-label"),
      }
    },


    prev() {
      if (this.stopInteractions) {
        return;
      }

      this.indexNext = this.index === 0 ? this.maxIndex : this.index - 1;
    },

    next() {
      if (this.stopInteractions) {
        return;
      }

      if (this.cursorOverPin) {
        return;
      }

      this.indexNext = this.index === this.maxIndex ? 0 : this.index + 1;
    },

    onMouseMove(e) {
      if (this.isMobile) {
        return
      }
      this.pointerX = e.clientX;
      this.pointerY = e.clientY;
    },

    onKeyDown(e) {
      if (e.key === "ArrowLeft") {
        this.prev();
      }
      if (e.key === "ArrowRight") {
        this.next();
      }
    },

    toggleLightbox(content) {
      window.mondo.event.$emit("Lightbox.toggle", content);
    },
    slideButtonClick(category){
      window.mondo.event.$emit("NavIndexes.scrollTo", 2);
      window.mondo.event.$emit("HeroSlider.linkTo", { category: category});
    },
    pinCarouselClick(pin) {
      window.mondo.event.$emit("NavIndexes.scrollTo", 2);
      window.mondo.event.$emit("HeroSlider.linkTo", { category: pin.categoryId,productIndex: this.pinCarouselIndex});
    },
    pinMouseEnter(e){
      this.pinCarouselContainer=e.target.querySelector('ul');
    },

    pinMouseLeave(){
      this.pinCarouselContainer=null;
    },

    labelHtml(str) {
      if (str.length < 20) {
        return str;
      }
      
      const labelSplit = str.split(" ");
      str = "";

      const half = Math.round(labelSplit.length / 2);
      labelSplit.forEach((word, i) => {
        if (i === half) {
          str += "<br>";
        }
        str += `${word} `;
      });

      return str;
    },

    onPinsMouseEnter() {
      if (this.isMobile) {
        return;        
      }
      this.cursorOverPin = true;
    },

    onPinsMouseLeave() {
      if (this.isMobile) {
        return;        
      }

      this.cursorOverPin = false;
    },

    //pin carousel

    prevPin(){
      if(this.pinCarouselIndex>0) this.pinCarouselIndex--;
    },
    nextPin(){
      if(this.pinCarouselIndex<this.pinCarouselItems-1) this.pinCarouselIndex++;
    }
  }
};
