import Vue from "vue";
import _ from "lodash";
import componentsSet from "../components/index.js"
import store from "../store/index.js"

let contentElement = null

let config = {
  contentSelector: "#content",
  componentAttribute: "data-component",
  componentId: "data-id"
}

const instances = []

const components = {
  init(sette, params) {
    let customContentSelector = false

    // merge config
    if (typeof params === "object" && params.constructor === Object) {
      if (params.contentSelector) {
        customContentSelector = true
      }
      config = Object.assign(config, params)
    }

    // if is provided a new content element selector use it, otherwise take the content element from pageLoader
    if (customContentSelector) {
      contentElement = document.querySelector(config.contentSelector)
      this.parse()
    } else {
      // wait for every module to be set
      setTimeout(() => {
        if (sette.modules.pageLoader) {
          contentElement = sette.modules.pageLoader.contentElement()
          if (!contentElement) {
            contentElement = document.querySelector(config.contentSelector)
          }
          // register page loader hooks
          registerPageLoaderHooks(sette)
        } else {
          contentElement = document.querySelector(config.contentSelector)
        }
        this.parse()
      }, 0)
    }

    return Promise.resolve();
  },

  parse() {
    const componentsEls = document.querySelectorAll(`[${config.componentAttribute}]`)
    Array.prototype.forEach.call(componentsEls, el => {
      const name = el.getAttribute(config.componentAttribute)
      const id = el.getAttribute(config.componentId)

      if (componentsSet[name]) {
        let component = _.cloneDeep(componentsSet[name])

        // test if component is global or not
        let scope = "global"
        let parentEl = el

        while (parentEl && parentEl.parentNode) {
          if (parentEl === contentElement) {
            scope = "content"
            parentEl = null
          } else {
            parentEl = parentEl.parentNode
          }
        }

        component.store = store

        // inject scope & id in component data
        if (component.data) {
          if (typeof component.data === "function") {
            component.data = component.data();
          }
          component.data.scope = scope
          component.data.id = id
        } else {
          component.data = { id, scope }
        }

        // set external data & remove window object
        const externalData = window[`__${id}`]
        if (externalData && typeof externalData === "object" && externalData.constructor === Object) {
          component.data = Object.assign(component.data, externalData)
          delete window[`__${id}`]

          // remove data script
          const externalDataScript = el.parentNode.querySelector(`script[data-id="${id}"]`)
          if (externalDataScript) {
            externalDataScript.parentNode.removeChild(externalDataScript)
          }
        }

        // set template
        component.template = nodeToVueTemplate(el)

        // create component and then mount the element
        const componentInstance = new Vue(component, true)
        componentInstance.$mount(el)

        // remove component attribute / id
        componentInstance.$el.removeAttribute(config.componentAttribute)
        componentInstance.$el.removeAttribute(config.componentId)

        // add the instance to the set
        instances.push(componentInstance)
      }
    })
  }
}

function nodeToVueTemplate(el) {
  const elClone = el.cloneNode(true)
  replaceAttributesToVueTemplate(elClone)
  return elClone.outerHTML.replace(/\s\s+/g, " ")
}

function replaceAttributesToVueTemplate(el) {
  // replace attributes
  if (el.hasAttributes()) {
    // map attributes before replace
    const elAttributes = Array.prototype.map.call(el.attributes, function (attribute) {
      return {
        name: attribute.name.toLowerCase(),
        value: attribute.value
      }
    })

    for (let i = elAttributes.length - 1; i >= 0; i--) {
      const attrName = elAttributes[i].name
      const attrValue = elAttributes[i].value
      let vueAttributeName = null

      // replace element
      if (attrName === "data-interpolation") {
        el.outerHTML = `{{ ${attrValue} }}`
      }

      // other directives
      switch (attrName) {
        case "data-cloak":
          vueAttributeName = "v-cloak"
          break;

        case "data-else":
          vueAttributeName = "v-else"
          break;

        case "data-else-if":
          vueAttributeName = "v-else-if"
          break;

        case "data-for":
          vueAttributeName = "v-for"
          break;

        case "data-html":
          vueAttributeName = "v-html"
          break;

        case "data-if":
          vueAttributeName = "v-if"
          break;

        case "data-is":
          vueAttributeName = "is"
          break;

        case "data-key":
          vueAttributeName = ":key"
          break;

        case "data-model":
          vueAttributeName = "v-model"
          break;

        case "data-once":
          vueAttributeName = "v-once"
          break;

        case "data-pre":
          vueAttributeName = "v-pre"
          break;

        case "data-ref":
          vueAttributeName = "ref"
          break;

        case "data-show":
          vueAttributeName = "v-show"
          break;

        case "data-slot":
          vueAttributeName = "slot"
          break;

        case "data-slot-scope":
          vueAttributeName = "slot-scope"
          break;

        case "data-text":
          vueAttributeName = "v-text"
          break;
      }

      // bind directive
      if (!vueAttributeName) {
        const bindMatch = attrName.match(/^data\-bind\-(.+)$/)
        if (bindMatch && bindMatch[1]) {
          vueAttributeName = `v-bind:${bindMatch[1]}`
        }
      }

      // on directive
      if (!vueAttributeName) {
        const onMatch = attrName.match(/^data\-on\-(.+)$/)
        if (onMatch && onMatch[1]) {
          vueAttributeName = `v-on:${onMatch[1]}`
        }
      }

      
      // asssign attribute
      if (vueAttributeName) {
        el.removeAttribute(attrName)
        el.setAttribute(vueAttributeName, attrValue)
      }
    }
  }

  // recursively convert children attributes
  if (el.children.length) {
    for (let i = el.children.length - 1; i >= 0; i--) {
      replaceAttributesToVueTemplate(el.children[i])
    }
  }
}

function registerPageLoaderHooks(sette) {
  sette.modules.pageLoader.hook("beforeContentReplace", onBeforeContentReplace)
  sette.modules.pageLoader.hook("afterContentReplace", onAfterContentReplace)
}

function onBeforeContentReplace() {
  // destroy and remove content components
  instances.forEach((component, i) => {
    if (component.scope === "content") {
      component.$destroy()
      instances.splice(i, 1)
    }
  })

  return Promise.resolve()
}

function onAfterContentReplace() {
  components.parse()
  return Promise.resolve()
}

export default components