import Vue from "vue";
import gsap from "gsap";
import _ from "lodash";
import SliderMixin from "../mixins/slider.js";
import TweenMixin from "../mixins/tween";

export default {
  name: "ProductsSlider",

  mixins: [SliderMixin, TweenMixin],

  data: {
    detailsActive: false,
    gridLinkDisplay: true,
    index: 0,
    indexVariant: 0,
    indexPin: 0,
    productsIndex: null,
    specsActive: false,
    stopInteractions: false
  },

  computed: {
    barStyle() {
      return {
        width: `${this.slides.length * 100}%`,
        transform: (this.slider.x !== null) ? `translateX(${this.slider.x}px)` : `translateX(${window.innerWidth * -this.index}px)`
      };
    },

    bkgTextBottom() {
      if (this.currentProduct && this.currentProduct.name) {
        const split = this.currentProduct.name.split(" ");
        const startAt = Math.floor(split.length / 2);
        return split.splice(startAt).join(" ");
      }
      return "";
    },

    bkgTextTop() {
      if (this.currentProduct && this.currentProduct.name) {
        const split = this.currentProduct.name.split(" ");
        const words = Math.floor(split.length / 2);
        return split.splice(0, words).join(" ");
      }
      return "";
    },

    currentProduct() {
      if (this.slides[this.index]) {
        return this.slides[this.index];
      }
      return null;
    },

    currentProductVariant() {
      if (this.currentProductVariants && this.currentProductVariants[this.indexVariant]) {
        return this.currentProductVariants[this.indexVariant];
      }
      return null;
    },

    currentProductVariants() {
      if (this.currentProduct && this.currentProduct.variants && this.currentProduct.variants.length) {
        return this.currentProduct.variants;
      }
      return [];
    },

    currentPin() {
      if (this.currentProduct && this.currentProduct.pins && this.currentProduct.pins[this.indexPin]) {
        return this.currentProduct.pins[this.indexPin];
      }
      return null;
    },

    disableSlider() {
      return this.isMobile && this.specsActive;
    },

    isMobile() {
      return this.$store.state.mediaQueries.includes("mobile");
    },

    productsIcons() {
      return this.$store.state.productsIcons;
    },

    productStyle() {
      return {
        width: `${100/this.slides.length}%`
      };
    },

    slides() {
      if (typeof this.productsIndex === "number" && this.$store.state.products[this.productsIndex]) {
        return this.$store.state.products[this.productsIndex];
      }
      return [];
    },

    slidersIntersected() {
      return this.$store.state.productsSlider.intersected;
    },

    // generic value
    visible() {
      return this.$store.state.productsSlider.visible;
    },

    // current slider visibility
    visibleThis() {
      return this.visible && (this.$store.state.productsSlider.index === this.productsIndex);
    }
  },

  watch: {
    detailsActive(value) {
      if (value) {
        window.document.body.classList.add("unscrollable");
        window.scrollTo({
          top: this.$el.getBoundingClientRect().top + window.pageYOffset,
          behavior: "smooth"
        });

        if (this.isMobile) {
          this.gridLinkDisplay = false;
        } else {
          this.$store.commit("navIndexes", { display: false });
          this.tweenDetailsIn();
          setTimeout(() => window.mondo.event.$emit("TopHeader.styleHidden"), 500); // end scroll end
        }
      } else {
        this.slider.interactions = true; // reset slider interactions
        window.document.body.classList.remove("unscrollable");
        if (this.isMobile) {
          this.tweenDetailsOutMobile();
          this.gridLinkDisplay = true;
        } else {
          this.tweenDetailsOut();
          setTimeout(() => {
            window.mondo.event.$emit("TopHeader.stylePrev");
            this.$store.commit("navIndexes", { display: true });
          }, 500);
        }
      }
    },

    disableSlider(value) {
      this.slider.interactions = !value;
    },

    index(value) {
      this.tweenSlideIn();
      this.indexPin = 0;
      this.indexVariant = 0;
    },

    specsActive(value) {
      this.$store.commit('navIndexes', { display: !value });
    },

    visibleThis(value) {
      Vue.nextTick().then(() => {
        if (value) {
          this.tweenSliderIn();
        } else {
          this.tweenSliderOut();
        }
      });

      if (this.isMobile) {
        if (value) {
          document.body.classList.add("unscrollable");
          setTimeout(() => {
            this.$el.scrollTo(0, 0);
            this.$store.commit("scrollComponentName", "products-slider");
          }, 50);
        } else {
          document.body.classList.remove("unscrollable");
        }
      }
    }
  },

  created() {
    this.$options.pinsLocation = {
      grass: [ null, 
        {
          top: 24,
          left: 35
        }, 
        {
          top: 50,
          left: 45
        },
        {
          top: 60,
          left: 30
        },
        {
          top: 77,
          left: 45
        },
        {
          top: 35,
          left: 65
        },
        {
          top: 60,
          left: 65
        }
      ],
      gomma: [ null, 
        {
          top: 47,
          left: 21
        },
        {
          top: 52,
          left: 35
        },
        {
          top: 57,
          left: 50
        },
        {
          top: 38,
          left: 40
        },
        {
          top: 35,
          left: 60
        },
        {
          top: 50,
          left: 70
        }
      ],
      legno: [ null, 
        {
          top: 48,
          left: 20
        },
        {
          top: 56,
          left: 33
        },
        {
          top: 62,
          left: 54
        },
        {
          top: 33,
          left: 42
        },
        {
          top: 38,
          left: 63
        },
        {
          top: 50,
          left: 70
        }
      ]
    };
  },

  mounted() {
    this.productsIndex = parseInt(this.$el.parentNode.getAttribute("data-products-index"));
    window.mondo.event.$on("ProductsSlider.toggle", this.toggle);

    if (this.slides && this.slides.length) {
      this.setup();
    } else {
      const unwatch = this.$watch("slides", slides => {
        if (slides && slides.length) {
          this.setup();
          unwatch();
        }
      });
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.$options.onResizeListener);
  },

  methods: {
    setup() {
      this.$options.onResizeListener = _.debounce(this.onResize, 1000);
      window.addEventListener("resize", this.$options.onResizeListener);

      // int observer

      this.$options.intListener = new IntersectionObserver(entries => {
        const entry = entries[0];

        if (entry.isIntersecting && !this.slidersIntersected.includes(this.id)) {
          this.$store.commit("productsSlider", {
            intersected: this.slidersIntersected.concat([this.id])
          });
        }

        if (!entry.isIntersecting && this.slidersIntersected.includes(this.id)) {
          this.slidersIntersected.splice(this.slidersIntersected.indexOf(this.id), 1);
          this.$store.commit("productsSlider", {
            intersected: this.slidersIntersected
          });
        }
        
        if (!entry.isIntersecting) {
          if (this.visibleThis) {
            setTimeout(() => {
              if (!this.slidersIntersected.includes(this.productsIndex)) {
                this.toggle(this.productsIndex);
              }
            }, 100);
          }
          if (this.slides.length) { 
            this.sliderTweenTo(0); // reset slider
          }
        }
      }, { threshold: 0.05 });

      this.$options.intListener.observe(this.$el);
    },

    toggle(pIndex, i) {
      if (this.stopInteractions) {
        return
      }

      if (pIndex !== undefined && pIndex !== this.productsIndex) {
        return;
      }
      if (_.isNumber(i)) {
        this.index = i;
      }
      
      if (this.visibleThis) {
        this.$store.commit("productsSlider", {
          visible: false,
          index: -1
        });
      } 
      else {
        this.$store.commit("productsSlider", {
          visible: true,
          index: pIndex
        });
      }
    },

    toggleSpecs() {
      this.specsActive = !this.specsActive;
    },

    toggleDetail(index) {
      // toggle lightbox instead of details
      if (!this.detailsActive && this.currentProduct.pins[index].lightboxContent) {
        window.mondo.event.$emit("Lightbox.toggle", this.currentProduct.pins[index].lightboxContent);
        return;
      }
      if (this.specsActive) {
        this.toggleSpecs();
      }

      this.stopInteractions = true

      // toggle details
      if (_.isNumber(index)) {
        const pin = this.currentProduct.pins[index];
        if (pin) {
          this.indexPin = index;
          this.detailsActive = true;
        }
      }
      else {
        this.detailsActive = false;
      }
    },

    toggleVariant(i) {
      Promise.resolve()
        .then(this.tweenVariantOut)
        .then(() => {
          this.indexVariant = i;
          return Vue.nextTick();
        })
        .then(this.tweenVariantIn);
    },

    beforeSliderTweenTo(i) {
      if (this.specsActive) {
        this.toggleSpecs();
      }

      if (this.detailsActive) {
        this.toggleDetail();
      }

      this.$options.switchToAnotherProduct = i !== this.index;

      if (this.$options.switchToAnotherProduct) {
        this.tweenLettersOut(this.$refs.letters, { duration: 0.5 });
      }
    },

    afterSliderTweenTo(i) {
      if (this.detailsActive) {
        this.slider.interactions = false; // disable drag when slider is in detail mode
      }
      if (this.$options.switchToAnotherProduct) {
        this.tweenLettersIn(this.$refs.letters, { duration: 1.5 });
      }
    },

    colorStyle(imageUrl) {
      if (imageUrl) {
        return {
          backgroundImage: `url("${imageUrl}")`
        };
      }
      return {};
    },

    labelHtml(str) {
      if (str.length < 20) {
        return str;
      }
      
      const labelSplit = str.split(" ");
      str = "";

      const half = Math.round(labelSplit.length / 2);
      labelSplit.forEach((word, i) => {
        if (i === half) {
          str += "<br>";
        }
        str += `${word} `;
      });

      return str;
    },

    mouseCursorDisplay() {
      if (this.detailsActive) {
        return;
      }
      this.$store.commit("mouseCursor", {
        display: true,
        displayLabel: true,
        style: null
      });
    },

    mouseCursorHide() {
      this.$store.commit("mouseCursor", {
        display: false,
        style: null
      });
    },

    onResize() {
      this.sliderTweenTo(this.index)
    },

    pinNext() {
      const indexPinNext = (this.indexPin + 1 === this.currentProduct.pins.length) ? 0 : this.indexPin + 1;
      this.pinSwitch(indexPinNext);
    },

    pinPrev() {
      const indexPinNext = (this.indexPin - 1 < 0) ? this.currentProduct.pins.length - 1 : this.indexPin - 1;
      this.pinSwitch(indexPinNext);
    },

    pinSwitch(i) {
      Promise.resolve()
        .then(() => this.tweenPinSwitchOut().then())
        .then(() => {
          this.indexPin = i;
          return Vue.nextTick();
        })
        .then(() => this.tweenPinSwitchIn().then())
    },

    scrollToContacts() {
      this.toggle(); // close products slider

      const contactFormEl = document.querySelector(".contact-form");
      if (!contactFormEl) {
        return;
      }

      setTimeout(() => {
        const top = window.pageYOffset + contactFormEl.getBoundingClientRect().top;

        window.scrollTo({
          top,
          behavior: "smooth"
        });
      }, this.isMobile ? 200 : 1); // on mobile the section must be closed to calculate the exact space
    },

    tweenVariantIn() {
      if (!this.$refs.specsInfo1) {
        return Promise.resolve();
      }
      return gsap.to(this.$refs.specsInfo1, {
        opacity: 1,
        duration: 0.35,
        ease: "power2.inOut",
        clearProps: "opacity"
      }).then();
    },

    tweenVariantOut() {
      if (!this.$refs.specsInfo1) {
        return Promise.resolve();
      }
      return gsap.to(this.$refs.specsInfo1, {
        opacity: 0,
        duration: 0.35,
        ease: "power2.inOut"
      }).then();
    },

    tweenSliderIn() {
      const tl = gsap.timeline();

      tl.fromTo(this.$el, {
        opacity: 0
      }, {
        opacity: 1,
        duration: 0.75,
        ease: "power4.inOut",
        clearProps: "opacity"
      }, 0);

      tl.fromTo(this.$refs.gridIcon, {
        opacity: 0,
        x: 20
      }, {
        opacity: 1,
        x: 0,
        duration: 0.75,
        ease: "power4.inOut",
        clearProps: "opacity,transform"
      });

      tl.add(this.tweenLettersIn(this.$refs.letters), 0.2);
      tl.add(this.tweenSlideIn, 0);

      return tl;
    },

    tweenSliderOut() {
      const tl = gsap.timeline();

      tl.add(this.tweenLettersOut(this.$refs.letters), 0);

      tl.to(this.$el, {
        opacity: 0,
        duration: 0.5,
        ease: "power4.inOut",
        clearProps: "opacity"
      }, 0);

      tl.to(this.$refs.gridIcon, {
        opacity: 0,
        duration: 0.5,
        ease: "power4.inOut",
        clearProps: "opacity"
      }, 0);

      return tl;
    },

    tweenSlideIn() {
      const tl = gsap.timeline();
      const slide = this.$refs.slides[this.index];
      const els = {
        infoEls: slide.querySelectorAll(".products-slider__info > *"),
        pins: slide.querySelectorAll(".products-slider__pin")
      };

      if (els.infoEls && els.infoEls.length) {
        tl.fromTo(els.infoEls, {
          opacity: 0,
          x: 20,
          scale: 1
        }, {
          opacity: 1,
          x: 0,
          scale: 1,
          duration: 0.8,
          stagger: 0.1,
          ease: "circ.out",
          clearProps: "all"
        }, 0);
      }

      if (els.pins && els.pins.length) {
        tl.fromTo(els.pins, {
          scale: 0
        }, {
          scale: 1,
          ease: "circ.inOut",
          duration: 0.75,
          stagger: 0.1,
          clearProps: "transform"
        }, 0.1);
      }

      return tl;
    },

    tweenDetailsIn() {
      const tl = gsap.timeline({
        onComplete: () => {
          this.stopInteractions = false
        }
      });
      const slide = this.$refs.slides[this.index];
      const els = {
        info: slide.querySelectorAll(".products-slider__info"),
        image: slide.querySelector(".products-slider__image"),
        pin: this.$el.querySelector(".products-slider__pin--active")
      };
      const imageLeft = els.image ? parseInt(getComputedStyle(els.image).left) : 0;
      let pinLeftPercent = 0;
      if (els.pin && !this.isMobile) {
        pinLeftPercent = this.$options.pinsLocation[this.currentPin.position.template][this.currentPin.position.id].left;
      }

      if (els.info) {
        tl.to(els.info, {
          opacity: 0,
          duration: 0.5,
          ease: "power2.out"
        }, 0);
      }

      if (this.$refs.gridIcon) {
        tl.to(this.$refs.gridIcon, {
          opacity: 0,
          duration: 0.5,
          ease: "power2.out",
          onComplete: () => {
            this.$refs.gridIcon.style.pointerEvents = 'none'
          }
        }, 0);
      }

      if (els.image) {
        gsap.set(els.image, { animation: "unset" });
        tl.fromTo(els.image, {
          scale: 1,
          left: imageLeft,
          xPercent: 0
        }, {
          scale: 1.5,
          left: 0,
          xPercent: 50 - (pinLeftPercent * 1.5), // change 50 to 75 for a perfect 50% position
          duration: 1,
          ease: "power2.inOut",
          clearProps: "left"
        }, 0.1);
      }

      if (this.$refs.details) {
        tl.to(this.$refs.details, {
          x: "-50vw",
          duration: 1,
          ease: "power2.inOut"
        }, 0.2);
      }

      return tl;
    },

    tweenDetailsOutMobile() {
      const tl = gsap.timeline({
        onComplete: () => {
          this.stopInteractions = false
        }
      });
      const slide = this.$refs.slides[this.index];
      const els = {
        info: slide.querySelectorAll(".products-slider__info"),
        image: slide.querySelector(".products-slider__image")
      };

      if (els.image) {
        tl.to(els.image, {
          scale: 1,
          left: 0,
          xPercent: 0,
          duration: 1,
          ease: "power2.inOut",
          clearProps: "all"
        }, 0.1);
      }

      return tl.then();
    },

    tweenDetailsOut() {
      const tl = gsap.timeline({
        onComplete: () => {
          this.stopInteractions = false
        }
      });
      const slide = this.$refs.slides[this.index];
      const els = {
        info: slide.querySelectorAll(".products-slider__info"),
        image: slide.querySelector(".products-slider__image")
      };

      if (this.$refs.details) {
        tl.to(this.$refs.details, {
          x: 0,
          duration: 1,
          ease: "power2.inOut",
          clearProps: "x"
        }, 0.1);
      }

      if (els.image) {
        tl.fromTo(els.image, {
          scale: 1.5,
          left: 0
        }, {
          scale: 1,
          left: '20vw',
          xPercent: 0,
          duration: 1,
          ease: "power2.inOut",
          clearProps: "all"
        }, 0.1);
      }

      if (els.info) {
        tl.to(els.info, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
          clearProps: "opacity"
        }, 0.5);
      }

      if (this.$refs.gridIcon) {
        tl.to(this.$refs.gridIcon, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
          clearProps: "opacity",
          onComplete: () => {
            this.$refs.gridIcon.style.pointerEvents = null
          }
        }, 0.25);
      }

      return tl;
    },

    tweenPinSwitchOut() {
      const tl = gsap.timeline();
      const els = {
        contentEls: this.$el.querySelectorAll(".products-slider__details-content > *")
      };

      if (els.contentEls && els.contentEls.length) {
        tl.to(els.contentEls, {
          opacity: 0,
          x: 10,
          duration: 0.5,
          ease: "power4.out",
          stagger: 0.05
        }, 0.1);
      } else {
        return {
          then: () => Promise.resolve()
        };
      }

      return tl;
    },

    tweenPinSwitchIn() {
      const tl = gsap.timeline();
      const slide = this.$refs.slides[this.index];
      const els = {
        image: slide.querySelector(".products-slider__image"),
        contentEls: this.$el.querySelectorAll(".products-slider__details-content > *"),
        pin: this.$el.querySelector(".products-slider__pin--active")
      };
      let pinLeftPercent = 0;
      if (els.pin && !this.isMobile) {
        pinLeftPercent = this.$options.pinsLocation[this.currentPin.position.template][this.currentPin.position.id].left;
      }

      if (els.image && !this.isMobile) {
        tl.to(els.image, {
          scale: 1.5,
          xPercent: 55 - (pinLeftPercent * 1.5), // change 50 to 75 for a perfect 50% position
          duration: 1,
          ease: "power2.inOut",
          clearProps: "left"
        }, 0.1);
      }

      if (els.contentEls && els.contentEls.length) {
        tl.fromTo(els.contentEls, {
          x: -10,
          opacity: 0
        }, {
          x: 0,
          opacity: 1,
          duration: 0.5,
          stagger: 0.05,
          ease: "power4.inOut",
          clearProps: "transform,opacity"
        }, 0.25);
      }

      return tl;
    }
  }
};
