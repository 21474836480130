import store from "../store/index.js";

export default {
    install() {
        setTimeout(this.setVh, 10);
        let timeout;

        window.addEventListener("resize", () => {
            if (!timeout) {
                timeout = setTimeout(() => {
                    this.setVh();
                    timeout = null;
                }, 250);
            }
        })
    },

    setVh() {
        const vh = window.innerHeight * 0.01;
        window.document.documentElement.style.setProperty('--vh', `${vh}px`);
        store.commit("vh", vh);
    }
};