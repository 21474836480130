import gsap from "gsap";
import TweenMixin from "../mixins/tween.js";

export default {
  name: "Lightbox",

  mixins: [TweenMixin],

  data() {
    return {
      content: '',
      toggleStop: false
    };
  },

  computed: {
    active() {
      return this.$store.state.lightbox.active;
    }
  },

  watch: {
    active(value) {
      if (!value) {
        this.content = '';
      }
    }
  },

  mounted () {
    window.mondo.event.$on("Lightbox.toggle", this.toggle);
  },

  methods: {
    toggle(content) {
      if (typeof content === "string") {
        this.content = content;
      }
      if (this.toggleStop) {
        return
      }
      this.toggleStop = true
      if (this.active) {
        this.tween("out").then(() => {
          this.toggleStop = false;
          this.$store.commit("lightbox", { active: false });
        });
      } else if (!this.active) {
        this.$store.commit("lightbox", { active: true });
        this.tween("in").then(() => {
          this.toggleStop = false;
        })
      }
    },

    tween(type) {
      return new Promise(resolve => {
        const tl = gsap.timeline()

        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        const from = {
          clipPath: type === 'in' ? 'inset(0% 0% 15% 0%)' : 'inset(0% 0% 0% 0%)',
          opacity: type === 'in' ? 0 : 1
        };
        const to = {
          clipPath: type === 'in' ? 'inset(0% 0% 0% 0%)' : 'inset(0% 0% 15% 0%)',
          opacity: type === 'in' ? 1 : 0,
          duration: 1,
          ease: "power2.inOut",
          clearProps: "clip-path,opacity",
          onComplete: resolve
        }
  
        if (!isFirefox) {
          from.webkitClipPath = type === 'in' ? 'inset(0% 0% 15% 0%)' : 'inset(0% 0% 0% 0%)';
          to.webkitClipPath = type === 'in' ? 'inset(0% 0% 0% 0%)' : 'inset(0% 0% 15% 0%)';
          to.clearProps = "clip-path,-webkit-clip-path";
        }
  
        tl.fromTo(
          this.$refs.background,
          from,
          to,
          type === 'in' ? 0.1 : 0.45
        );
        
        tl.fromTo(
          this.$refs.close,
          {
            opacity: type === 'in' ? 0 : 1,
          },
          {
            opacity: type === 'in' ? 1 : 0,
            duration: 1,
            ease: "power4.inOut"
          },
          type === 'in' ? 0.75 : 0.1
        );

        tl.fromTo(
          this.$refs.content,
          {
            opacity: type === 'in' ? 0 : 1,
            yPercent: type === 'in' ? -5 : 0
          },
          {
            opacity: type === 'in' ? 1 : 0,
            yPercent: type === 'in' ? 0 : -5,
            duration: 1,
            ease: "power4.inOut",
            clearProps: "transform"
          },
          type === 'in' ? 0.75 : 0.1
        )
      });
    }
  }
}
