import Vue from "vue";
import gsap from "gsap";
import ApiLoadMixin from "../mixins/api.js";
import SliderMixin from "../mixins/slider.js";
import TweenMixin from "../mixins/tween.js";
import FloatingButtonMixin from "../mixins/floatingbutton.js";

export default {
  name: "InfoSlider",

  mixins: [ApiLoadMixin, SliderMixin, TweenMixin, FloatingButtonMixin],

  data: {
    index: -1,
    indexType: 0,
    indexesFixed: false,
    indexesFixedBottom: false,
    backgroundText: [],
    slides: [],
    timeout: 8000
  },

  computed: {
    barStyle() {
      return {
        width: `${this.slides.length * 100}%`,
        transform: (this.slider.x !== null) ? `translateX(${this.slider.x}px)` : `translateX(${this.$el.offsetWidth * -this.index}px)`
      };
    },

    currentSlide() {
      return this.slides[this.index] ? this.slides[this.index] : null;
    },

    currentSlideType() {
      if (this.currentSlide && this.currentSlide.types && this.currentSlide.types.length && this.currentSlide.types[this.indexType]) {
        return this.currentSlide.types[this.indexType];
      } else {
        return null;
      }
    },

    isMobile() {
      return this.$store.state.mediaQueries.includes('mobile');
    },

    slideStyle() {
      return {
        width: `${100/this.slides.length}%`
      };
    },

    topHeaderHidden() {
      return this.$store.state.topHeader.isHidden;
    }
  },

  watch: {
    index(value) {
      if (value < 0) {
        return;
      }
      this.indexType = 0; // reset indexType
      Vue.nextTick().then(this.tweenSlideIn);
      if (this.slider.autoplay) {
        this.setTimeout();
      }
    }
  },

  mounted() {
    this.setup();
  },

  methods: {
    setup() {
      return Promise.resolve()
        .then(this.loadData)
        .then(() => new Promise(resolve => {
          setTimeout(resolve, 100);
        }))
        .then(() => {
          this.$options.intListener = new IntersectionObserver(entries => {
            const entry = entries[0];
            if (entry && entry.isIntersecting) {
              this.setupMobileScrollListener();
              this.index = 0;
              this.tweenLetters();
              if (this.slider.autoplay) {
                this.setTimeout(); // restart
              }
            }
            if (entry && !entry.isIntersecting) {
              if (this.slides.length) {
                // set slider to 1 to reset to 0 when the slider returns in viewport
                if (this.slides[1]) {
                  this.$refs.indexes.scrollTo({ left: 0, behavior: "smooth" })
                  this.index = 1;
                  this.sliderTweenTo(1); // reset
                }
              }
              clearTimeout(this.$options.timeout); // stop autoplay
            }
          }, { threshold: 0.1 });

          this.$options.intListener.observe(this.$el);
        });
    },

    setupMobileScrollListener() {
      if (!this.isMobile) {
        return;
      }

      this.$options.mobileScrollBounds = {
        min: this.$el.offsetTop,
        max: this.$el.offsetTop + (this.$el.offsetHeight / 2) - this.$refs.indexes.offsetHeight
      };

      window.removeEventListener('scroll', this.onScrollMobile, false);
      window.addEventListener('scroll', this.onScrollMobile, false);
    },

    loadData() {
      return this.apiLoad(this.$el.getAttribute("data-endpoint")).then(data => {
        if (data && data.slides && data.slides.length) {
          this.backgroundText = data.backgroundText;
          this.slides = data.slides;
        }
        this.floatingButtonSet(data);
      });
    },

    imageStyle(imageData) {
      return {
        objectPosition: imageData.imagePosition ? imageData.imagePosition : "center"
      };
    },

    toggleSlide(i) {
      if (i === this.index) {
        return;
      }
      window.scrollTo({
        top: this.$el.getBoundingClientRect().top + window.pageYOffset + 1,
        behavior: "smooth"
      });
      this.slider.autoplay = false;
      this.sliderTweenTo(i);
    },

    toggleType(i) {
      if (i === this.indexType) {
        return;
      }
      Promise.resolve()
        .then(this.tweenTypeOut)
        .then(() => {
          this.indexType = i;
          return Vue.nextTick();
        })
        .then(this.tweenTypeIn);
    },

    setTimeout() {
      return; // disabled
      // clearTimeout(this.$options.timeout);
      // this.$options.timeout = setTimeout(() => {
      //   const nextIndex = (this.index + 1 === this.slides.length) ? 0 : this.index + 1;
      //   this.sliderTweenTo(nextIndex);
      // }, this.timeout);
    },

    onMouseEnter() {
      setTimeout(() => {
        this.$store.commit("mouseCursor", { display: true,
          label:window.mondoConfig.strings.dragLabel||''
         });
      }, 1);
    },

    onMouseLeave() {
      this.$store.commit("mouseCursor", { display: false });
    },

    onScrollMobile() {
      if (!this.isMobile) {
        this.indexesFixed = false;
        return;
      }
      
      if (window.pageYOffset >= this.$options.mobileScrollBounds.min && window.pageYOffset <= this.$options.mobileScrollBounds.max) {
        this.indexesFixed = true;
      } else {
        this.indexesFixed = false;
      }
    },

    tweenLetters() {
      return this.tweenLettersIn(this.$refs.letters);
    },

    tweenTypeOut() {
      if (!this.$refs.type) {
        return Promise.resolve();
      }
      const tl = gsap.timeline();

      tl.to(this.$refs.type, {
        opacity: 0,
        duration: 0.5,
        ease: "power2.inOut"
      }, 0);
      
      tl.to(this.$refs.typeImage, {
        opacity: 0,
        duration: 0.5,
        ease: "power2.inOut"
      }, 0);

      return tl.then();
    },

    tweenTypeIn() {
      if (!this.$refs.type) {
        return Promise.resolve();
      }

      const tl = gsap.timeline();

      tl.to(this.$refs.type, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.inOut",
        clearProps: "all"
      }, 0);

      tl.to(this.$refs.typeImage, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.inOut",
        clearProps: "all"
      }, 0);

      return tl.then();
    },

    tweenSlideIn() {
      const tl = gsap.timeline();
      const slideType = this.slides[this.index].type;
      const slide = this.$refs.slides[this.index];
      const els = {
        overlay: slide.querySelector(".info-slider__overlay"),
        overlayMask: slide.querySelector(".info-slider__overlay div"),
        image: slide.querySelector(".info-slider__intro-image"),
        imageMask: slide.querySelector(".info-slider__intro-image div"),
        contentEls: slide.querySelectorAll(".info-slider__content > *")
      };

      if (els.overlay && els.overlayMask) {
        tl.fromTo(els.overlay, {
          x: 20,
          opacity: 0
        }, {
          x: 0,
          opacity: 1,
          duration: 1,
          ease: "power2.inOut",
          clearProps: "all"
        }, 0);

        tl.fromTo(els.overlayMask, {
          skewX: 18,
          xPercent: 0,
          opacity: 1
        }, {
          skewX: 0,
          xPercent: -100,
          duration: 1,
          ease: "power2.inOut",
          clearProps: "all"
        }, 0);
      }

      if (els.image && els.imageMask) {
        tl.fromTo(els.image, {
          opacity: 0,
          x: 40,
        }, {
          opacity: 1,
          x: 0,
          duration: 1,
          ease: "circ.inOut",
          clearProps: "all"
        }, 0.2);

        tl.fromTo(els.imageMask, {
          skewX: 0,
          xPercent: -105
        }, {
          skewX: -18,
          xPercent: 0,
          duration: 1,
          ease: "circ.inOut",
          clearProps: "all"
        }, 0.2);
      }

      if (els.contentEls && els.contentEls.length) {
        tl.fromTo(els.contentEls, {
          opacity: 0,
          x: 40
        }, {
          opacity: 1,
          x: 0,
          ease: "circ.out",
          duration: 1,
          stagger: 0.1,
          clearProps: "all"
        }, 0.4);
      }

      return tl.then();
    }
  }
}
